import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_SUPERVISION_VISIT,
  DELETE_SUPERVISION_VISIT,
  SET_LOADING,
  SET_SUPERVISION_VISIT,
  SET_SUCCESS,
  SHOW_SUPERVISION_VISITS,
  UPDATE_SUPERVISION_VISIT,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getSuperVisionVisits = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        "get",
        `supervision-visits?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: SHOW_SUPERVISION_VISITS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getSuperVisionVisit = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call("get", `supervision-visits/${id}`);
      const result = response.data


      dispatch({
        type: SET_SUPERVISION_VISIT,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createSuperVisionVisit = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call("post", "supervision-visits", data);
      const result = response.data;
      dispatch({
        type: CREATE_SUPERVISION_VISIT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('SuperVisionVisit has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateSuperVisionVisit = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        "post",
        `supervision-visits/${id}?_method=PUT`,
        data
      );
      const result = response.data

      dispatch({
        type: UPDATE_SUPERVISION_VISIT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('SuperVisionVisit has been updated successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const deleteSuperVisionVisit = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call("delete", `supervision-visits/${id}`);

      dispatch({ type: DELETE_SUPERVISION_VISIT, payload: id })
      NotificationManager.success('SuperVisionVisit has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
