import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { sexs } from "variables/options";
import { yesOrNo } from "variables/options";
import {
  getVolunteers,
  getPatient,
  getSymptoms,
  updatePatient,
  getVolunteer,
} from "store/actions";
import { referTo } from "variables/options";
import { caseFoundActivity } from "variables/options";
import { referType } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { useHistory } from "react-router";
import { createPatient } from "store/actions";
import { call } from "services/api";
import { isVolunteerSupervisor } from "utils/middleware";
import { getTownships } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import BackBtn from "utils/backBtn";

const CreateNewPatient = ({
  status,
  patient,
  symptom,
  auth,
  createPatient,
  townshipState,
  getTownships,
  getSymptoms,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [showTBCode, setShowTBcode] = useState(false);
  const [latestPresumptiveNumber, setLatestPresumptiveNumber] = useState("");
  const [showVolunteer, setShowVolunteer] = useState(false);
  const [volunteers, setVolunteers] = useState([]);
  const [volunteerSelected, setVolunteerSelected] = useState(false);

  useEffect(() => {
    getSymptoms();
  }, []);

  useEffect(() => {
    getTownships();
  }, []);

  useEffect(() => {
    if (isVolunteerSupervisor(auth.user?.role)) {
      setVolunteer(auth.user?.township?.id);
      getLatestPresumptiveTBNumber(auth.user?.township?.id);
      setValue("township_id", auth.user?.township?.id);
    }
  }, []);

  async function getLatestPresumptiveTBNumber(township) {
    const response = await call(
      "get",
      `latest-presumptive-TB-Number/${township}`
    );
    setLatestPresumptiveNumber(response.data);
  }

  const setVolunteer = async (township_id) => {
    const result = await call(
      "get",
      `volunteers?${new URLSearchParams({
        township_id: township_id,
      }).toString()}`
    );
    const volunteers = result.data?.map((element) => ({
      value: element.id,
      label: `${element.name}(${element.code})`,
    }));
    setShowVolunteer(true);
    setVolunteers(volunteers);
  };

  const townships = townshipState.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const symptoms = symptom.symptoms?.map((element) => ({
    value: { eng_name: element.eng_name, myan_name: element.myan_name },
    label: element.eng_name,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    if (data.symptoms.length > 1) {
      let symptomData = data.symptoms?.reduce((tempArray, newSymptom) => {
        const existingSymptom = tempArray.find((symptom) => {
          return (
            symptom.eng_name === newSymptom?.eng_name &&
            symptom.myan_name === newSymptom?.myan_name
          );
        });
        if (!existingSymptom) {
          tempArray.push(newSymptom);
        }
        return tempArray;
      }, []);
      data.symptoms = JSON.stringify(symptomData);
    } else {
      data.symptoms = JSON.stringify(data.symptoms);
    }

    const result = await createPatient(data);
    if (result) {
      router.replace(`/admin/patient-fill/${result.id}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    // <div className="d-flex justify-content-center flex-column align-items-center">
    //   <Row className="my-3">

    //     <h3>New Patient</h3>
    //   </Row>

    <Container className="mt-3" fluid>
      <BackBtn />
      <div className="d-flex justify-content-center">
        <Card outline>
          <CardHeader className="bg-gray mx-0">
            <h4 className="text-white">New Patient</h4>
          </CardHeader>
          <CardBody className="pa-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <Row>
                  <Col md={4} className="mb-3">
                    <CustomInput
                      id="name"
                      label="Patient Name"
                      register={{
                        ...register("name", {
                          required: "Patient Name is required!",
                        }),
                      }}
                      placeholder="Name"
                      errors={errors}
                    />
                  </Col>

                  <Col md={4} className="mb-3">
                    <CustomInput
                      id="phone"
                      label="Phone Number"
                      type="number"
                      register={{
                        ...register("phone", {
                          required: "Phone Number is required!",
                        }),
                      }}
                      placeholder="Phone Number"
                      errors={errors}
                      min="999999"
                      max="99999999999"
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <CustomInput
                      id="age"
                      label="Age"
                      type="number"
                      register={{
                        ...register("age", {
                          required: "age is required!",
                        }),
                      }}
                      placeholder="Age"
                      errors={errors}
                      min="1"
                      max="120"
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="Gender"
                      control={control}
                      name="sex"
                      options={sexs}
                      rules={{ required: true }}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <CustomTextArea
                      id="address"
                      label="Address"
                      register={{
                        ...register("address", {
                          required: "Address is required!",
                        }),
                      }}
                      placeholder="Address"
                      errors={errors}
                      isRequired={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <SelectBox
                      label="Symptoms"
                      control={control}
                      name="symptoms"
                      options={symptoms}
                      rules={{ required: true }}
                      multiple={true}
                      onValueChange={(list) => {
                        setValue(
                          "symptoms",
                          list.map((l) => l.value)
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  {!isVolunteerSupervisor(auth.user.role) && (
                    <Col md={4}>
                      <SelectBox
                        label="Township"
                        control={control}
                        rules={{ required: true }}
                        name="township_id"
                        options={townships}
                        onValueChange={async (val) => {
                          setLatestPresumptiveNumber("");
                          await getLatestPresumptiveTBNumber(val);
                          await setVolunteer(val);
                        }}
                      />
                    </Col>
                  )}

                  <Col md={4}>
                    <SelectBox
                      label="Refer by (Volunteer)"
                      control={control}
                      name="referral_volunteer_id"
                      options={volunteers}
                      isRequired={false}
                      disabled={!showVolunteer}
                      onValueChange={(val) => {
                        if (val != null) setVolunteerSelected(true);
                        else {
                          setVolunteerSelected(false);
                          setValue("referred_type", null);
                          setValue("refer_to", null);
                          setValue("referral_date", null);
                          setValue("case_founded_activity", null);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4} className="mb-3">
                    <CustomDateInput
                      id="referral_date"
                      label="Referred date"
                      register={{
                        ...register("referral_date", {
                          required: volunteerSelected
                            ? "Refer date is required!"
                            : false,
                        }),
                      }}
                      placeholder="Refer date"
                      errors={errors}
                      min="1950-01-01"
                      max="2100-12-31"
                      disabled={!volunteerSelected}
                      isRequired={false}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="Refer to"
                      control={control}
                      name="refer_to"
                      options={referTo}
                      rules={{ required: volunteerSelected }}
                      disabled={!volunteerSelected}
                      isRequired={false}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="Case Founded At"
                      control={control}
                      name="case_founded_activity"
                      options={caseFoundActivity}
                      rules={{ required: volunteerSelected }}
                      disabled={!volunteerSelected}
                      onValueChange={(v) =>
                        v === "TB CI"
                          ? setShowTBcode(true)
                          : setShowTBcode(false)
                      }
                      isRequired={false}
                    />
                  </Col>

                  {showTBCode && (
                    <Col md={4} className="mb-3">
                      <CustomInput
                        id="TB_code"
                        label="TB Code"
                        register={{
                          ...register("TB_code", {
                            required: "TB_code is required!",
                          }),
                        }}
                        placeholder="TB Code"
                        errors={errors}
                        isRequired={false}
                      />
                    </Col>
                  )}
                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="Refer Type"
                      control={control}
                      name="referred_type"
                      options={referType}
                      rules={{ required: volunteerSelected }}
                      disabled={!volunteerSelected}
                      isRequired={false}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <CustomDateInput
                      id="registration_date"
                      label="Registration date"
                      register={{
                        ...register("registration_date", {
                          required: "Registration date is required!",
                        }),
                      }}
                      placeholder="Registration date"
                      errors={errors}
                      min="1950-01-01"
                      max="2100-12-31"
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <CustomInput
                      id="presumptive_TB_number"
                      label={`Presumptive TB number (Latest Presumptive Number is ${latestPresumptiveNumber})`}
                      register={{
                        ...register("presumptive_TB_number", {
                          required: "presumptive TB number is required!",
                        }),
                      }}
                      disabled={latestPresumptiveNumber === "" ? true : false}
                      placeholder="Presumptive TB number"
                      errors={errors}
                      type="number"
                      // min={
                      //   `${patient.patient.presumptive_TB_number
                      //     ? patient.patient.presumptive_TB_number
                      //     : latestPresumptiveNumber + 1}`
                      // }
                      // max={`${latestPresumptiveNumber + 1}`}
                      //max={latestPresumptiveNumber + 1}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="TB Contact"
                      control={control}
                      name="tb_contact"
                      options={yesOrNo}
                      rules={{ required: true }}
                    />
                  </Col>

                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="MDR TB Contact"
                      control={control}
                      name="mdr_tb_contact"
                      options={yesOrNo}
                      rules={{ required: true }}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <SelectBox
                      label="Previous TB History"
                      control={control}
                      name="previous_tb_history"
                      options={yesOrNo}
                      rules={{ required: true }}
                    />
                  </Col>
                </Row>
              </div>

              <div className="modal-footer d-flex justify-content-end">
                <Button color="success" type="submit" disabled={loading}>
                  {loading ? "Loading" : "Create"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Container>
    // </div>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  volunteer: store.volunteer,
  patient: store.patient,
  symptom: store.symptom,
  townshipState: store.township,
});

export default connect(mapStateToProps, {
  getVolunteers,
  getPatient,
  getSymptoms,
  getVolunteer,
  updatePatient,
  createPatient,
  getTownships,
})(CreateNewPatient);
