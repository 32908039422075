import {
  CREATE_SUPERVISION_VISIT,
  DELETE_SUPERVISION_VISIT,
  SET_SUPERVISION_VISIT,
  SHOW_SUPERVISION_VISITS,
  UPDATE_SUPERVISION_VISIT,
} from "../type";

const initialState = {
  supervision_visits: [],
  supervision_visit: {},
  total: 0,
};

const supervision_visit = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SUPERVISION_VISITS:
      return {
        ...state,
        supervision_visits: action.payload.data,
        total: action.payload.total,
      };
    case CREATE_SUPERVISION_VISIT:
      console.log(action.payload);
      return {
        ...state,
        supervision_visits: [action.payload, ...state.supervision_visits],
        total: state.total + 1,
      };
    case DELETE_SUPERVISION_VISIT:
      return {
        ...state,
        supervision_visits: state.supervision_visits.filter(
          (supervision_visit) => supervision_visit.id !== action.payload
        ),
        total: state.total - 1,
      };
    case SET_SUPERVISION_VISIT:
      return {
        ...state,
        supervision_visit: action.payload,
      }
    case UPDATE_SUPERVISION_VISIT:
      
      let index = state.supervision_visits.findIndex(
        (supervision_visit) => supervision_visit.id === action.payload.id,
      )
      state.supervision_visits[index] = action.payload
      if (state.supervision_visit.id === action.payload.id) {
        state.supervision_visit = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default supervision_visit
