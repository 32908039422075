import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";

import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import BackBtn from "utils/backBtn";
import CustomTable from "components/Table/Table";
import DotVisitDateUpdate from "./DOTVisitDateUpdate";
import { patientMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { getDOTs, getDOT, getPatient } from "store/actions";
import { updateTBconclusion } from "store/actions";
import { call } from "services/api";
import { DOTWorking } from "utils/DOTWorking";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import CustomPagination from "components/Pagination/Pagination";
import { deleteDotWithVolunteerDates } from "store/actions";
import CustomAlert from "components/Alert/CustomAlert";
import moment from "moment";
import DOTWithAppDetails from "./DOTWithAppDetails";
import DOTWithAppRemark from "./DOTWithAppRemark";
import { clearPatientData } from "store/actions";

const DOTWithAppList = ({
  status,
  auth,
  DOT,
  getDOTs,
  getDOT,
  patient,
  getPatient,
  deleteDotWithVolunteerDates,
  clearPatientData,
}) => {
  const router = useHistory();
  const { id } = useParams();
  const { role } = auth.user;
  const currentDate = moment();
  const [alert, setAlert] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isRemarkOpen, setIsRemarkOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getDOTs(id, query);
  }, [id, router.location.search, getDOTs]);

  // useEffect(() => {
  //   getPatient(id);
  // }, []);
  useEffect(() => {
    if (Object.keys(patient?.patient).length === 0) {
      console.log("get patient");
      getPatient(id);
    }
    return () => {
      clearPatientData();
    };
  }, []);

  const getWorkStatus = (status, date) => {
      if (status) {
        return 'အောင်မြင်ပါသည်'
      }
      let tempDotDate = moment(date.substring(0, 10));
      if (!status && currentDate.isAfter(tempDotDate, "day")) {
        return 'မအောင်မြင်ပါ'
      }
      return 'မသွားရသေးပါ'
  }
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `export-DOTs/${id}?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Volunteer_Name: data.change_volunteer?.volunteer?.name,
        Date: data.date,
        Work: data.work_status ? DOTWorking[data.work] : "",
        Work_Status:
        getWorkStatus(data.work_status, data?.date),
        ကြာချိန်: data.long,
        သာမာန်ဘေးထွက်ဆိုးကျိုး: data.minor_side_effects,
        အရေးကြီးဘေးထွက်ဆိုးကျိုး: data.major_side_effects,
        လုပ်ဆောင်ချက်: data.actions,
        Video: data.video ? data.video_url : null,
        Sign: data.sign ? data.sign_url : null,
        Type: data.change_volunteer?.type,
        Remark: data.remark,
      }));
      if (response.status === "success") {
       excelExport(result, "DOT-APP");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteDotWithVolunteerDates(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6" className="d-flex flex-column gap-3">
                <h3 className="mb-0">Dot Visit Date (app) </h3>
                <span className="fw-bold">{`Patient Name - ${patient.patient.name}`}</span>
                <span>{`TB Code - ${patient.patient?.TB_DRTB_code}`}</span>
                <span>
                  {`Current assign volunteer - ${patient.patient?.volunteer?.name}`}{" "}
                </span>
              </Col>

              <Col className="text-right d-flex flex-column" xs="6">
                <div>
                  {patientMiddleware(role, CREATE) &&
                    patient.patient?.type === "App" &&
                    (patient.patient?.DOT_end_date == null ||
                      patient.patient?.treatment_outcome == null) && (
                      <Button
                        size="sm"
                        color="success"
                        onClick={() =>
                          router.push(`/admin/dot-visit-date-app-new/${id}`)
                        }
                      >
                        New
                      </Button>
                    )}

                  <Button
                    size="sm"
                    color="info"
                    className=""
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => handleDelete(patient.patient?.id)}
                  >
                    Dates Clear
                  </Button>
                </div>
                <div className="d-flex flex-column mt-3">
                <span>
                    {`အောင်မြင်သည့်အရေအတွက် - ${DOT?.success_count}`}{' '}
                  </span>
                  <span>{`မအောင်မြင်သည့်အရေအတွက် - ${DOT?.fail_count}`} </span>
                  <span>
                    {`မသွားရသေးသည့် ရက်စုစုပေါင်း - ${DOT?.pending_count}`}{' '}
                  </span>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Volunteer</th>
                <th scope="col">Late Remark</th>
                <th scope="col">Remark</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={DOT.DOTs?.map((d, index) => {
              let tempDotDate = moment(d?.date.substring(0, 10));
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td
                    onClick={async () => {
                      await getDOT(d.id);
                      setIsOpen(true);
                    }}
                  >
                    {d.date}
                  </td>
                  <td className="fixed-cell left">
                    <span
                      className="p-2"
                      style={{
                        borderRadius: "10px",
                        backgroundColor:
                          d.work_status == 1
                            ? "green"
                            : currentDate.isAfter(tempDotDate, "day")
                            ? "red"
                            : "yellow",
                        color: d.work_status == 1 ? "white" : "black",
                      }}
                    >
                      {d.work_status == 1
                        ? "complete"
                        : currentDate.isAfter(tempDotDate, "day")
                        ? "not visited"
                        : "pending"}
                    </span>
                  </td>
                  <td>{d.change_volunteer?.volunteer?.name}</td>
                  <td
                    style={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {d.late_remark}
                  </td>
                  <td
                    style={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {d.remark}
                  </td>
                  
                    <td>
                    {currentDate.isAfter(tempDotDate, "day") && d.work_status != 1 && (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getDOT(d.id);
                          setIsRemarkOpen(true);
                        }}
                      >
                        Remark
                      </Button>
                      )}
                    </td>
                  
                </tr>
              );
            })}
          />
          {DOT.total > 28 && <CustomPagination pageCount={DOT.total / 28} />}
        </Card>
      </Container>

      <DotVisitDateUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <DOTWithAppDetails isOpen={isOpen} toggle={setIsOpen} />
      <DOTWithAppRemark isOpen={isRemarkOpen} toggle={setIsRemarkOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  DOT: store.DOT,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getDOTs,
  getDOT,
  getPatient,
  updateTBconclusion,
  deleteDotWithVolunteerDates,
  clearPatientData,
})(DOTWithAppList);
