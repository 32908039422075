import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomPagination from "components/Pagination/Pagination";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPatients,
  getPatient,
  getTownships,
  deletePatient,
  recoverPatient,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { paginationLimit } from "variables/limits";
import { isVolunteerSupervisor } from "utils/middleware";
import BackBtn from "utils/backBtn";
import CustomAlert from "components/Alert/CustomAlert";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import { call } from "services/api";

const RawDataTrashList = ({
  status,
  auth,
  patient,
  township,
  getPatients,
  getTownships,
  deletePatient,
  recoverPatient,
}) => {
  const { role } = auth.user;
  const router = useHistory();
  const [alert, setAlert] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);

  const patients = patient.patients?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (selectedPatient) {
      query.patient_id = selectedPatient.value;
    } else {
      delete query["patient_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setSelectedPatient(null);
    // setSelectedTownship(null);
  };

  useEffect(() => {
      const query = queryString.parse(router.location.search);
      query.accept_status = false;
    query.status = 0;
    if (!("page" in query)) {
      query.page = 1;
    }
    getPatients(query);
  }, [getPatients, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deletePatient(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const handleRecover = async (id) => {
    await recoverPatient(id);
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.accept_status = 0;
      query.status = 0;
      const response = await call(
        "get",
        `patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
               Code: data.code,
        Password: data.plain_password,

        Township: data.township?.name,
        Name: data.name,
        Address: data.address,
        Phone: data.phone,
        Age: data.age,
        Sex: data.sex,
        Referral_Volunteer: `${data.referral_volunteer?.name}(${data.referral_volunteer?.code})`,
        Referral_Date: data.referral_date,
        Refer_To: data.refer_to,
        Case_Founded_Activity: data.case_founded_activity,
        TB_Code: data.TB_code,
        Referred_Type: data.referred_type,
        Symptoms: JSON.parse(data.symptoms)
          .map((d) => d.eng_name)
          .join(","),
        Presumptive_TB_Number: data.presumptive_TB_number,
      }));

      if (response.status === "success") {
        excelExport(result, "Patients Raw Data Trash");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Raw data Trash List</h3>
              </Col>
              <Col className="text-right" xs={6}>
                <Button
                  size="sm"
                  color="info"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Select
                  className="mt-3"
                  options={patients}
                  value={selectedPatient}
                  onChange={(value) => setSelectedPatient(value)}
                  placeholder="Filter by patient..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              {!isVolunteerSupervisor(role) && (
                <Col sm={4}>
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Address</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={patient.patients?.map((patient, index) => (
              <tr key={patient.id}>
                <td className="fixed-cell left">{index + 1}</td>
                <td className="fixed-cell left">{patient.name}</td>
                <td>{patient.address}</td>
                <td>{patient.phone}</td>
                <td>{patient.age}</td>
                <td>{patient.sex}</td>
                <td>{patient.township.name}</td>
                <td>
                  <>
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => handleRecover(patient.id)}
                    >
                      Recover
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(patient.id)}
                    >
                      Delete
                    </Button>
                  </>
                </td>
              </tr>
            ))}
          />

          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getPatients,
  getPatient,
  getTownships,
  deletePatient,
  recoverPatient,
})(RawDataTrashList);
