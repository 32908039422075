import { array } from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { FormGroup } from "reactstrap";
import "./index.scss";
function SelectBox({
  control,
  options,
  name,
  rules,
  value,
  customStyles,
  onValueChange,
  multiple = false,
  disabled = false,
  label = null,
  defaultValue = null,
  isRequired = true,
  placeholder = "Select",
}) {
  const styles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };
  const checkDefaultValue = (value, options) => {
    if (typeof value === "array") {
      return value.map((v) => options.find((c) => c.value === v));
    }
    return options.find((c) => c.value === value);
  };

  return (
    <FormGroup>
      {label && (
        <label>
          {label} {isRequired && <sup className="text-danger">*</sup>}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={
          checkDefaultValue(value, options)
        }
        render={({
          field: { onChange, value, ref },
          fieldState: { invalid, error },
        }) => (
          <>
            <Select
              styles={styles}
              isDisabled={disabled}
              inputRef={ref}
              isMulti={multiple}
              classNamePrefix="addl-class"
              defaultValue={defaultValue ? defaultValue : value}
              options={options}
              value={checkDefaultValue(value, options)}
              onChange={(val) => {
                if (val) {
                  onChange(val.value);
                  const value = multiple ? val : val.value;
                  if (onValueChange) onValueChange(value);
                } else {
                  onChange(null);
                  if (onValueChange) onValueChange(null);
                }
              }}
              style={{ paddingBottom: 20 }}
              placeholder={placeholder}
              isClearable={true}
            />
            {invalid && (
              <span className="text-danger text-capitalize">{`${
                label ? label : name
              } is required`}</span>
            )}
          </>
        )}
      />
    </FormGroup>
  );
}
export default SelectBox;
