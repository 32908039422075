import React, { useEffect, useState } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col, Badge } from "reactstrap";
import MissDoseUpdate from "./MissDose_Details";
import MissDoseCreate from "./MissDose_Create";
import { connect } from "react-redux";
import {
  getMissDoses,
  getMissDose,
  deleteMissDose,
  getVolunteers,
  getTownships,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomAlert from "components/Alert/CustomAlert";
import { activitiesMiddleware, CREATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import { DELETE } from "utils/middleware";
import { useHistory } from "react-router";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { call } from "services/api";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import classNames from "classnames";
import {VscRefresh} from "react-icons/vsc"
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
const MissDoseList = ({
  status,
  auth,
  township,
  volunteer,
  getTownships,
  getVolunteers,
  missDose,
  getMissDoses,
  getMissDose,
  deleteMissDose,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const router = useHistory();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

   useEffect(() => {
     const query = queryString.parse(router.location.search);
     if (!("page" in query)) {
       query.page = 1;
     }
     getMissDoses(query);
   }, [getMissDoses, router.location.search]);
  
  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers, role]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: `${element.name} (${element.code})`,

  }));
  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (monthYear !== "") {
      query.month_year = monthYear;
    } else {
      delete query["month_year"];
    }

    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value;
    } else {
      delete query["volunteer_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setMonthYear("");
    // setSelectedVolunteer(null);
    // setSelectedTownship(null);
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `miss_doses-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Volunteer: `${data.volunteer?.name}(${data.volunteer?.code})`,
        Township: data.volunteer?.township?.name,
        "စေတနာ့ဝန်ထမ်းကုဒ်နံပါတ်": data.volunteer?.code,
        "တီဘီလူနာအမည်": data.name,
        "တီဘီလူနာကုဒ်": data.tb_patient_code,
        "လိပ်စာ": data.address,
        "အသက်": data.age,
        "ကျား/မ": data.sex,
        "phone": data.phone,
        "စာရင်းဝင်သည့်နေ့": data.registration_date,
        "ပထမအကြိမ် နေ့စွဲ" : data.first_time_tracing_date,
        "ပထမအကြိမ်ရလဒ်": data.first_time_tracing_result,
        "ပထမအကြိမ် မှတ်ချက်": data.first_time_tracing_remark,
        "ဒုတိယအကြိမ် နေ့စွဲ" : data.second_time_tracing_date,
        "ဒုတိယအကြိမ်ရလဒ်": data.second_time_tracing_result,
        "ဒုတိယအကြိမ် မှတ်ချက်": data.second_time_tracing_remark,
        "တတိယအကြိမ် နေ့စွဲ" : data.third_time_tracing_date,
        "တတိယအကြိမ်ရလဒ်": data.third_time_tracing_result,
        "တတိယအကြိမ် မှတ်ချက်": data.third_time_tracing_remark,
        "နောက်ဆုံးရလဒ်ထွက်သည့် နေ့စွဲ" : data.outcome_date,
        "နောက်ဆုံးရလဒ်": data.outcome_result,
        "မှတ်ချက်": data.outcome_remark,
      }));

      if (response.status === "success") {
        excelExport(result, "Miss Dose");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };


  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteMissDose(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleRefresh = () => {
    router.push(router.location.pathname);
  }
  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="8">
                <h3 className="mb-0">
                  ဆေးသောက်ပျက်ကွက်လူနာ ပြန်လည်ရှာဖွေခြင်း
                </h3>
              </Col>

              <Col className="text-right" xs="4">
                <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={() => handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                {activitiesMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>

              {role !== undefined && !isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}

              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
            <Row className="mt-3 ">
              <Col className="d-flex gap-3 justify-content-end">
                <Badge
                  style={{
                    padding: "10px",
                    backgroundColor: "green",
                    color: "white"
                  }}
                >
                  Finished
                </Badge>

                <Badge
                  style={{
                    padding: "10px",
                    backgroundColor: "yellow",
                    color: "black"
                  }}
                >
                  Ongoing
                </Badge>

                
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">No</th>
                <th scope="col">ရက်စွဲ</th>
                <th scope="col">Volunteer Code</th>
                <th scope="col">နာမည်</th>
                <th scope="col">တီဘီလူနာ ကုဒ်နံပါတ်</th>

                <th scope="col">Action</th>
              </tr>
            }
            body={missDose.missDoses?.map((t, index) => (
              <tr
                key={t.id}
                className={classNames(
                  { "bg-warning text-dark": t.outcome_result },
                  {
                    "bg-success text-white":
                      t.outcome_result ===
                        "သက်ဆိုင်ရာ ကျန်းမာရေးဌာနသို့ ပြန်လာခြင်း" ||
                      t.outcome_result ===
                        "အခြားဆေးကုသမူဌာနတွင်တီဘီကုသမူခံယူနေခြင်း" ||
                      t.outcome_result === "သေဆုံးခြင်း" ||
                      t.outcome_result === "အခြား",
                  }
                )}
              >
                <td>{index + 1}</td>
                <td>{t.registration_date}</td>
                <td>{t.volunteer.code}</td>
                <td>{t.name}</td>
                <td>{t.tb_patient_code}</td>

                <td>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={async () => {
                      await getMissDose(t.id);
                      setIsUpdateOpen(true);
                    }}
                  >
                    Details
                  </Button>
                  {activitiesMiddleware(auth.user.role, DELETE) && (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(t.id)}
                    >
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          />
          {missDose.total > paginationLimit && (
            <CustomPagination pageCount={missDose.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <MissDoseUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <MissDoseCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  missDose: store.missDose,
  volunteer: store.volunteer,
  township: store.township,
});

export default connect(mapStateToProps, {
  getMissDoses,
  getMissDose,
  deleteMissDose,
  getVolunteers,
  getTownships,
})(MissDoseList);
