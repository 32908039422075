import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { missDoseResults } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { activitiesMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { updateMissDoseResults } from "store/actions";
import dayjs from "dayjs";
import moment from "moment";

const ThirdTimeTracing = ({ missDose, auth, updateMissDoseResults }) => {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { third_time_tracing_date, third_time_tracing_result, remark } =
      missDose.missDose;
    setValue("third_time_tracing_date", third_time_tracing_date);
    setValue("third_time_tracing_result", third_time_tracing_result);
    setValue("remark", remark);
  }, [missDose.missDose, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateMissDoseResults(
      missDose.missDose?.id,
      data,
      "third-time-tracing"
    );
    setLoading(false);
  };
  const isDisable = useMemo(() => {
    if (!missDose.missDose?.second_time_tracing_result) {
      return true;
    }
    if (
      !missDose.missDose?.third_time_tracing_result &&
      missDose.missDose?.outcome_result
    ) {
      return true;
    }
    return false;
  }, [
    missDose.missDose?.outcome_result,
    missDose.missDose?.second_time_tracing_result,
    missDose.missDose?.third_time_tracing_result,
  ]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <CustomDateInput
            id="third_time_tracing_date"
            label="Third Time Tracing Date"
            register={{
              ...register("third_time_tracing_date"),
            }}
            errors={errors}
            min={dayjs(
              moment(missDose.missDose?.second_time_tracing_date).add(1, "days")
            ).format("YYYY-MM-DD")}
            disabled={isDisable}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <SelectBox
            label="Third Time Tracing Result"
            control={control}
            name="third_time_tracing_result"
            options={missDoseResults}
            disabled={isDisable}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <CustomTextArea
            id="remark"
            label="Remark"
            register={{
              ...register("remark", {
                required: false,
              }),
            }}
            placeholder="Remark"
            errors={errors}
            disable={isDisable}
          />
        </Col>
      </Row>
      {activitiesMiddleware(auth.user?.role, UPDATE) && !isDisable && (
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  missDose: store.missDose,
});

export default connect(mapStateToProps, { updateMissDoseResults })(
  ThirdTimeTracing
);
