import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_PATIENT_MEDICINE,
  DELETE_PATIENT_MEDICINE,
  SET_LOADING,
  SET_PATIENT_MEDICINE,
  SET_SUCCESS,
  SHOW_PATIENT_MEDICINES,
  UPDATE_PATIENT_MEDICINE,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getPatientMedicines = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', `patient-medicines/${id}`)
      const result = response.data

      dispatch({
        type: SHOW_PATIENT_MEDICINES,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getPatientMedicine = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `patient-medicine/${id}`)
      const result = response.data
         
      dispatch({
        type: SET_PATIENT_MEDICINE,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createPatientMedicine = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'patient-medicines', data)
      const result = response.data

      dispatch({
        type: CREATE_PATIENT_MEDICINE,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'Patient Medicine has been created successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updatePatientMedicine = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        'post',
        `patient-medicines/${id}?_method=PUT`,
        data,
      )
      const result = response.data

      dispatch({
        type: UPDATE_PATIENT_MEDICINE,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'Patient Medicine has been updated successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const deletePatientMedicine = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
     await call('delete', `patient-medicines/${id}`)

      dispatch({ type: DELETE_PATIENT_MEDICINE, payload: id })
      NotificationManager.success(
        'Patient Medicine has been deleted successfully!',
      )
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
