import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updateAccount } from "store/actions";
import { roles } from "variables/options";
import SelectBox from "components/Selectbox";

const AccountUpdate = ({
  isOpen,
  toggle,
  status,
  account,
  township,
  updateAccount,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const { name, role, township } = account.account;
    setValue("name", name);

    setValue("township_id", township?.id);
    setValue("role", role);
    if (role === "Volunteer Supervisor") setShow(true);
  }, [setValue, account.account]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateAccount(account.account.id, data);
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Account"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />

          <SelectBox
            label="Select Role"
            control={control}
            name="role"
            options={roles}
            rules={{ required: true }}
            onValueChange={(v) => {
              if (v !== "Volunteer Supervisor") {
                setShow(false);
                setValue("township_id", null);
              }
            }}
          />
          {show && (
            <SelectBox
              label="Select Township"
              control={control}
              name="township_id"
              options={townships}
              rules={{ required: true }}
            />
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  account: store.account,
  township: store.township,
});

export default connect(mapStateToProps, { updateAccount })(AccountUpdate);
