import React, { useEffect, useState } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import PeopleHEUpdate from "./PeopleHE_Details";
import PeopleHECreate from "./PeopleHE_Create";
import { connect } from "react-redux";
import {
  getHEs,
  getHE,
   deleteHE,
  getTownships,
  getVolunteers,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomAlert from "components/Alert/CustomAlert";
import { activitiesMiddleware, CREATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import { DELETE } from "utils/middleware";
import { useHistory } from "react-router";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { call } from "services/api";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import {VscRefresh} from "react-icons/vsc"
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";

const PeopleHEList = ({
  status,
  auth,
  township,
  volunteer,
  getTownships,
  getVolunteers,
  he,
  getHEs,
  deleteHE,
  getHE
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const router = useHistory();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getHEs(query);
  }, [getHEs,router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [role, getTownships]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: `${element.name} (${element.code})`,

  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (monthYear !== "") {
      query.month_year = monthYear;
    } else {
      delete query["month_year"];
    }

    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value;
    } else {
      delete query["volunteer_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setMonthYear("");
    // setSelectedVolunteer(null);
    // setSelectedTownship(null);
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `hes-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Volunteer: `${data.volunteer?.name}(${data.volunteer?.code})`,
        Township: data.volunteer?.township?.name,
        "စေတနာ့ဝန်ထမ်းကုဒ်နံပါတ်" : data.volunteer?.code,
        Date: data.date,
        "ကျင်းပသည့်နေရာ": data.place,
        "တက်ရောက်သူအရေအတွက်(ကျား)": data.male,
        "တက်ရောက်သူအရေအတွက်(မ)": data.female,
        "တက်ရောက်သူအရေအတွက်(စုစုပေါင်း)": data.total,
        "ဆွေးနွေးခဲ့သောအကြောင်းအရာ": data.topic_discussed,
        "ဆွေးနွေးခဲ့သောအကြောင်းအရာ(အခြား)": data.topic_discussed_other,
      }));

      if (response.status === "success") {
        excelExport(result, "HE");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteHE(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleRefresh = () => {
    router.push(router.location.pathname);
  }
  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">ကျန်းမာရေး အသိပညာပေးခြင်း</h3>
              </Col>

              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={() => handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                {activitiesMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      setIsCreateOpen(true);
                    }}
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>

              {role !== undefined && !isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}

              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">နံပါတ်</th>
                <th scope="col">ရက်စွဲ</th>
                <th scope="col">စေတနာ့ဝန်ထမ်း ကုဒ်နံပါတ်</th>
                <th scope="col">ကျင်းပသည့်နေရာ</th>
                <th scope="col">တတ်ရောက်သူ စုစုပေါင်း</th>
                <th scope="col">ကျား</th>
                <th scope="col">မ</th>

                <th scope="col">Action</th>
              </tr>
            }
            body={he.hes?.map((t, index) => (
              <tr key={t.id}>
                <td>{index + 1}</td>
                <td>{t.date}</td>
                <td>{t.volunteer.code}</td>
                <td>{t.place}</td>
                <td>{t.total}</td>
                <td>{t.male}</td>
                <td>{t.female}</td>

                <td>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={async () => {
                      await getHE(t.id);
                      setIsUpdateOpen(true);
                    }}
                  >
                    Details
                  </Button>
                  {activitiesMiddleware(auth.user.role, DELETE) && (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(t.id)}
                    >
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          />
          {he.total > paginationLimit && (
            <CustomPagination pageCount={he.total / paginationLimit} />
          )}
        </Card>
      </Container>
      <PeopleHEUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <PeopleHECreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  he: store.he,
  volunteer: store.volunteer,
  township: store.township,
});

export default connect(mapStateToProps, {
  getHEs,
  getHE,
  deleteHE,
  getTownships,
  getVolunteers,
})(PeopleHEList);
