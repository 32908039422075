import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import AccountCreate from "./AccountCreate";
import CustomPagination from "components/Pagination/Pagination";
import CustomTable from "components/Table/Table";
import { roles } from "variables/options";
import { connect } from "react-redux";
import {
  getAccounts,
  deleteAccount,
  getAccount,
  getTownships,
  passwordReset
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import AccountUpdate from "./AccountUpdate";
import { paginationLimit } from "variables/limits";
import CustomAlert from "components/Alert/CustomAlert";
import BackBtn from "utils/backBtn";
import {
  accountMiddleware,
  CREATE,
  DELETE,
  EXPORT,
  UPDATE,
} from "../../../../utils/middleware";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import { call } from "services/api";
import AccountEmailUpdate from "./AccountEmailUpdate";


const AccountList = ({
  status,
  auth,
  account,
  getAccounts,
  deleteAccount,
  getAccount,
  getTownships,
  passwordReset
}) => {
  const router = useHistory();
  const [alert, setAlert] = useState(null);

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);
  const [isEmailUpdateOpen, setIsEmailUpdateOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getAccounts(query);
  }, [getAccounts, router.location.search]);

  useEffect(() => {
    getTownships();
  }, [getTownships]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (email !== "") {
      query.email = email;
    } else {
      delete query["email"];
    }
    if (role) {
      query.role = role.value;
    } else {
      delete query["role"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setEmail("");
    // setRole(null);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteAccount(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `users-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Township: data.township?.name,
        Name: data.name,
        Email: data.email,
        Plain_Password: data.plain_password,
        Role: data.role,
      }));

      if (response.status === "success") {
        excelExport(result, "Accounts");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Accounts</h3>
              </Col>
              <Col className="text-right" xs="6">
                {/* <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={()=> getAccounts()}
                >
                  <VscRefresh/>
                </Button> */}
                {accountMiddleware(auth.user.role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                )}
                {accountMiddleware(auth.user.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Input
                  bsSize="sm"
                  type="text"
                  placeholder="Enter Email"
                  className="mt-3"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Col>
              <Col md={3}>
                <Select
                  className="mt-3"
                  options={roles}
                  value={role}
                  onChange={(value) => setRole(value)}
                  placeholder="Filter by role..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-3"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-3"
                  onClick={() => router.push(router.location.pathname)}
                >
                  All
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Plain Password</th>
                <th scope="col">Role</th>
                <th scope="col">Township</th>
                {accountMiddleware(auth.user.role, UPDATE) &&
                  accountMiddleware(auth.user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={account.accounts?.map((account, index) => (
              <tr key={account.id}>
                <td>{index + 1}</td>
                <td>{account.name}</td>
                <td>{account.email}</td>
                <td>{account.plain_password}</td>
                <td>{account.role}</td>
                <td>{account.township?.name}</td>
                {accountMiddleware(auth.user.role, UPDATE) &&
                  accountMiddleware(auth.user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getAccount(account.id);
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="warning"
                        className="text-white"
                        onClick={async () => {
                          await getAccount(account.id);
                          setIsEmailUpdateOpen(true);
                        }}
                      >
                        Email Update
                      </Button>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={async () => {
                          await passwordReset(account.id);
                        }}
                      >
                        Password reset
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(account.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />

          {account.total > paginationLimit && (
            <CustomPagination pageCount={account.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <AccountCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
      <AccountUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <AccountEmailUpdate
        isOpen={isEmailUpdateOpen}
        toggle={setIsEmailUpdateOpen}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  account: store.account,
});

export default connect(mapStateToProps, {
  getAccounts,
  deleteAccount,
  getAccount,
  getTownships,
  passwordReset,
})(AccountList);
