import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { updateTownship } from 'store/actions'

const TownshipUpdate = ({
  isOpen,
  toggle,
  status,
  region,
  district,
  township,
  updateTownship,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const regions = region.regions.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const districts = district.districts.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    const { name, abbreviation, region, district } = township.township

    setValue('name', name)
    setValue('abbreviation', abbreviation)
    setValue('region', { value: region?.id, label: region?.name })
    setSelectedRegion({ value: region?.id, label: region?.name })
    setValue('district', { value: district?.id, label: district?.name })
    setSelectedDistrict({ value: district?.id, label: district?.name })
  }, [setValue, township.township])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateTownship(township.township.id, {
      name: data.name,
      region_id: data.region.value,
      district_id: data.district.value,
      abbreviation: data.abbreviation,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Township"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="region"
            label="Select Region"
            rules={{ required: 'Region is required!' }}
            control={control}
            options={regions}
            value={selectedRegion}
            setData={setSelectedRegion}
            placeholder="Select region"
          />
          <CustomSelect
            id="district"
            label="Select District"
            rules={{ required: 'District is required!' }}
            control={control}
            options={districts}
            value={selectedDistrict}
            setData={setSelectedDistrict}
            placeholder="Select district"
          />
          <CustomInput
            id="name"
            label="Township Name"
            register={{
              ...register('name', {
                required: 'Township Name is required!',
              }),
            }}
            placeholder="Enter township name"
            errors={errors}
          />
          <CustomInput
            id="abbreviation"
            label="Township Abbreviation"
            register={{
              ...register('abbreviation', {
                required: 'Township Abbreviation is required!',
              }),
            }}
            placeholder="Enter township abbreviation"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  region: store.region,
  district: store.district,
  township: store.township,
})

export default connect(mapStateToProps, { updateTownship })(TownshipUpdate)
