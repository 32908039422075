import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Col, Label, FormGroup, Row } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import { isVolunteerSupervisor } from "utils/middleware";
import { getTownshipVolunteers } from "store/actions";
import CustomDateInput from "components/Inputs/CustomDateInput";

const PeopleHEDetails = ({ isOpen, toggle, auth, he, township, volunteer }) => {
  const {
    register,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const [topicDiscussedOther, setTopicDiscussedOther] = useState(false);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const {
      date,
      male,
      female,
      topic_discussed_other,
      place,
      topic_discussed,
      volunteer,
      image_url,
      image
    } = he.he;
    setValue("date", date);
    setValue("township_id", volunteer?.township?.id);
    setValue("volunteer_id", volunteer?.id);
    setValue("male", male);
    setMale(male);
    setValue("female", female);
    setFemale(female);

    if (topic_discussed_other) {
      setTopicDiscussedOther(true);
      setValue("topic_discussed_other", topic_discussed_other);
    }
    setValue("topic_discussed", topic_discussed);
    setValue("place", place);

    if (image) {
      setImage(image_url);
    }
  }, [he.he, setValue, volunteer.volunteers]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  return (
    <CustomModal
      isOpen={isOpen}
      title={"ကျန်းမာရေးအသိပညာပေးခြင်း အသေးစိတ်"}
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ minWidth: "50%" }}
    >
      <Form>
        <div className="modal-body p-3">
          <Row>
            <Col md={6}>
              <FormGroup>
                <CustomDateInput
                  id="Date"
                  label="Date"
                  register={{
                    ...register("date"),
                  }}
                  errors={errors}
                  disabled={true}
                  isRequired={false}
                />
              </FormGroup>
            </Col>
            {!isVolunteerSupervisor(auth.user.role) && (
              <Col md={6}>
                <SelectBox
                  label="Township"
                  control={control}
                  rules={{ required: true }}
                  name="township_id"
                  options={townships}
                  disabled={true}
                />
              </Col>
            )}
            {!isVolunteerSupervisor(auth.user.role) && (
              <Col md={6}>
                <SelectBox
                  label="Volunteer"
                  control={control}
                  rules={{ required: true }}
                  name="volunteer_id"
                  options={volunteers}
                  disabled={true}
                />
              </Col>
            )}
            <Col md={6}>
              <CustomInput
                id="place"
                label="Place"
                register={{
                  ...register("place", {
                    required: "Place is required!",
                  }),
                }}
                errors={errors}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label>Attendent List</Label>
              <div className="d-flex">
                <Col>
                  <CustomInput
                    register={{
                      ...register("male", {
                        required: "တတ်ရောက်သူ ကျား အရေအတွက် is required!",
                      }),
                    }}
                    errors={errors}
                    type="number"
                    max="99"
                    min="0"
                    isRequired={false}
                    placeholder="ကျား"
                    onChanged={(e) => {
                      e.target.value !== ""
                        ? setMale(parseInt(e.target.value))
                        : setMale(0);
                    }}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <CustomInput
                    register={{
                      ...register("female", {
                        required: "တတ်ရောက်သူ မ အရေအတွက် is required!",
                      }),
                    }}
                    errors={errors}
                    type="number"
                    max="99"
                    min="0"
                    isRequired={false}
                    placeholder="မ"
                    onChanged={(e) => {
                      e.target.value !== ""
                        ? setFemale(parseInt(e.target.value))
                        : setFemale(0);
                    }}
                    disabled={true}
                  />
                </Col>
                <Col className="d-flex flex-column">
                  <Label>Total</Label>
                  <span>{male + female}</span>
                </Col>
              </div>
            </Col>

            <Col md={6} className="mb-3">
              <Label>ဆွေးနွေးခဲ့သော အကြောင်းအရာ</Label>
              <div style={{ border: "1px solid gray" }} className="px-3 py-1">
                {he.he?.topic_discussed}
              </div>
            </Col>
            {topicDiscussedOther && (
              <Col md={6}>
                <CustomInput
                  id="place"
                  label="အခြား"
                  register={{
                    ...register(
                      "topic_discussed_other",
                      topicDiscussedOther && {
                        required: "အခြားအကြောင်းအရာ is required!",
                      }
                    ),
                  }}
                  errors={errors}
                  disabled={true}
                />
              </Col>
            )}
          </Row>
          {image && (
            <Row>
              <Col md={6} className="d-flex flex-column">
                <label>Image</label>
                <img src={image} alt="Image" width={150} height={150} />
                <a href={image} target="_blank">
                  Click link to view full image
                </a>
              </Col>
            </Row>
          )}
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  township: store.township,
  he: store.he,
});

export default connect(mapStateToProps, { getTownshipVolunteers })(
  PeopleHEDetails
);
