import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { yesOrNo } from 'variables/options'
import { patientMiddleware } from 'utils/middleware'
import { UPDATE } from 'utils/middleware'
import CustomTextArea from 'components/Inputs/CustomTextArea'

const ineligibleOptions = [
  { value: 'No Phone', label: 'No Phone' },
  { value: 'Imcompatible Phone Version', label: 'Imcompatible Phone Version' },
  { value: 'Keypad Phone', label: 'Keypad Phone' },
  { value: 'Others', label: 'Others' },
]

const Eligible = ({ patient, updatePatient, auth }) => {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm()

  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [selectedEligible, setSelectedEligible] = useState(null)
  const [selectedInEligibleReason, setSelectedInEligibleReason] = useState(null)

  useEffect(() => {
    const { VOT_eligible, VOT_ineligible_reason, VOT_ineligible_other_reason } = patient.patient

    setValue(
      'VOT_eligible',
      VOT_eligible === null
        ? null
        : {
            value: VOT_eligible,
            label: VOT_eligible,
          }
    )
    setSelectedEligible(
      VOT_eligible === null
        ? null
        : {
            value: VOT_eligible,
            label: VOT_eligible,
          }
    )
    setValue(
      "VOT_ineligible_reason",
      VOT_ineligible_reason === null
        ? null
        : {
            value: VOT_ineligible_reason,
            label: VOT_ineligible_reason,
          }
    );
    setSelectedInEligibleReason(
      VOT_ineligible_reason === null
        ? null
        : {
            value: VOT_ineligible_reason,
            label: VOT_ineligible_reason,
          }
    );
    setValue("VOT_ineligible_other_reason", VOT_ineligible_other_reason);
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      id,
      {
        VOT_eligible: data.VOT_eligible?.value,
        VOT_ineligible_reason:
          selectedEligible?.value === 'No'
            ? selectedInEligibleReason?.value
            : null,
        VOT_ineligible_other_reason:
        selectedEligible?.value === 'No' &&
          selectedInEligibleReason?.value === 'Others'
            ? data.VOT_ineligible_other_reason
            : null,
      },
      'VOT-eligible',
      'VOT Eligible'
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="VOT_eligible"
              label="VOT_Eligible"
              control={control}
              rules={{
                required: 'VOT Eligible is required!',
              }}
              options={yesOrNo}
              value={selectedEligible}
              setData={setSelectedEligible}
              placeholder="VOT Eligible"
              isRequired={true}
            />
          </Col>
          {selectedEligible?.value === 'No' && (
            <Col md={4}>
              <CustomSelect
                id="VOT_Eligible_reason"
                label="VOT Ineligible Reason"
                control={control}
                rules={{
                  required: 'Ineligible Reason is required!',
                }}
                options={ineligibleOptions}
                value={selectedInEligibleReason}
                setData={setSelectedInEligibleReason}
                placeholder="VOT Ineligible Reason"
                isRequired={true}
              />
            </Col>
          )}
        </Row>
        {selectedEligible?.value === 'No' &&
          selectedInEligibleReason?.value === 'Others' && (
            <Row>
              <Col md={4}>
                <CustomTextArea
                  id="VOT_Ineligible_other_reason"
                  label="VOT Ineligible other reason"
                  register={{
                    ...register('VOT_ineligible_other_reason'),
                  }}
                  placeholder="VOT Ineligible other reason"
                  errors={errors}
                />
              </Col>
            </Row>
          )}

        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? 'Loading' : 'Update'}
            </Button>
          </div>
        )}
      </div>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
  auth: store.auth,
})

export default connect(mapStateToProps, { updatePatient })(Eligible)
