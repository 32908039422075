import {
  CREATE_TOWNSHIP,
  DELETE_TOWNSHIP,
  SET_TOWNSHIP,
  SHOW_TOWNSHIPS,
  UPDATE_TOWNSHIP,
} from '../type'

const initialState = {
  townships: [],
  township: {},
  total: 0,
}

const township = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOWNSHIPS:
      return {
        ...state,
         townships: action.payload.data,
        //  townships: action.payload,
        total: action.payload.total,
      };
    case CREATE_TOWNSHIP:
      return {
        ...state,
        townships: [action.payload, ...state.townships],
        // townships: [...state.townships],
        total: state.total + 1,
      }
    case DELETE_TOWNSHIP:
      return {
        ...state,
        townships: state.townships.filter(
          (township) => township.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_TOWNSHIP:
      return {
        ...state,
        township: action.payload,
      }
    case UPDATE_TOWNSHIP:
      let index = state.townships.findIndex(
        (township) => township.id === action.payload.id,
      )
      state.townships[index] = action.payload
      if (state.township.id === action.payload.id) {
        state.township = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default township
