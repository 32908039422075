//auth
import Login from "views/pages/auth/Login";
import ChangePassword from "views/pages/auth/ChangePassword";
// dashboard
import Dashboard from "views/pages/dashboard";
import AccountList from "views/pages/dashboard/account/AccountList";

// basic data
import DistrictList from "views/pages/dashboard/basicData/district/DistrictList";
import RegionList from "views/pages/dashboard/basicData/region/RegionList";
import TownshipList from "views/pages/dashboard/basicData/township/TownshipList";

// volunteer
import TrainingList from "views/pages/dashboard/activity/training/TrainingList";
import VolunteerList from "views/pages/dashboard/volunteer/VolunteerList";
import VolunteerTrainingList from "views/pages/dashboard/activity/training/VolunteerTrainingList";

// handout
import VolunteerHandoutList from "views/pages/dashboard/handout/VolunteerHandout/VolunteerHandoutList";
import PatientHandoutList from "views/pages/dashboard/handout/PatientHandout/PatientHandoutList";

//activity
import TBCI_List from "views/pages/dashboard/activity/tb-ci/TBCI_List";
import MissDose_List from "views/pages/dashboard/activity/missDose/MissDose_List";
import SuperVisionVisit_List from "views/pages/dashboard/activity/superVisionVisit/SuperVisionVisit_List";
import PeopleHE_List from "views/pages/dashboard/activity/peopleHE/PeopleHE_List";
import SymptomList from "views/pages/dashboard/symptom/SymptomList";
import MedicineList from "views/pages/dashboard/medicine/MedicineList";

// trash
import RawDataTrashList from "views/pages/dashboard/trash/RawDataTrash/RawDataTrashList";
import VolunteerTrashList from "views/pages/dashboard/trash/VolunteerTrash/VolunteerTrashList";
import PatientTrashList from "views/pages/dashboard/trash/PatientTrash/PatientTrashList";

// patient
import RawDataList from "views/pages/dashboard/patient/RawDataList";
import CreateNewPatient from "views/pages/dashboard/patient/CreateNewPatient";
import AllPatientList from "views/pages/dashboard/patient/AllPatientList";
import PatientDetails from "views/pages/dashboard/patient/PatientDetails";
import CBTBCPatientPage from "views/pages/dashboard/patient/index";

// DOT Patient with No Volunteer
import DOTPatientWithVolunteer from "views/pages/dashboard/DOTPatientWithVolunteer";
import DOTPatientWithVolunteerDetails from "views/pages/dashboard/DOTPatientWithVolunteer/DOTPatientWithVolunteerDetails";
import DOTVisitDateCreate from "views/pages/dashboard/DOTPatientWithVolunteer/DOTWithApp/DOTVisitDateCreate";
import DOTWithAppList from "views/pages/dashboard/DOTPatientWithVolunteer/DOTWithApp";
import NoAppDotList from "views/pages/dashboard/DOTPatientWithVolunteer//DOTWithNoApp/index";

// with No Volunteer
import DOTPatientWithNoVolunteer from "views/pages/dashboard/DOTPatientWithNoVolunteer/index";
import DOTPatientWithNoVolunteerDetails from "views/pages/dashboard/DOTPatientWithNoVolunteer/DOTPatientWithNoVolunteerDetails";
import NoVolunteerDotList from "views/pages/dashboard/DOTPatientWithNoVolunteer/DOTStatus/index";

// No DOT
import NoDOTPatientList from "views/pages/dashboard/NoDOTPatient";

// VOT patient
import VOTEligiblePatientList from "views/pages/dashboard/patient/VOTEligiblePatientList";
import VOTAcceptablePatient from "views/pages/dashboard/patient/VOTAcceptablePatient";
import VOTDeclinedPatientList from "views/pages/dashboard/patient/VOTDeclinedPatientList";
import ScreenedPatientList from "views/pages/dashboard/patient/ScreenedPatientList";

// move to dot
import MoveToDOT from "views/pages/dashboard/patient/detailComponents/MoveToDOT";
//change to volunteer
import ChangeVolunteerCreate from "views/pages/dashboard/changeVolunteer/ChangeVolunteerCreate";
import ChangeVolunteerList from "views/pages/dashboard/changeVolunteer/ChangeVolunteerList";
// patient medicine
import PatientMedicineList from "views/pages/dashboard/patientMedicine/PatientMedicineList";
//FU Sputum
import FUSputumExaminitionList from "views/pages/dashboard/FUSputumExaminition/FUSputumExaminitionList";

// visualization
import VolunteerVisualization from "views/pages/dashboard/visualization/VolunteerVisulization";
import Visualization from "views/pages/dashboard/visualization";
import PatientVisualization from "views/pages/dashboard/visualization/PatientVisualization";
import DotPatientVisualization from "views/pages/dashboard/visualization/DotPatientVisualization";
import ActivityVisualization from "views/pages/dashboard/visualization/ActivityVisualization";
import VolunteerReportViz from "views/pages/dashboard/visualization/VolunteerReportViz";

//report
import VOTReport from "views/pages/dashboard/report/VOT";
import VolunteerReport from "views/pages/dashboard/report/VolunteerReport";
import VolunteerSupervisiorReport from "views/pages/dashboard/report/VolunteerSupervisiorReport";
import NTPCBTBCTemplate from "views/pages/dashboard/report/NTPCBTBCTemplate";
import VDotCalendar from "views/pages/dashboard/patient/detailComponents/VDotCalendar";
import PatientList from "views/pages/dashboard/patient/PatientList";
import TBPatientList from "views/pages/dashboard/patient/TBPatientList";


const routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-archive-2 text-green",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    icon: "ni ni-archive-2 text-green",
    component: ChangePassword,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/visualization",
    name: "Visualization",
    icon: "ni ni-image text-primary",
    component: Visualization,
    layout: "/admin",
  },
  {
    path: "/volunteer-visualization",
    name: "VolunteerVisualization",
    icon: "ni ni-archive-2 text-green",
    component: VolunteerVisualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-visualization",
    name: "patientVisualization",
    icon: "ni ni-archive-2 text-green",
    component: PatientVisualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-patient-visualization",
    name: "dotpatientVisualization",
    icon: "ni ni-archive-2 text-green",
    component: DotPatientVisualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/activity-visualization",
    name: "activityVisualization",
    icon: "ni ni-archive-2 text-green",
    component: ActivityVisualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/viz-volunteer-report",
    name: "VolunteerReportViz",
    icon: "ni ni-archive-2 text-green",
    component: VolunteerReportViz,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/account-list",
    name: "Accounts",
    icon: "ni ni-single-02 text-primary",
    component: AccountList,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Basic Data",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "basicDataCollapse",
    views: [
      {
        path: "/symptom",
        name: "Symptoms",
        miniName: "ST",
        component: SymptomList,
        layout: "/admin",
      },
      {
        path: "/medicine",
        name: "Medicines",
        miniName: "ML",
        component: MedicineList,
        layout: "/admin",
      },
      {
        path: "/region-list",
        name: "State/Regions",
        miniName: "RL",
        component: RegionList,
        layout: "/admin",
      },
      {
        path: "/district-list",
        name: "Districts",
        miniName: "DL",
        component: DistrictList,
        layout: "/admin",
      },
      {
        path: "/township-list",
        name: "Townships",
        miniName: "TL",
        component: TownshipList,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/volunteer-list",
    name: "Volunteers",
    icon: "fas fa-user-friends text-primary",
    component: VolunteerList,
    layout: "/admin",
  },
  {
    path: "/volunteer-training-list/:id",
    name: "VolunteerTraining",
    icon: "ni ni-archive-2 text-blue",
    component: VolunteerTrainingList,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/raw-data",
    name: "Raw Data (refer)",
    miniName: "RD",
    component: RawDataList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/all-patient-list",
    name: "AllPatientList",
    component: AllPatientList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-list",
    name: "Patient List",
    miniName: "PL",
    component: PatientList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/new-patient",
    name: "PatientFill",
    component: CreateNewPatient,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-fill/:id",
    name: "PatientFill",
    component: PatientDetails,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "SCLTBC Patients",
    icon: "ni ni-collection text-primary",
    state: "CBTBCPatientsCollapse",
    views: [
      {
        path: "/CBTBC-patient-list",
        name: "Presumptive Patients",
        miniName: "AP",
        component: CBTBCPatientPage,
        layout: "/admin",
      },
      {
        path: "/tb-patient-list",
        name: "TB Patients",
        miniName: "AP",
        component: TBPatientList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "VOT Eligible",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "VOTEligibleCollapse",
    views: [
      {
        path: "/screened-patient-list",
        name: "Screened Patients",
        miniName: "SP",
        component: ScreenedPatientList,
        layout: "/admin",
      },
      {
        path: "/eligible-patient-list",
        name: "Eligible Patients",
        miniName: "EP",
        component: VOTEligiblePatientList,
        layout: "/admin",
      },
      {
        path: "/acceptable-patient-list",
        name: "Acceptable Patients",
        miniName: "AP",
        component: VOTAcceptablePatient,
        layout: "/admin",
      },
      {
        path: "/declined-patient-list",
        name: "Declined Patients",
        miniName: "DP",
        component: VOTDeclinedPatientList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "VOT Patients",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "DOTPatientsCollapse",
    views: [
      {
        path: "/dot-patient-with-volunteer-list",
        name: "VOT patient with volunteer",
        miniName: "DV",
        component: DOTPatientWithVolunteer,
        layout: "/admin",
      },
      {
        path: "/dot-patient-with-no-volunteer-list",
        name: "VOT patient with no volunteer",
        miniName: "DN",
        component: DOTPatientWithNoVolunteer,
        layout: "/admin",
      },
      {
        path: "/no-dot-patient-list",
        name: "No VOT patients",
        miniName: "ND",
        component: NoDOTPatientList,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/dot-patient-detail/:id",
    name: "DotPatientDetail",
    icon: "ni ni-archive-2 text-blue",
    component: DOTPatientWithVolunteerDetails,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-patient-with-no-volunteer-detail/:id",
    name: "DotPatientWithNoVolunteerDetail",
    icon: "ni ni-archive-2 text-blue",
    component: DOTPatientWithNoVolunteerDetails,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/no-volunteer-dot-list/:id",
    name: "NoVolunteerDotList",
    icon: "ni ni-archive-2 text-blue",
    component: NoVolunteerDotList,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/move-to-DOT/:id",
    name: "MoveToDOT",
    icon: "ni ni-archive-2 text-blue",
    component: MoveToDOT,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/change-volunteer-create/:id",
    name: "ChangeVolunteerCreate",
    icon: "ni ni-archive-2 text-blue",
    component: ChangeVolunteerCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/change-volunteer-list/:id",
    name: "ChangeVolunteerList",
    icon: "ni ni-archive-2 text-blue",
    component: ChangeVolunteerList,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/patient-medicine-list/:id",
    name: "Patient Medicine",
    icon: "ni ni-archive-2 text-blue",
    component: PatientMedicineList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-visit-date-app-new/:id",
    name: "Infection Checks",
    icon: "ni ni-archive-2 text-blue",
    component: DOTVisitDateCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-visit-date-app/:id",
    name: "Infection Checks",
    icon: "ni ni-archive-2 text-blue",
    component: DOTWithAppList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-visit-date-noapp/:id",
    name: "Infection Checks",
    icon: "ni ni-archive-2 text-blue",
    component: NoAppDotList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/vot-calendar/:id",
    name: "VOT calendar",
    icon: "ni ni-archive-2 text-blue",
    component: VDotCalendar,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/FU-sputum-examinition-list/:id",
    name: "F/U Sputum Examinitions",
    icon: "ni ni-archive-2 text-blue",
    component: FUSputumExaminitionList,
    layout: "/admin",
    invisible: true,
  },

  {
    collapse: true,
    name: "Activities",
    icon: "ni ni-archive-2 text-primary",
    state: "ActivitiessCollapse",
    views: [
      {
        path: "/he-list",
        name: "ကျန်းမာရေးအသိပညာပေးခြင်း",
        miniName: "HE",
        component: PeopleHE_List,
        layout: "/admin",
      },
      {
        path: "/training-list",
        name: "သင်တန်းရရှိမှုစာရင်း",
        miniName: "TR",
        component: TrainingList,
        layout: "/admin",
      },

      {
        path: "/tb-ci",
        name: "လူနာနှင့်မိသားစုအတွင်းကျန်းမာရေးအသိပညာပေးခြင်း",
        miniName: "CT",
        component: TBCI_List,
        layout: "/admin",
      },
      {
        path: "/miss-dose",
        name: "ဆေးသောက်ပျက်ကွက်လူနာပြန်လည်ရှာဖွေခြင်း",
        miniName: "CT",
        component: MissDose_List,
        layout: "/admin",
      },
      {
        path: "/supervision-visit",
        name: "ကွင်းဆင်းကြီးကြပ်သည့်အရေအတွက်",
        miniName: "CT",
        component: SuperVisionVisit_List,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Report",
    icon: "ni ni-book-bookmark text-primary",
    state: "reportCollapse",
    views: [
      {
        path: "/volunteer-report",
        name: "Volunteer Report",
        miniName: "VR",
        component: VolunteerReport,
        layout: "/admin",
      },
      {
        path: "/volunteer-supervisior-report",
        name: "Volunteer Supervisior Report",
        miniName: "VR",
        component: VolunteerSupervisiorReport,
        layout: "/admin",
      },

      {
        path: "/NTP-CBTBC-Report",
        name: "NTP CBTBC Report",
        miniName: "NTP",
        component: NTPCBTBCTemplate,
        layout: "/admin",
      },
      {
        path: "/VOT-Report",
        name: "VOT Report",
        miniName: "VOT",
        component: VOTReport,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Handouts",
    icon: "ni ni-archive-2 text-primary",
    state: "handoutCollapse",
    views: [
      {
        path: "/volunteer-handout",
        name: "Volunteer Handout",
        miniName: "VH",
        component: VolunteerHandoutList,
        layout: "/admin",
      },
      {
        path: "/patient-handout",
        name: "Patient Handout",
        miniName: "PH",
        component: PatientHandoutList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Trash",
    icon: "ni ni-app text-primary",
    state: "trashCollapse",
    views: [
      {
        path: "/patient-trash-list",
        name: "Patients",
        miniName: "AP",
        component: PatientTrashList,
        layout: "/admin",
      },
      {
        path: "/rawdata-trash-list",
        name: "RawData",
        miniName: "AP",
        component: RawDataTrashList,
        layout: "/admin",
      },
      {
        path: "/volunteer-trash-list",
        name: "Volunteer",
        miniName: "AP",
        component: VolunteerTrashList,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
