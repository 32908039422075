import { ACTIVITY_VISUALIZATION, DOT_PATIENT_VISUALIZATION, PATIENT_VISUALIZATION, VOLUNTEER_REPORT_VISUALIZATION, VOLUNTEER_VISUALIZATION } from "../type";

const initialState = {
  volunteerVisualization: {},
  patientVisualization: {},
  dotPatientVisualization: {},
  activityVisualization: {},
  volunteerReportVisualization: {},
};

const visualization = (state = initialState, action) => {
  switch (action.type) {
    case VOLUNTEER_VISUALIZATION:
      return {
        ...state,
        volunteerVisualization: action.payload,
      };
    case PATIENT_VISUALIZATION:
      return {
        ...state,
        patientVisualization: action.payload,
      };
    case DOT_PATIENT_VISUALIZATION:
      return {
        ...state,
        dotPatientVisualization: action.payload,
      };
    case ACTIVITY_VISUALIZATION:
      return {
        ...state,
        activityVisualization: action.payload,
      };
      case VOLUNTEER_REPORT_VISUALIZATION:
    return {
        ...state,
        volunteerReportVisualization: action.payload,
      };
    default:
      return state;
  }
};

export default visualization;
