import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import { years } from "variables/options";
import { reportMonths } from "variables/options";
import { connect } from "react-redux";
import { getVolunteers, getTownships, getVolunteerReport } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { isVolunteerSupervisor } from "utils/middleware";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import styles from "./VolunteerReport.module.css";

const VolunteerReport = ({
  status,
  auth,
  township,
  volunteer,
  getVolunteers,
  getTownships,
  getVolunteerReport,
  report,
}) => {
  const { role } = auth.user;
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const router = useHistory();

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers, role]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
  useEffect(() => {
    const query = queryString.parse(router.location.search);
    getVolunteerReport(query);
  }, [getVolunteerReport, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    if (month) {
      console.log(month);
      query.month = month.value;
    } else {
      delete query["month"];
    }
    if (year) {
      query.year = year.value;
    } else {
      delete query["year"];
    }

    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value;
    } else {
      delete query["volunteer_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setMonth("");
    // setYear("");
    // setSelectedVolunteer(null);
    // setSelectedTownship(null);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Volunteer Report</h3>
            </Col>
           
            <Col className="text-right" xs={6}>
            <Button
                size="sm"
                color="success"
                onClick={handleFilter}
                
              >
                <i className="fa fa-search" />
              </Button>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="btn btn-sm bg-primary text-white border-0"
                table="table-to-xls"
                filename="Volunteer report"
                sheet="Volunteer report"
                buttonText="Export"
              />
            </Col>
           
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={volunteers}
                value={selectedVolunteer}
                onChange={(value) => setSelectedVolunteer(value)}
                placeholder="Filter by volunteer..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col md={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(e) => setMonth(e)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(e) => setYear(e)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>

           
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <Table
            responsive
            className="align-items-center table-flush mb-5"
            id="table-to-xls"
          >
            <thead className="bg-info text-white">
              <tr>
                <th scope="col">No</th>

                <th scope="col">Indicator</th>
                <th></th>
                <th scope="col">Male</th>
                <th scope="col">Female</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody className="list">
              <tr>
                <td>1</td>
                <td>
                  <div className="d-flex flex-column">
                    No. of volunteer referred presumptive TB cases reach to clinic
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ပြည်သူလူထုအတွင်းမှ ညွှန်းပို့သည့်
                        တီဘီသံသယလူနာအရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of referred patients by volunteers (referral date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.referPatient?.male}</td>
                <td>{report.volunteerReport?.referPatient?.female}</td>
                <td>
                  {report.volunteerReport?.referPatient?.male +
                    report.volunteerReport?.referPatient?.female}
                </td>
              </tr>

              <tr>
                <td>2</td>
                <td>
                  <div className="d-flex flex-column">
                    No.of presumptive TB cases examined
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ညွှန်းပို့သူများအနက်မှ စစ်ဆေးသည့် တီဘီသံသယလူနာအရေအတွက်
                        )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of registered patients (registration date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.registeredPatient?.male}</td>
                <td>{report.volunteerReport?.registeredPatient?.female}</td>
                <td>
                  {report.volunteerReport?.registeredPatient?.male +
                    report.volunteerReport?.registeredPatient?.female}
                </td>
              </tr>

              <tr>
                <td>3</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No. of Total TB patients referred by volunteer
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ညွှန်းပို့သူများအနက်မှ တီဘီဆေးစတင်ကုသသူအရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of TB patients (treatment registration date is used, TB status must be TB or MDR TB)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.TBPatient?.male}</td>
                <td>{report.volunteerReport?.TBPatient?.female}</td>
                <td>
                  {report.volunteerReport?.TBPatient?.male +
                    report.volunteerReport?.TBPatient?.female}
                </td>
              </tr>

              <tr>
                <td>4</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No Of Index TB patients receiving contact investigation
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (TB-CI ဆောင်ရွက်ခဲ့သည့် သတ်မှတ်လူနာအရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of TB-CI sessions
                    </span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerReport?.TBCT?.total}</td>
              </tr>

              <tr>
                <td>5</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No of presumptive TB contacts examined by Contact
                    Investigation
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( TB-CIမှ ညွှန်းပို့ပြီး စစ်ဆေးသည့် တီဘီသံသယလူနာ
                        အရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of TB-CI patients (registration date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.TBCTPatient?.male}</td>
                <td>{report.volunteerReport?.TBCTPatient?.female}</td>
                <td>
                  {report.volunteerReport?.TBCTPatient?.male +
                    report.volunteerReport?.TBCTPatient?.female}
                </td>
              </tr>

              <tr>
                <td>6</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Presumptive TB cases diagnosed as TB referred by
                    contact tracing
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( TB-CIမှ ညွှန်းပို့ပြီးစစ်ဆေးခံသူများအနက်မှ
                        တီဘီဆေးစတင်ကုသသူ အရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of TB patients found from TB CI  (treatment registration date is used, TB status must be TB or MDR TB)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.TBPatientFromTBCT?.male}</td>
                <td>{report.volunteerReport?.TBPatientFromTBCT?.female}</td>
                <td>
                  {report.volunteerReport?.TBPatientFromTBCT?.male +
                    report.volunteerReport?.TBPatientFromTBCT?.female}
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No Of TB patients on DOTS provided by Volunteers
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( မိမိမှ တီဘီဆေးသောက်ရန်ကြီးကြပ်ဆဲ လူနာအရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of DOT patients (DOT start date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.DotPatient?.male}</td>
                <td>{report.volunteerReport?.DotPatient?.female}</td>
                <td>
                  {report.volunteerReport?.DotPatient?.male +
                    report.volunteerReport?.DotPatient?.female}
                </td>
              </tr>

              <tr>
                <td>8</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No Of TB patients who completed DOTS
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ဆေးကုသမှုပြီးစီးသည့် တီဘီလူနာအရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of DOT finished patients (DOT end date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.DotFinishedPatient?.male}</td>
                <td>{report.volunteerReport?.DotFinishedPatient?.female}</td>
                <td>
                  {report.volunteerReport?.DotFinishedPatient?.male +
                    report.volunteerReport?.DotFinishedPatient?.female}
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  <div className="d-flex flex-column">
                    No of Frequency traced by MD/LFU activities
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ဆေးသောက်ပျက်ကွက်ပြန်လည် ရှာဖွေသည့် အကြိမ်အရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of miss dose patient visits (first, second , third time visits, related tracing dates are used )
                    </span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerReport?.MissDose?.totalTracingCount}</td>
              </tr>

              <tr>
                <td>10</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Patients traced by MD/LFU activities
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ပြန်လည်ရှာဖွေတွေ့ရှိသည့် ဆေးသောက်ပျက်ကွက် လူနာအရေအတွက်
                        )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of miss dose patients (row counts, outcome date is used, only good options (options number 2,4,6,8) are counted)
                    </span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerReport?.MissDose?.total}</td>
              </tr>

              <tr>
                <td>11</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No. of health education sessions conducted by volunteers
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ပြည်သူလူထုအတွင်း ကျန်းမာရေးပညာပေးသည့်အကြိမ်အရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>Count of HE list</span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerReport?.HE?.total}</td>
              </tr>

              <tr>
                <td>12</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Health education attendees in this township
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (ပြီးခဲ့သောလအတွင်း
                        ပြည်သူလူထုအတွင်းကျန်းမာရေးလုပ်ငန်းများဆောင်ရွက်ရာတွင်
                        တက်ရောက်ခဲ့သူစုစုပေါင်း )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of HE attendees
                    </span>
                  </div>
                </td>
                <td>{report.volunteerReport?.HE?.male}</td>
                <td>{report.volunteerReport?.HE?.female}</td>
                <td>
                  {report.volunteerReport?.HE?.male +
                    report.volunteerReport?.HE?.female}
                </td>
              </tr>

              <tr>
                <td>13</td>
                <td>
                  {" "}
                  <div className="d-flex flex-column">
                    No Of HE sessions To TB household members and non-household
                    members Contacts
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( အတူနေမိသားစုအားကျန်းမာရေးပညာပေးသည့်အကြိမ်အရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of HE and TB-CI list
                    </span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerReport?.TBCTAndHE?.total}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  report: store.report,
});

export default connect(mapStateToProps, {
  getVolunteers,
  getTownships,
  getVolunteerReport,
})(VolunteerReport);
