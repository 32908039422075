import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { VOLUNTEER_SUPERVISIOR_REPORT, SET_LOADING, VOLUNTEER_REPORT,CBTBC_REPORT, VOT_REPORT } from "../type";
import { NotificationManager } from "react-notifications";

export const getVolunteerReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `volunteer-report?${new URLSearchParams(query).toString()}`
      );
      dispatch({
        type: VOLUNTEER_REPORT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getVolunteerSupervisiorReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `volunteer-supervisior-report?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: VOLUNTEER_SUPERVISIOR_REPORT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
export const getCBTBCReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `CBTBC-report?${new URLSearchParams(query).toString()}`
      );
      dispatch({
        type: CBTBC_REPORT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getVotReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `vot-report?${new URLSearchParams(query).toString()}`
      );
      dispatch({
        type: VOT_REPORT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
