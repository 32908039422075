import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Col, Label } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import { CBTBCPatientTypes } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { sexs } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";

const TBCIDetails = ({
  isOpen,
  toggle,
  volunteer,
  tb_ci,
  status
}) => {
  const {
    register,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [image, setImage] = useState(null);


  useEffect(() => {
    const {
      date,
      volunteer,
      male,
      female,
      tb_patient_code,
      name,
      address,
      age,
      sex,
      tb_type,
      remark,
      image,
      image_url
    } = tb_ci.tb_ci;
    setValue("date", date);
    setValue("volunteer_id", volunteer?.id);
    setValue("male", male);
    setMale(male);
    setValue("female", female);
    setFemale(female);
    setValue("tb_patient_code", tb_patient_code);
    setValue("name", name);
    setValue("address", address);
    setValue("age", age);
    setValue("tb_type", tb_type);
    setValue("sex", sex);
    setValue("remark", remark);
    if (image) {
      setImage(image_url);
    }
    console.log(image_url);
  }, [tb_ci.tb_ci, setValue, image]);

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
   useEffect(() => {
     if (status.success) {
       reset();
        toggle(false);
     }
   }, [status.success, reset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="လူနာနှင့်မိသားစုအတွင်း ကျန်းမာရေးအသိပညာပေးခြင်း အသေးစိတ်"
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ minWidth: "70%" }}
    >
      <Form>
        <div className="modal-body d-flex flex-wrap">
          <Col md={4}>
            <CustomDateInput
              id="Date"
              label="Date"
              register={{
                ...register("date"),
              }}
              errors={errors}
              disabled={true}
              isRequired={false}
            />
          </Col>
          <Col md={4}>
            <SelectBox
              label="Volunteer"
              control={control}
              rules={{ required: true }}
              name="volunteer_id"
              options={volunteers}
              disabled={true}
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="tb_patient_code"
              label="TB Code"
              register={{
                ...register("tb_patient_code", {
                  required: "TB  Code is required!",
                }),
              }}
              placeholder="TB Code"
              errors={errors}
              disabled={true}
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="name"
              label="Name"
              register={{
                ...register("name", {
                  required: "Name is required!",
                }),
              }}
              errors={errors}
              disabled={true}
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="age"
              label="Age"
              register={{
                ...register("age", {
                  required: "Age is required!",
                }),
              }}
              errors={errors}
              type="number"
              max="99"
              disabled={true}
            />
          </Col>
          <Col md={4}>
            <SelectBox
              label="Gender"
              control={control}
              name="sex"
              options={sexs}
              rules={{ required: true }}
              disabled={true}
            />
          </Col>
          <Col md={4}>
            <CustomTextArea
              id="address"
              label="Address"
              register={{
                ...register("address", {
                  required: "address is required!",
                }),
              }}
              errors={errors}
              disable={true}
            />
          </Col>
          <Col md={4}>
            <Label>Attendent List</Label>
            <div className="d-flex">
              <Col>
                <CustomInput
                  register={{
                    ...register("male", {
                      required: "တတ်ရောက်သူ ကျား အရေအတွက် is required!",
                    }),
                  }}
                  errors={errors}
                  type="number"
                  max="99"
                  min="0"
                  isRequired={false}
                  placeholder="male"
                  onChanged={(e) => {
                    e.target.value !== ""
                      ? setMale(parseInt(e.target.value))
                      : setMale(0);
                  }}
                  disabled={true}
                />
              </Col>
              <Col>
                <CustomInput
                  register={{
                    ...register("female", {
                      required: "တတ်ရောက်သူ မ အရေအတွက် is required!",
                    }),
                  }}
                  errors={errors}
                  type="number"
                  max="99"
                  min="0"
                  isRequired={false}
                  placeholder="female"
                  onChanged={(e) => {
                    e.target.value !== ""
                      ? setFemale(parseInt(e.target.value))
                      : setFemale(0);
                  }}
                  disabled={true}
                />
              </Col>
              <Col className="d-flex flex-column">
                <Label>Total</Label>
                <span>{male + female}</span>
              </Col>
            </div>
          </Col>
          <Col md={4}>
            <SelectBox
              label="TB Types"
              control={control}
              name="tb_type"
              options={CBTBCPatientTypes}
              rules={{ required: true }}
              disabled={true}
            />
          </Col>
          <Col md={4}>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register("remark", {
                  required: false,
                }),
              }}
              placeholder="Remark"
              errors={errors}
              disable={true}
            />
          </Col>
          {image && (
            <Col md={6} className="d-flex flex-column">
              <label>Image</label>
              <img src={image} alt="Image" width={150} height={150} />
              <a href={image} target="_blank">
                Click link to view full image
              </a>
            </Col>
          )}
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  tb_ci: store.tb_ci,
});

export default connect(mapStateToProps, { })(TBCIDetails);
