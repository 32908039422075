import React, {  useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { createPatientMedicine, getMedicines } from "store/actions";
import SelectBox from "components/Selectbox";

const PatientMedicineCreate = ({
  isOpen,
  toggle,
  status,
  medicine,
  createPatientMedicine,
}) => {
  const { handleSubmit, reset, control } = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const medicines = medicine.medicines?.map((element) => ({
    value: element.name,
    label: element.name + '(' + element.acronym + ')',
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    const chosenMedicine = medicine.medicines?.find((m)=> m.name === data.name)
    await createPatientMedicine({
      patient_id: id,
      name: chosenMedicine.name,
      acronym: chosenMedicine.acronym,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [reset,status.success]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Patient's Medicine"
      onClick={() => {
        toggle(false);
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <SelectBox
            label="Select Medicine"
            rules={{ required: true }}
            control={control}
            options={medicines}
            name="name"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  medicine: store.medicine,
});

export default connect(mapStateToProps, {
  createPatientMedicine,
  getMedicines,
})(PatientMedicineCreate);
