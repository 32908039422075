import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getVolunteerVisualization } from "store/actions";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import BarChart from "components/charts/BarChart";
import PieChart from "components/charts/PieChart";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import { stackedBarCharConfig } from 'utils/chartConfig';
import { primaryColor } from "components/charts/constant";
import { secondaryColor } from "components/charts/constant";

const VolunteerVisualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, visualization, township, auth } = useSelector(
    (state) => state
  );
  const { volunteerVisualization } = visualization;
  const {
    by_training,
    by_age,
    by_education_status,
    by_townships,
    by_active_status,
  } = volunteerVisualization;
  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getVolunteerVisualization(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const all_volunteer_bar = {
    labels: by_townships?.data?.map((row) => row.township_name),
    datasets: [
      {
        label: "Male",
        data: by_townships?.data?.map((data) => data.male),
        backgroundColor: primaryColor,
      },
      {
        label: "Female",
        data: by_townships?.data?.map((data) => data.female),
        backgroundColor: secondaryColor,
      },
    ],
  };
  const active_status_bar = {
    labels: by_active_status?.data?.map((data) => data.township_name),
    datasets: [
      {
        label: "Male",
        data: by_active_status?.data?.map((data) => data.male),
        backgroundColor: primaryColor,
      },
      {
        label: "Female",
        data: by_active_status?.data?.map((data) => data.female),
        backgroundColor: secondaryColor,
      },
    ],
  };
  // const trained_volunteer_bar = {
  //   labels: by_training?.data?.map((data) => data.township_name),
  //   datasets: [
  //     {
  //       label: "Male",
  //       data: by_training?.data?.map((data) => data.male),
  //       backgroundColor: primaryColor,
  //     },
  //     {
  //       label: "Female",
  //       data: by_training?.data?.map((data) => data.female),
  //       backgroundColor: secondaryColor,
  //     },
  //   ],
  // };
  const trained_volunteer_bar = {
    labels: by_training?.data?.map((data) => data.township_name),
    datasets: [
      {
        label: "SCLTBC",
        data: by_training?.data?.map((data) => data.scl),
        backgroundColor: primaryColor,
      },
      {
        label: "VOT",
        data: by_training?.data?.map((data) => data.vot),
        backgroundColor: secondaryColor,
      },
    ],
  };

  const age_pie = {
    labels: ["<18", ">18 and <30", ">30"],
    datasets: [
      {
        label: "volunteer age",
        data: [by_age?.less_18, by_age?.between_18_30, by_age?.greater_30],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const education_status_pie = {
    labels: ["Primary", "Secondary", "Higher"],
    datasets: [
      {
        label: "education status",
        data: [
          by_education_status?.primary,
          by_education_status?.secondary,
          by_education_status?.higher,
        ],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Volunteer Data Visualization</CardTitle>
          <Row className="mt-3">
            {!isVolunteerSupervisor(role) && (
              <Col sm={4} className="mt-4">
                <Select
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}

            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>

            <Col sm={1} className="mt-4">
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <BarChart
                title={"All Volunteers in townships"}
                data={all_volunteer_bar}
                total={by_townships?.total}
                uniqueChartClassName={"all_volunteers"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"Active volunteers"}
                data={active_status_bar}
                total={by_active_status?.total}
                uniqueChartClassName={"active_volunteers"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"Trained volunteers"}
                data={trained_volunteer_bar}
                total={by_training?.total}
                uniqueChartClassName={"trained_volunteers"}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <PieChart
                title={"All volunteers' ages"}
                data={age_pie}
                total={by_townships?.total}
                uniqueChartClassName={"volunteers_ages"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieChart
                title={"All volunteers' education status"}
                data={education_status_pie}
                total={by_townships?.total}
                uniqueChartClassName={"volunteers_education_status"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default VolunteerVisualization;
