import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import {
  CHANGE_STATUS_NOAPP,
  CREATE_DOTS,
  DELETE_DOT,
  SET_DOT,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_DOTS,
  UPDATE_DOT_DATE,
  DELETE_DOT_DATES,
  CREATE_DOT
} from "../type";
import { NotificationManager } from "react-notifications";

export const getAllDOTs = (patient, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `all-DOTs/${patient}?${new URLSearchParams(query).toString()}`
      );
      dispatch({
        type: SHOW_DOTS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getDOTs = (patient, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `DOTs/${patient}?${new URLSearchParams(query).toString()}`
      );
      dispatch({
        type: SHOW_DOTS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getNoAppDOTs = (patient, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `NoApp-DOTs/${patient}?${new URLSearchParams(query).toString()}`
      );
      dispatch({
        type: SHOW_DOTS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getNoVolunteerDOTs = (patient, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `NoVolunteer-DOTs/${patient}?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: SHOW_DOTS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getDOT = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("get", `DOT/${id}`);
      const result = response.data;
      dispatch({
        type: SET_DOT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createDOT = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "store-DOTs", data);
      const result = response.data;

      dispatch({
        type: CREATE_DOTS,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("DOT has been created successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};
export const createNoAppDOT = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "store-NoApp-DOTs", data);
      const result = response.data;

      dispatch({
        type: CREATE_DOTS,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("DOT has been created successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const createNoVolunteerDOT = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "store-NoVolunteer-DOTs", data);
      const result = response.data;

      dispatch({
        type: CREATE_DOT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("DOT has been created successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const changeStatusNoApp = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("get", `change-status-NoApp-DOTs/${id}`);
      const result = response.data;
      dispatch({
        type: CHANGE_STATUS_NOAPP,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
export const updateDotDate = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", `DOTs-date/${id}?_method=PUT`, data);
      const result = response.data;
      dispatch({
        type: UPDATE_DOT_DATE,
        payload: result,
      });
      NotificationManager.success("Date has been changed successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const updateRemark = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "post",
        `DOTs-remark/${id}?_method=PUT`,
        data
      );
      const result = response.data;
      dispatch({
        type: UPDATE_DOT_DATE,
        payload: result,
      });
      NotificationManager.success("Remark has been changed successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const deleteDOT = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `delete-DOTs/${id}`);

      dispatch({ type: DELETE_DOT, payload: id });
      NotificationManager.success("DOT has been deleted successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        //setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const deleteDotWithVolunteerDates = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `delete-dot-dates/${id}`);
      dispatch({ type: DELETE_DOT_DATES, payload: id });
      NotificationManager.success("Dot Dates has been deleted successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
