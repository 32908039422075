import React from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardHeader,
  Button,
} from "reactstrap";

const Visualization = () => {
  const router = useHistory();

  return (
    <Container className="mt-3" fluid>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <VisualItemCard
                title={"Volunteers"}
                handleOnClick={() =>
                  router.push(`/admin/volunteer-visualization`)
                }
                color="bg-gradient-primary"
              />
            </Col>

            <Col md="4">
              <VisualItemCard
                title={"Patients"}
                handleOnClick={() =>
                  router.push(`/admin/patient-visualization`)
                }
                color="bg-gradient-warning"
              />
            </Col>
            <Col md="4">
              <VisualItemCard
                title={"DOT Patients"}
                handleOnClick={() =>
                  router.push(`/admin/dot-patient-visualization`)
                }
                color="bg-gradient-success"
              />
            </Col>
            <Col md="4">
              <VisualItemCard
                title={"Activity"}
                handleOnClick={() =>
                  router.push(`/admin/activity-visualization`)
                }
                color="bg-gradient-danger"
              />
            </Col>
            <Col md="4">
              <VisualItemCard
                title={"Volunteer Report"}
                handleOnClick={() =>
                  router.push(`/admin/viz-volunteer-report`)
                }
                color="bg-gradient-warning"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Visualization;

const VisualItemCard = ({ title, handleOnClick,color }) => {
  return (
    <Card className={color}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle className="text-uppercase mb-0 text-white" tag="h3">
              {title}
            </CardTitle>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
              <i className="fas fa-user-friends" />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <Button size="sm" color="primary" onClick={handleOnClick}>
            Detail
          </Button>
        </p>
      </CardBody>
    </Card>
  );
};
