import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import CustomPagination from "components/Pagination/Pagination";
import queryString from "query-string";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getNoVolunteerDOTs, getDOT, getPatient } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { currentDate } from "utils/currentDate";
import BackBtn from "utils/backBtn";
import CustomTable from "components/Table/Table";
import { host } from "services/api";
import NoVolunteerDotRemark from "./NoVolunteerDotRemark";
import NoVolunteerDotCreate from "./NoVolunteerDotCreate";
import { clearPatientData } from "store/actions";
import CustomAlert from "components/Alert/CustomAlert";
import { deleteDOT } from "store/actions";

const NoVolunteerDotList = ({
  status,
  patient,
  DOT,
  getDOT,
  getNoVolunteerDOTs,
  getPatient,
  clearPatientData,
  deleteDOT,
}) => {
  const { id } = useParams();
  const router = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRemarkOpen, setIsRemarkOpen] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getNoVolunteerDOTs(id, query);
  }, [getNoVolunteerDOTs, id, router.location.search]);

  // useEffect(() => {
  //   getPatient(id);
  // }, [getPatient, id]);

  useEffect(() => {
    if (Object.keys(patient?.patient).length === 0) {
      getPatient(id);
    }
    return () => {
      clearPatientData();
    };
  }, []);

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteDOT(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <h3 className="mb-3">({patient.patient?.name}) DOT Status</h3>
            <Row>
              <Col sm={4}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  min={
                    patient.patient?.DOT_start_date === null
                      ? ""
                      : patient.patient?.DOT_start_date
                  }
                  max={currentDate()}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col>
                <Button
                  size="sm"
                  color="success"
                  className="mt-4"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
              <Col>
                <Button
                  size="sm"
                  color="success"
                  className="mt-4"
                  onClick={() => setIsOpen(true)}
                >
                  New
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {/* <Row>
              {DOT.DOTs.map((DOT, index) => {
                return (
                  <Col
                    key={index}
                    xs={3}
                    md={3}
                    lg={2}
                    className={`text-center ${styles.box}`}
                    onClick={async () => {
                      await getDOT(DOT.id);
                      setIsOpen(true);
                    }}
                  >
                    {DOT.date}
                  </Col>
                );
              })}
            </Row> */}
            <CustomTable
              header={
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Video</th>
                  <th scope="col">Late remark</th>
                  <th scope="col">Remark</th>
                  <th scope="col">Action</th>
                </tr>
              }
              body={DOT.DOTs?.map((d, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>

                    <td>
                      <span className="p-2">{d?.date}</span>
                    </td>
                    <td>
                      {d.video && (
                        <a
                          href={`${host}/DOT-video/${d?.uuid}`}
                          target="_black"
                        >
                          Click Link To View
                        </a>
                      )}
                    </td>
                    <td
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {d.late_remark}
                    </td>
                    <td
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {d.remark}
                    </td>
                    <td>
                      {!d.video && (
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleDelete(d.id)}
                        >
                          Delete
                        </Button>
                      )}
                      {!d.video && (
                        <Button
                          size="sm"
                          color="primary"
                          onClick={async () => {
                            await getDOT(d.id);
                            setIsRemarkOpen(true);
                          }}
                        >
                          Remark
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            />
          </CardBody>

          {DOT.total > 30 && <CustomPagination pageCount={DOT.total / 30} />}
        </Card>
      </Container>
      <NoVolunteerDotRemark isOpen={isRemarkOpen} toggle={setIsRemarkOpen} />
      <NoVolunteerDotCreate isOpen={isOpen} toggle={setIsOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
});

export default connect(mapStateToProps, {
  getDOT,
  getNoVolunteerDOTs,
  getPatient,
  clearPatientData,
  deleteDOT,
})(NoVolunteerDotList);
