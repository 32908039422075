import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import SelectBox from 'components/Selectbox'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { updatePatient } from 'store/actions'
import { patientMiddleware } from 'utils/middleware'
import { UPDATE } from 'utils/middleware'
import CustomTextArea from 'components/Inputs/CustomTextArea'
import { VDOTCategories } from 'variables/options'
import { call } from 'services/api'

const VDotConclusion = ({ patient, updatePatient, auth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const {
      vdot_conclusion_category,
      vdot_conclusion_date,
      vdot_conclusion_remark,
    } = patient.patient

    setValue('vdot_conclusion_category', vdot_conclusion_category)
    setValue('vdot_conclusion_date', vdot_conclusion_date)
    setValue('vdot_conclusion_remark', vdot_conclusion_remark)
  }, [patient.patient, setValue])

  useEffect(() => {
    const getLatestDotDate = async () => {
      if (patient?.patient?.vdot_conclusion_date) {
        return;
      }
      if (patient?.patient?.id) {
        try {
          const response = await call(
            'get',
            `latest-success-dot-date/${patient?.patient?.id}`
          )
          if (response?.data) {
            setValue('vdot_conclusion_date', response.data)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    getLatestDotDate()
  }, [patient?.patient, setValue])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      patient.patient.id,
      {
        vdot_conclusion_category: data.vdot_conclusion_category,
        vdot_conclusion_date: data.vdot_conclusion_date,
        vdot_conclusion_remark: data.vdot_conclusion_remark,
      },
      'vdot-conclusion',
      'VDOT Conclusion'
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4} className="mb-3">
            <CustomDateInput
              id="vdot_conclusion_date"
              label="VDOT conclusion date"
              register={{
                ...register('vdot_conclusion_date', {
                  required: 'VDOT conclusion date is required!',
                }),
              }}
              placeholder="VDOT conclusion date"
              errors={errors}
              min="1950-01-01"
              max="2100-12-31"
            />
          </Col>
          <Col md={4} className="mb-3">
            <SelectBox
              label="VDOT Outcome"
              control={control}
              name="vdot_conclusion_category"
              options={VDOTCategories}
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register('vdot_conclusion_remark'),
              }}
              placeholder="Remark"
              errors={errors}
            />
          </Col>
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? 'Loading' : 'Update'}
            </Button>
          </div>
        )}
      </div>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(VDotConclusion)
