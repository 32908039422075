import {
  VOLUNTEER_REPORT,
  VOLUNTEER_SUPERVISIOR_REPORT,
  CBTBC_REPORT,
  VOT_REPORT
} from "../type";

const initialState = {
  volunteerReport: {},
  volunteerSupReport: {},
  CBTBCReport: {},
  votReport: [],
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case VOLUNTEER_REPORT:
      return {
        ...state,
        volunteerReport: action.payload.data,
      };
    case VOLUNTEER_SUPERVISIOR_REPORT:
      return {
        ...state,
        volunteerSupReport: action.payload.data,
      };
    case CBTBC_REPORT:
      return {
        ...state,
        CBTBCReport: action.payload.data,
      };
      case VOT_REPORT:
      return {
        ...state,
        votReport: action.payload.data,
      };
    default:
      return state;
  }
};

export default report;
