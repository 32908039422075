import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updateTraining } from "store/actions";
import { types } from "variables/options";
import SelectBox from "components/Selectbox";
import DatePicker from "components/Inputs/DatePicker";
import { trainingOptions } from "variables/options";

const TrainingUpdate = ({
  isOpen,
  toggle,
  status,
  volunteer,
  training,
  updateTraining,
  id
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const { volunteer, date, type, name } = training.training;
    setValue("volunteer_id", volunteer?.id);
    setValue("date", date);
    setValue("date", date);
    setValue("type", type);
    setValue("name", name);
  }, [setValue, training.training]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateTraining(training.training.id,data);
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Training"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          {!id && (
            <SelectBox
              label="Select Volunteer"
              control={control}
              name="volunteer_id"
              options={volunteers}
              defaultValue={training.training?.volunteer?.id}
              rules={{ required: true }}
            />
          )}
          <FormGroup>
            <label className="mb-2">
              Date <sup className="text-danger">*</sup>
            </label>
            <DatePicker
              rules={{ required: true }}
              control={control}
              value={getValues("date")}
              onChange={(v) => {
                setValue("date", v);
              }}
            />
          </FormGroup>
          <SelectBox
            label="Select Training"
            control={control}
            name="name"
            options={trainingOptions}
            rules={{ required: true }}
          />
          <SelectBox
            label="Select Type"
            control={control}
            name="type"
            options={types}
            rules={{ required: true }}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  training: store.training,
});

export default connect(mapStateToProps, { updateTraining })(TrainingUpdate);
