import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import { chestXrayResult } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { updatePatient } from "store/actions";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { doneOrNotDone } from "variables/options";

const ChestXray = ({ patient, updatePatient, auth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const isDone = watch("chest_Xray_done_or_not_done");

  useEffect(() => {
    const {
      chest_Xray_done_or_not_done,
      chest_Xray_examination_date,
      chest_Xray_result,
    } = patient.patient;

    setValue("chest_Xray_done_or_not_done", chest_Xray_done_or_not_done);
    setValue("chest_Xray_examination_date", chest_Xray_examination_date);
    setValue("chest_Xray_result", chest_Xray_result);
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    data.chest_Xray_done_or_not_done = isDone;
    setLoading(true);
    await updatePatient(patient.patient.id, {
      chest_Xray_done_or_not_done: data.chest_Xray_done_or_not_done,
      chest_Xray_examination_date:
        isDone === "1" ? data.chest_Xray_examination_date : null,
      chest_Xray_result: isDone === "1" ? data.chest_Xray_result : null,
    },
    'chest-XRay',
      'Chest X-Ray');

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="Chest X-ray done"
              control={control}
              name="chest_Xray_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          {isDone === "1" && (
            <>
              <Col md={4} className="mb-3">
                <CustomDateInput
                  id="chest_Xray_examination_date"
                  label=" Date of chest X-Ray examination"
                  register={{
                    ...register("chest_Xray_examination_date", {
                      required: "Chest XRay date is required!",
                    }),
                  }}
                  placeholder="Chest XRay date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4} className="mb-3">
                <SelectBox
                  label="Result of chest X-Ray"
                  control={control}
                  name="chest_Xray_result"
                  options={chestXrayResult}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(ChestXray);
