import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updateVolunteerHandout } from "store/actions";

const VolunteerHandoutUpdate = ({
  isOpen,
  toggle,
  status,
  volunteerHandout,
  updateVolunteerHandout,
  editName,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("name", data.name);
    await updateVolunteerHandout(
      volunteerHandout.volunteerHandout.id,
      formData
    );
    setLoading(false);
  };

  useEffect(() => {
    setValue("name", editName);
  }, [editName]);

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Volunteer Handout"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
          <CustomInput
            id="file"
            label="file"
            register={{
              ...register("file", {
                required: "File is required!",
              }),
            }}
            placeholder="Enter file"
            errors={errors}
            type="file"
            accept="application/pdf"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteerHandout: store.volunteerHandout,
});

export default connect(mapStateToProps, { updateVolunteerHandout })(
  VolunteerHandoutUpdate
);
