
import CustomTable from 'components/Table/Table'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import { patientMiddleware } from 'utils/middleware'
import SymptomCreate from './SymptomCreate'
import { useEffect } from 'react'
import { UPDATE } from 'utils/middleware'
import { DELETE } from 'utils/middleware'
import {getSymptoms,
  deleteSymptom,
  getSymptom,} from "store/actions"
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { CREATE } from 'utils/middleware'
import CustomAlert from 'components/Alert/CustomAlert'
import SymptomUpdate from './SymptomUpdate'

const SymptomList = ({
  auth, 
  symptom,
  getSymptoms,
  status,
  deleteSymptom,
  getSymptom}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)


  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteSymptom(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  useEffect(() => {
    getSymptoms();
  }, [getSymptoms]);

  if (status.loading) {
    return <FullScreenLoading />;
  }
  
  return (
    <>
    {alert}
      <Container className="mt-3" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Symptoms</h3>
              </Col>
              <Col className="text-right" xs="6">
               {patientMiddleware(auth.user.role,CREATE) && (
                 <Button
                 size="sm"
                 color="success"
                 onClick={() => setIsOpen(true)}
               >
                 New
               </Button>
               )}
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Eng Name</th>
                <th scope="col">Myanmar Name</th>
                {/* {patientMiddleware(auth.user.role, UPDATE) &&
                  patientMiddleware(auth.user.role, DELETE) && (
                   <th scope="col">Action</th>
                  )} */}
                
              </tr>
            }
            body={symptom?.symptoms?.map((s, index) => (
              <tr key={s.id}>
                <td>{index + 1}</td>
                <td>{s.eng_name}</td>
                <td>{s.myan_name}</td>
                {/* {patientMiddleware(auth.user.role, UPDATE) &&
                  patientMiddleware(auth.user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getSymptom(s.id);
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(s.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )} */}
              </tr>
            ))}
          />

        </Card>
      </Container>

      <SymptomCreate isOpen={isOpen} toggle={setIsOpen} />
      <SymptomUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />

    </>
  )
}

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  symptom: store.symptom
});

export default connect(mapStateToProps, {
  getSymptoms,
  deleteSymptom,
  getSymptom,
})(SymptomList);
