import React, { useEffect, useState } from 'react'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import {
  getPatients,
  getPatient,
  temDeletePatient,
  updatePatient,
  getVolunteers,
  getTownships,
  acceptPatient,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { patientMiddleware } from 'utils/middleware'
import BackBtn from 'utils/backBtn'
import { UPDATE } from 'utils/middleware'
import { DELETE } from 'utils/middleware'
import Select from 'react-select'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import { excelExport } from 'utils/excelExport'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { paginationLimit } from 'variables/limits'
import CustomPagination from 'components/Pagination/Pagination'
import { isVolunteerSupervisor } from 'utils/middleware'
import { VscRefresh } from 'react-icons/vsc'
const RawDataList = ({
  status,
  auth,
  patient,
  getPatients,
  temDeletePatient,
  volunteer,
  getVolunteers,
  getTownships,
  acceptPatient,
  township,
}) => {
  const router = useHistory()
  const { role } = auth.user
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)
  const [dates, setDates] = useState(null)
  const [exportLoading, setExportLoading] = useState(false)
  const [selectedTownship, setSelectedTownship] = useState(null)

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    query.accept_status = 0
    query.status = 1
    if (!('page' in query)) {
      query.page = 1
    }
    getPatients(query)
  }, [router.location.search])

  useEffect(() => {
    getVolunteers()
  }, [role])

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: element.name,
  }))
  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    query.accept_status = 0
    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value
    } else {
      delete query['volunteer_id']
    }
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }
    if (dates) {
      query.start_date = dates.start_date
      query.end_date = dates.end_date
      // setDates(null);
    } else {
      delete query['start_date']
      delete query['end_date']
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    // setSelectedVolunteer(null);
  }
  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])
  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleDelete = async (id) => {
    await temDeletePatient(id)
  }
  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      query.accept_status = 0
      const response = await call(
        'get',
        `patients-export?${new URLSearchParams(query).toString()}`
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Code: data.code,
        Password: data.plain_password,

        Township: data.township?.name,
        Name: data.name,
        Address: data.address,
        Phone: data.phone,
        Age: data.age,
        Sex: data.sex,
        Referral_Volunteer: `${data.referral_volunteer?.name}(${data.referral_volunteer?.code})`,
        Referral_Date: data.referral_date,
        Refer_To: data.refer_to,
        Case_Founded_Activity: data.case_founded_activity,
        TB_Code: data.TB_code,
        Referred_Type: data.referred_type,
        Symptoms: JSON.parse(data.symptoms)
          .map((d) => d.eng_name)
          .join(','),
        Presumptive_TB_Number:
          data.township?.abbreviation + '/' + data.presumptive_TB_number,
      }))

      if (response.status === 'success') {
        excelExport(result, 'Patients Raw Data')
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }
  const handleRefresh = () => {
    router.push(router.location.pathname)
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Raw Data</h3>
              </Col>
              <Col className="text-right" xs={6}>
                <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={() => handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? 'Loading' : 'Export'}
                </Button>
              </Col>
            </Row>
            <Row className="mt-5 d-flex">
              <Col md={3}>
                <Select
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              {!isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col md={5}>
                <DateRangePicker
                  onDateChange={(dates) => {
                    setDates(dates)
                  }}
                />
              </Col>
              <Col md={1}>
                <Button size="sm" color="success" onClick={handleFilter}>
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">Address</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Township</th>
                <th scope="col">Symptoms</th>
                <th scope="col">Refer By ( Volunteer )</th>
                <th scope="col">Referral date</th>
                <th scope="col">Referred To </th>
                <th scope="col">Case Founded At </th>
                <th scope="col">Referred Type</th>
                {patientMiddleware(role, UPDATE) &&
                  patientMiddleware(role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={patient.patients?.map((r, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{r.name}</td>
                <td>{r.age}</td>
                <td>{r.sex}</td>
                <td>{r.address}</td>
                <td>{r.phone}</td>

                <td>{r.township.name}</td>
                <td>
                  {r.symptoms &&
                    JSON.parse(r.symptoms)?.map((s, index) => {
                      return (
                        <div key={index}>
                          <span>{s.eng_name}</span> <br />
                        </div>
                      )
                    })}
                </td>
                <td>{r.referral_volunteer?.name}</td>
                <td>{r.referral_date}</td>
                <td>{r.refer_to}</td>
                <td>{r.case_founded_activity}</td>
                <td>{r.referred_type}</td>
                {patientMiddleware(auth.user.role, UPDATE) &&
                  patientMiddleware(auth.user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await acceptPatient(r.id)
                          const query = queryString.parse(
                            router.location.search
                          )
                          query.accept_status = true
                          getPatients(query)
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(r.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  patient: store.patient,
  volunteer: store.volunteer,
  township: store.township,
})

export default connect(mapStateToProps, {
  getPatients,
  getPatient,
  acceptPatient,
  temDeletePatient,
  updatePatient,
  getVolunteers,
  getTownships,
})(RawDataList)
