import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { SputumCultureResult } from "variables/options";
import { updatePatient } from "store/actions";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { doneOrNotDone } from "variables/options";

const SputumCulture = ({ patient, auth, updatePatient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const isDone = watch("sputum_culture_done_or_not_done");

  useEffect(() => {
    if (patient.patient) {
      const {
        sputum_culture_done_or_not_done,
        sputum_culture_examination_date,
        sputum_culture_result,
      } = patient.patient;

      setValue(
        "sputum_culture_done_or_not_done",
        sputum_culture_done_or_not_done
      );
      setValue(
        "sputum_culture_examination_date",
        sputum_culture_examination_date
      );
      setValue("sputum_culture_result", sputum_culture_result);
    }
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);

    await updatePatient(patient.patient.id, {
      sputum_culture_done_or_not_done:
        data.sputum_culture_done_or_not_done ,
      sputum_culture_examination_date:
        isDone === "1" ? data.sputum_culture_examination_date : null,
      sputum_culture_result: isDone === "1" ? data.sputum_culture_result : null,
    },
    'sputum-culture',
    'Sputum Culture');

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="Sputum culture done"
              control={control}
              name="sputum_culture_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
          
        </Row>
        <Row>
        {isDone === "1" && (
            <>
              <Col md={4}>
                <CustomDateInput
                  id="sputum_culture_examination_date"
                  label=" Date of Sputum Culture"
                  register={{
                    ...register("sputum_culture_examination_date", {
                      required: "Sputum Culture date is required!",
                    }),
                  }}
                  placeholder="Sputum Culture date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="Result of Sputum Culture"
                  control={control}
                  name="sputum_culture_result"
                  options={SputumCultureResult}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(
  SputumCulture
);
