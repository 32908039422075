import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
} from 'reactstrap'
import BackBtn from 'utils/backBtn'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import styles from './VOTReport.module.css'
import { connect } from 'react-redux'
import { getTownships, getVotReport } from 'store/actions'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import { isVolunteerSupervisor } from 'utils/middleware'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { currentDate } from 'utils/currentDate'

const VOTReport = ({
  status,
  auth,
  township,
  getTownships,
  report,
  getVotReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (startDate !== '') {
      query.start_date = startDate
    } else {
      delete query['start_date']
    }

    if (endDate !== '') {
      query.end_date = endDate
    } else {
      delete query['end_date']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getVotReport(query)
  }, [getVotReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  let fileName = 'VOT-Report'
  if (startDate) {
    fileName += `-${startDate}`
  }
  if (endDate) {
    fileName += `-${endDate}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">VOT Report</h3>
            </Col>
            <Col className="text-right" xs="6">
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm mb-3"
                table="table-to-xls"
                filename={fileName}
                sheet="VOT-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => {
                  if (!event.target.value) {
                    setEndDate('')
                  }
                  setStartDate(event.target.value)
                }}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                value={endDate}
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ''}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col md={3}>
              <Button
                className="mt-4"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            className={classNames('app-table align-items-center table-fixed')}
            responsive
            id="table-to-xls"
          >
            <thead className="border">
              <tr>
                <th className="fixed-cell left border bg-warning" rowSpan={3}>
                  Organization
                </th>
                <th className="fixed-cell left border bg-warning " rowSpan={3}>
                  State/Region
                </th>
                <th className="fixed-cell left border bg-warning" rowSpan={3}>
                  Township
                </th>
                <th className="fixed-cell left border bg-warning" rowSpan={3}>
                  Fiscal Year (FY)
                </th>
                <th className="fixed-cell left border bg-warning" rowSpan={3}>
                  Quarter
                </th>
                <th
                  className={classNames(styles.bg, 'border bg-warning')}
                  rowSpan={2}
                  colSpan={4}
                >
                  Screened for eligibility for VOT
                </th>
                <th rowSpan={2} colSpan={4} className="bg-warning">
                  Eligible for VOT
                </th>
                <th
                  className={classNames(styles.bg, 'border bg-warning')}
                  rowSpan={2}
                  colSpan={4}
                >
                  Eligible & enrolled VOT
                </th>
                <th rowSpan={2} colSpan={4} className="border bg-warning">
                  Declined VOT
                </th>
                <th
                  className={classNames(styles.bg, 'border bg-warning')}
                  rowSpan={3}
                >
                  Currently on VOT
                </th>
                <th colSpan={4} className="border bg-warning">
                  Missed targeted VOT doses
                </th>
                <th
                  className={classNames(styles.bg, 'border bg-warning')}
                  colSpan={8}
                >
                  Stopped VOT
                </th>
                <th rowSpan={2} colSpan={4} className="border bg-warning">
                  Eligible and enrolled VOT patient with cured/completed Tx
                </th>
              </tr>
              <tr>
                <th rowSpan={2} className="bg-warning">
                  No. of cases with missed targeted VOT doses (Head count)
                </th>
                <th colSpan={3} className="bg-warning">
                  Missed doses (Frequency)
                </th>
                <th
                  className={classNames(styles.bg, 'border bg-warning')}
                  rowSpan={2}
                >
                  No. of Stopped VOT (Head count)
                </th>
                <th className={'bg-warning'} rowSpan={2}>
                  Switch to Family DOT/In-Person DOT
                </th>
                <th className={'bg-warning'} rowSpan={2}>
                  Deny VOT
                </th>
                <th className={'bg-warning'} rowSpan={2}>
                  Damage and Losing Phone
                </th>
                <th className={'bg-warning'} rowSpan={2}>
                  Technical Issues
                </th>
                <th className={'bg-warning'} rowSpan={2}>
                  Internet Connection Problem
                </th>
                <th className={'bg-warning'} rowSpan={2}>
                  Stop VOT due to treatment outcome
                </th>
                <th
                  className={classNames(styles.bg, 'border bg-warning')}
                  rowSpan={2}
                >
                  Others (Specify)-
                </th>
              </tr>
              <tr>
                <th className={'bg-warning'}>{'<15 Female'}</th>
                <th className={'bg-warning'}>{'15+ Female'}</th>
                <th className="bg-warning">{'<15 Male'}</th>
                <th className="bg-warning">{'15+ Male'}</th>
                <th className="border-left bg-warning">{'<15 Female'}</th>
                <th className="bg-warning">{'15+ Female'}</th>
                <th className="bg-warning">{'<15 Male'}</th>
                <th className="bg-warning">{'15+ Male'}</th>
                <th className="border-left bg-warning">{'<15 Female'}</th>
                <th className={'bg-warning'}>{'15+ Female'}</th>
                <th className="bg-warning">{'<15 Male'}</th>
                <th className="bg-warning">{'15+ Male'}</th>
                <th className="border-left bg-warning">
                  No. of declined (Head count)
                </th>
                <th className="bg-warning">
                  Reasons for declined - Family (Head count)
                </th>
                <th className="bg-warning">
                  Reasons for declined - Privacy (Head count)
                </th>
                <th className="bg-warning">
                  Reasons for declined - Others (Head count)
                </th>
                {/* <th>Reasons for declined, Multiple selection (Frequency)</th> */}
                <th className="bg-warning">{'<10%'}</th>
                <th className="bg-warning">{'10-50%'}</th>
                <th className="bg-warning">{'>50%'}</th>
                <th className="bg-warning">{'<15 Female'}</th>
                <th className="bg-warning">{'15+ Female'}</th>
                <th className="bg-warning">{'<15 Male'}</th>
                <th className="bg-warning">{'15+ Male'}</th>
              </tr>
            </thead>
            <tbody className="list">
              {report.votReport?.map((row, index) => (
                <tr key={index}>
                  <td className="fixed-cell left">{row.organization}</td>
                  <td className="fixed-cell left">{row.region}</td>
                  <td className="fixed-cell left">{row.township}</td>
                  <td className="fixed-cell left">{row.fy || '-'}</td>
                  <td className="fixed-cell left">{row.quarter || '-'}</td>

                  <td>{row.screened_patient?.less_than_15_female}</td>
                  <td>{row.screened_patient?.greater_than_15_female}</td>
                  <td>{row.screened_patient?.less_than_15_male}</td>
                  <td>{row.screened_patient?.greater_than_15_male}</td>

                  <td>{row.eligible_patient?.less_than_15_female}</td>
                  <td>{row.eligible_patient?.greater_than_15_female}</td>
                  <td>{row.eligible_patient?.less_than_15_male}</td>
                  <td>{row.eligible_patient?.greater_than_15_male}</td>

                  <td>{row.dot_patient?.less_than_15_female}</td>
                  <td>{row.dot_patient?.greater_than_15_female}</td>
                  <td>{row.dot_patient?.less_than_15_male}</td>
                  <td>{row.dot_patient?.greater_than_15_male}</td>

                  <td>{row.decline_patient}</td>
                  <td>{row.decline_patient_family}</td>
                  <td>{row.decline_patient_privacy}</td>
                  <td>{row.decline_patient_others}</td>

                  <td>{row.current_dot}</td>

                  <td>{row.missed_dot}</td>
                  <td>{row.missed_dot_less_than_10}</td>
                  <td>{row.missed_dot_between_10_50}</td>
                  <td>{row.missed_dot_over_50}</td>

                  <td>{row.stopped_dot?.total}</td>
                  <td>{row.stopped_dot?.person_family}</td>
                  <td>{row.stopped_dot?.deny}</td>
                  <td>{row.stopped_dot?.damage_phlost}</td>
                  <td>{row.stopped_dot?.technical_issue}</td>
                  <td>{row.stopped_dot?.internet_connection}</td>
                  <td>{row.stopped_dot?.stop_vot_due_to_tx_outcome}</td>
                  <td>{row.stopped_dot?.other_outcome}</td>

                  <td>{row.outcome_patient?.less_than_15_female}</td>
                  <td>{row.outcome_patient?.greater_than_15_female}</td>
                  <td>{row.outcome_patient?.less_than_15_male}</td>
                  <td>{row.outcome_patient?.greater_than_15_male}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  report: store.report,
})

export default connect(mapStateToProps, {
  getTownships,
  getVotReport,
})(VOTReport)
