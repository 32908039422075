import { combineReducers } from 'redux'
import status from './status'
import auth from './auth'
import account from './account'
import region from './region'
import district from './district'
import township from './township'
import volunteer from './volunteer'
import training from './training'
import patient from './patient'
import patientMedicine from './patientMedicine'
import fUSputumExaminition from './fUSputumExaminition'
import he from './he'
import infectionControlCheck from './infectionControlCheck'
import contactTracy from './contactTracy'
import volunteerHandout from './volunteerHandout'
import patientHandout from './patientHandout'
import takeMedicine from './takeMedicine'
import DOT from './DOT'
import changeVolunteer from './changeVolunteer'
import symptom from './symptom'
import tb_ci from './tb_ci'
import missDose from './missDose'
import supervision_visit from './superVisionVisit'
import medicine from "./medicine";
import patientSymptom from "./patientSymptom"
import report from "./report"
import visualization from "./visualization";

const reducers = combineReducers({
  status,
  auth,
  account,
  region,
  district,
  township,
  volunteer,
  training,
  patient,
  patientMedicine,
  fUSputumExaminition,
  he,
  infectionControlCheck,
  contactTracy,
  volunteerHandout,
  patientHandout,
  takeMedicine,
  DOT,
  changeVolunteer,
  symptom,
  tb_ci,
  missDose,
  supervision_visit,
  medicine,
  patientSymptom,
  report,
  visualization
});

export default reducers
