import {
  CREATE_VOLUNTEER_HANDOUT,
  SET_VOLUNTEER_HANDOUT,
  SHOW_VOLUNTEER_HANDOUTS,
  UPDATE_VOLUNTEER_HANDOUT,
  DELETE_VOLUNTEER_HANDOUT,
} from "../type";

const initialState = {
  volunteerHandouts: [],
  volunteerHandout: {},
};

const volunteerHandout = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_VOLUNTEER_HANDOUTS:
      return {
        ...state,
        volunteerHandouts: action.payload,
      };
    case CREATE_VOLUNTEER_HANDOUT:
      return {
        ...state,
        volunteerHandouts: [action.payload, ...state.volunteerHandouts],
      };
    case SET_VOLUNTEER_HANDOUT:
      return {
        ...state,
        volunteerHandout: action.payload,
      };
    case UPDATE_VOLUNTEER_HANDOUT:
      let index = state.volunteerHandouts.findIndex(
        (volunteerHandout) => volunteerHandout.id === action.payload.id
      );
      state.volunteerHandouts[index] = action.payload;
      if (state.volunteerHandout.id === action.payload.id) {
        state.volunteerHandout = action.payload;
      }
      return {
        ...state,
      };
    case DELETE_VOLUNTEER_HANDOUT:
      return {
        ...state,
        volunteerHandouts: state.volunteerHandouts.filter(
          (handout) => handout.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default volunteerHandout;
