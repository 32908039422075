import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Table,
} from "reactstrap";
import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import styles from "./NoAppDotList.module.css";
import { currentDate } from "utils/currentDate";
import BackBtn from "utils/backBtn";
import NoAppDotCreate from "./NoAppDotCreate";
import {
  getNoAppDOTs,
  getPatient,
  changeStatusNoApp,
  deleteDOT,
} from "store/actions";
import CustomPagination from "components/Pagination/Pagination";
import CustomAlert from "components/Alert/CustomAlert";
import clsx from "clsx";
import { patientMiddleware } from "utils/middleware";
import { call } from "services/api";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import dayjs from "dayjs";
import { CREATE } from "utils/middleware";
import { useCallback } from "react";
import moment from "moment";
import { clearPatientData } from "store/actions";

const NoAppDotList = ({
  status,
  patient,
  DOT,
  getNoAppDOTs,
  getPatient,
  changeStatusNoApp,
  deleteDOT,
  clearPatientData,
  auth,
}) => {
  const { id } = useParams();
  const { role } = auth.user;
  const router = useHistory();

  const [alert, setAlert] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [lastDotDate, setLastDotDate] = useState(false);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getNoAppDOTs(id, query);
  }, [router.location.search]);

  useEffect(() => {
    getLastDotDate();
  }, []);

  const getLastDotDate = async () => {
    const response = await call("get", `Noapp-last-DOTs-date/${id}`);
    // const date = new Date(response.lastDotDate);
    // date.setDate(date.getDate() + 1);
    // setLastDotDate(dayjs(date).format("YYYY-MM-DD"));
    const date = moment(response.lastDotDate);
    const newDate = date.add(1, "days");
    setLastDotDate(dayjs(newDate).format("YYYY-MM-DD"));
  };

  // useEffect(() => {
  //   getPatient(id);
  // }, []);
  useEffect(()=> {
    if(Object.keys(patient?.patient).length === 0) {
      getPatient(id);
    } 
    return () => {
      clearPatientData();
    };
  },[])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setStartDate("");
    setEndDate("");
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `export-NoApp-DOTs/${id}?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Date: data.date,
        Work_Status: data.work_status ? "အောင်မြင်ပါသည်" : "မအောင်မြင်ပါ",
      }));
      console.log(result);
      if (response.status === "success") {
        excelExport(result, "DOT-NoAPP");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleDelete = (id, date) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await deleteDOT(id);
          setAlert(null);
          setLastDotDate(date);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };
  let success = 0;
  let fail = 0;
  let notGoing = 0;
  DOT.DOTs.forEach((DOT) => {
    if (DOT.work_status === null) {
      notGoing++;
    } else {
      if (DOT.work_status) {
        success++;
      } else {
        fail++;
      }
    }
  });

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">
                  ({patient.patient?.name}) DOT Visit Date (No App)
                </h3>
                <Row className="d-flex align-items-center">
                  <Col md={4}>
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      id="start_date"
                      className="form-control"
                      type="date"
                      min=""
                      max={currentDate()}
                      onChange={(event) => setStartDate(event.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <label htmlFor="end_date">End Date</label>
                    <input
                      id="end_date"
                      className="form-control"
                      type="date"
                      min={startDate}
                      max={currentDate()}
                      onChange={(event) => setEndDate(event.target.value)}
                      disabled={startDate === ""}
                    />
                  </Col>
                  <Col md={4}>
                    <Button
                      size="sm"
                      color="success"
                      className="mt-4"
                      onClick={handleFilter}
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col className="text-right" xs="6">
                <div>
                  {patientMiddleware(role, CREATE) &&
                    patient.patient?.type === "No App" &&
                    (patient.patient?.DOT_end_date == null ||
                      patient.patient?.treatment_outcome == null) && (
                      <Button
                        size="sm"
                        color="success"
                        className="mb-3"
                        onClick={() => setIsCreate(true)}
                      >
                        New
                      </Button>
                    )}
                  <Button
                    size="sm"
                    color="info"
                    className="mb-3"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    className="mb-3"
                    onClick={() => {
                      getNoAppDOTs(id);
                    }}
                  >
                    All
                  </Button>
                </div>
                <div className="d-flex flex-column mt-3">
                  <span>{`အောင်မြင်သည့်အရေအတွက် - ${success}`} </span>
                  <span>{`မအောင်မြင်သည့်အရေအတွက် - ${fail}`} </span>
                  <span>{`မသွားရသေးသည့် ရက်စုစုပေါင်း - ${notGoing}`} </span>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="mt-3">
            <Table>
              <tbody className="d-flex flex-wrap justify-content-start px-5 border-0">
                {DOT.DOTs.map((DOT, index) => {
                  let style = null;

                  if (DOT.work_status === null) {
                    style = null;
                  } else {
                    if (DOT.work_status) {
                      style = "bg-primary text-white";
                    } else {
                      style = "bg-gray";
                    }
                  }
                  return (
                    <td className={clsx(styles.box, style, "py-4")} key={index}>
                      <span
                        onClick={async () =>
                          !patient.patient.DOT_end_date
                            ? await changeStatusNoApp(DOT.id)
                            : {}
                        }
                      >
                        {DOT.date}
                      </span>
                      <div className={styles.cancel}>
                        <i
                          className="ni ni-fat-remove ml-1"
                          onClick={() => {
                            handleDelete(DOT.id, DOT.date);
                          }}
                        />
                      </div>
                    </td>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>

          {DOT.total > 28 && <CustomPagination pageCount={DOT.total / 28} />}
        </Card>
      </Container>
      <NoAppDotCreate
        isOpen={isCreate}
        toggle={setIsCreate}
        lastDotDate={lastDotDate}
        setLastDotDate={setLastDotDate}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
  auth: store.auth,
});

export default connect(mapStateToProps, {
  getNoAppDOTs,
  getPatient,
  changeStatusNoApp,
  deleteDOT,
  clearPatientData
})(NoAppDotList);
