import React, { useState, useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BackBtn from "utils/backBtn";
import { connect } from "react-redux";
import {
  getVolunteers,
  getChangeVolunteers,
  getPatient,
  deleteChangeVolunteer,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { useParams } from "react-router";
import CustomAlert from "components/Alert/CustomAlert";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import dayjs from "dayjs";
import { DELETE } from "utils/middleware";

const ChangeVolunteerList = ({
  status,
  patient,
  changeVolunteer,
  getChangeVolunteers,
  getPatient,
  deleteChangeVolunteer,
  auth,
}) => {
  const { id } = useParams();
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    getChangeVolunteers(id);
  }, [getChangeVolunteers, id]);

  useEffect(() => {
    getPatient(id);
  }, [getPatient, id]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteChangeVolunteer(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Change Volunteer List</h3>
                <span className="fw-bold">{`Patient Name - ${patient.patient.name}`}</span>
                <br></br>
                <span>{`TB Code - ${patient.patient?.TB_DRTB_code}`}</span>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Volunteer Name</th>
                <th scope="col">Change Date</th>
                <th scope="col">Type</th>
                {patientMiddleware(role, UPDATE) && <th scope="col">Action</th>}
              </tr>
            }
            body={changeVolunteer.changeVolunteers?.map(
              (changeVolunteer, index) => (
                <tr key={changeVolunteer.id}>
                  <td>{index + 1}</td>
                  <td>{changeVolunteer?.volunteer?.name}</td>
                  <td>
                    {dayjs(changeVolunteer.created_at).format("YYYY-MM-DD")}
                  </td>
                  <td>{changeVolunteer?.type}</td>

                  {patientMiddleware(role, DELETE) && (
                    <td>
                      {changeVolunteer.DOT_count === 0 && (
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleDelete(changeVolunteer.id)}
                        >
                          Delete
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              )
            )}
          />
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  changeVolunteer: store.changeVolunteer,
});

export default connect(mapStateToProps, {
  getVolunteers,
  getChangeVolunteers,
  getPatient,
  deleteChangeVolunteer,
})(ChangeVolunteerList);
