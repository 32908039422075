import React, { useEffect, useState } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import FUSputumExaminitionCreate from "./FUSputumExaminitionCreate";
import FUSputumExaminitionDetail from "./FUSuptumExaminitionDetail";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getFUSputumExaminitions,
  getFUSputumExaminition,
  getPatient,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { patientMiddleware, CREATE } from "utils/middleware";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import BackBtn from "utils/backBtn";
import { clearPatientData } from "store/actions";

const FUSputumExaminitionList = ({
  status,
  auth,
  fUSputumExaminition,
  patient,
  getFUSputumExaminitions,
  getFUSputumExaminition,
  getPatient,
  clearPatientData
}) => {
  const { role } = auth.user;
  const { id } = useParams();

  const [exportLoading, setExportLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  // useEffect(() => {
  //   getPatient(id);
  // }, [getPatient,id]);
  useEffect(()=> {
    if(Object.keys(patient?.patient).length === 0) {
      console.log("get patient");
      getPatient(id);
    } 
    return () => {
      clearPatientData();
    };
  },[])
  
  useEffect(() => {
    getFUSputumExaminitions(id);
  }, [getFUSputumExaminitions, id]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const result = fUSputumExaminition.fUSputumExaminitions.map(
        (data, index) => ({
          ID: index + 1,
          Patient_Name: patient.patient?.name,
          Township: patient.patient?.township?.name,
          Month: data.month,
          To_send_date: data.to_send_date,
          Send_date: data.send_date,
          Smear: data.smear,
          Smear_Date: data.smear_date,
          Smear_Result: data.smear_result,
          remark: data.remark,
        })
      );

      excelExport(result, "FU-Sputum-Examinition");
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">F/U SputumExaminition List</h3>
                <span>Patient Name - {patient.patient?.name}</span>
                <br />
                <span>TB Code - {patient.patient?.TB_DRTB_code}</span>
              </Col>
              {patientMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                    className="mb-3"
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                  <Button
                    size="sm"
                    color="success"
                    className="mb-3"
                    onClick={() => setIsCreate(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
               
                <th className="fixed-cell left" scope="col">
                  Month
                </th>
                <th scope="col">To Send Date</th>
                <th scope="col">Send Date</th>
                <th scope="col">Smear</th>
                <th scope="col">Date</th>
                <th scope="col">Result</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={fUSputumExaminition.fUSputumExaminitions.map(
              (fUSputumExaminition, index) => (
                <tr key={fUSputumExaminition.id}>
                  <td className="fixed-cell left">{index + 1}</td>
                  <td className="fixed-cell left">
                    {fUSputumExaminition.month}
                  </td>
                  <td>{fUSputumExaminition.to_send_date}</td>
                  <td>{fUSputumExaminition.send_date}</td>
                  <td>{fUSputumExaminition.smear}</td>
                  <td>{fUSputumExaminition.smear_date}</td>
                  <td>{fUSputumExaminition.smear_result}</td>
                  <td>
                    <Button
                      size="sm"
                      color="success"
                      onClick={async () => {
                        await getFUSputumExaminition(fUSputumExaminition.id);
                        setIsDetail(true);
                      }}
                    >
                      Detail
                    </Button>
                  </td>
                </tr>
              )
            )}
          />
        </Card>
      </Container>

      <FUSputumExaminitionDetail isOpen={isDetail} toggle={setIsDetail} />
      <FUSputumExaminitionCreate isOpen={isCreate} toggle={setIsCreate} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  fUSputumExaminition: store.fUSputumExaminition,
});

export default connect(mapStateToProps, {
  getFUSputumExaminitions,
  getFUSputumExaminition,
  getPatient,
  clearPatientData
})(FUSputumExaminitionList);
