import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { drugSensitivityResult } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { updatePatient, getPatient } from "store/actions";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { doneOrNotDone } from "variables/options";
const DrugSensitivity = ({ patient, updatePatient, auth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const isDone = watch("drug_susceptibility_done_or_not_done");
  const result = watch("drug_susceptibility_result");

  useEffect(() => {
    if (patient.patient) {
      const data = patient.patient;

      setValue(
        "drug_susceptibility_done_or_not_done",
        data.drug_susceptibility_done_or_not_done
      );
      setValue("drug_susceptibility_date", data.drug_susceptibility_date);
      setValue("drug_susceptibility_result", data.drug_susceptibility_result);
      setValue("drug_susceptibility_remark", data.drug_susceptibility_remark);
    }
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);

    await updatePatient(patient.patient.id, {
      drug_susceptibility_done_or_not_done:
        data.drug_susceptibility_done_or_not_done,
      drug_susceptibility_date:
        isDone === "1" ? data.drug_susceptibility_date : null,
      drug_susceptibility_result:
        isDone === "1" ? data.drug_susceptibility_result : null,
      drug_susceptibility_remark:
        result === "Other" ? data.drug_susceptibility_remark : null,
    },
    'drug-susceptibility',
    'Drub Sensitivity');

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="Drug Sensitivity done"
              control={control}
              name="drug_susceptibility_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          {isDone === "1" && (
            <>
              <Col md={4} className="mb-3">
                <CustomDateInput
                  id="drug_sensitivity_date"
                  label=" Date of Drug Sensitivity"
                  register={{
                    ...register("drug_susceptibility_date", {
                      required: "Drug susceptibility date is required!",
                    }),
                  }}
                  placeholder="Drug Sensitivity date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4} className="mb-3">
                <SelectBox
                  label="Result of Drug Sensitivity"
                  control={control}
                  name="drug_susceptibility_result"
                  options={drugSensitivityResult}
                  rules={{ required: true }}
                />
              </Col>
              {result === "Other" && (
                <Col md={4} className="mb-3">
                  <CustomTextArea
                    id="drug_susceptibility_remark"
                    label="Drug Sensitivity Remark"
                    register={{
                      ...register("drug_susceptibility_remark", {
                        required: "Drug Sensitivity Remark is required",
                      }),
                    }}
                    placeholder="Drug Sensitivity Remark"
                    errors={errors}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient, getPatient })(
  DrugSensitivity
);
