import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getPatient, updatePatient } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { yesOrNo } from "variables/options";
import { currentDate } from "utils/currentDate";
import BackBtn from "utils/backBtn";
import SelectBox from "components/Selectbox";
import { patientMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";

const MoveToDOT = ({ status, patient, getPatient, updatePatient, auth }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const history = useHistory();
  const { id } = useParams();
  const { role } = auth.user;
  const [loading, setLoading] = useState(false);
  const [isDOT, setIsDOT] = useState(null);

  useEffect(() => {
    getPatient(id);
  }, []);

  useEffect(() => {
    const { DOT_start_date } = patient.patient;
    setIsDOT(DOT_start_date ? "Yes" : null);
    setValue("DOT_start_date", DOT_start_date);
  }, [patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(patient.patient.id, {
      DOT_start_date: isDOT === "Yes" ? data.DOT_start_date : null,
    },
    'move-to-DOT',
    'Move To DOT');
    setLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <h3 className="mb-3"> Move To DOT</h3>
          <span className="fw-bold">{`Patient Name - ${patient.patient.name}`}</span>
          <br></br>
          <span>{`TB Code - ${patient.patient?.TB_DRTB_code}`}</span>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={3}>
                <SelectBox
                  label="DOT Patient"
                  control={control}
                  name="is_DOT"
                  defaultValue={patient.patient?.DOT_start_date ? "Yes" : null}
                  options={yesOrNo}
                  rules={{ required: true }}
                  onValueChange={(v) => {
                    setIsDOT(v);
                  }}
                />
              </Col>
              {isDOT === "Yes" && (
                <Col md={3}>
                  <CustomDateInput
                    id="DOT_start_date"
                    label="DOT Start Date"
                    register={{
                      ...register("DOT_start_date", {
                        required: "Select DOT Start Date",
                      }),
                    }}
                    placeholder="Select DOT Start Date"
                    errors={errors}
                    isRequired={isDOT?.value === "Yes"}
                    min={patient.patient?.treatment_start_date}
                   // max={currentDate()}
                  />
                </Col>
              )}
              {isDOT === "Yes" && patient.patient?.DOT_start_date && (
                <Col md={3}>
                  <Button
                    color="success"
                    size="sm"
                    className="mt-5"
                    onClick={() =>
                      history.push(
                        `/admin/change-volunteer-create/${patient.patient.id}`
                      )
                    }
                  >
                    DOT with/without volunteer?
                  </Button>
                </Col>
              )}
            </Row>
            {patientMiddleware(role, CREATE) && (
              <div className="modal-footer">
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Save"}
                </Button>
              </div>
            )}
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, {
  getPatient,
  updatePatient,
})(MoveToDOT);
