import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { yesOrNo } from "variables/options";
import { outcome } from "variables/options";
import { updatePatient } from "store/actions";
import { patientMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";

const DotConclusion = ({ patient, updatePatient, auth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();
  const { role } = auth.user;

  const [loading, setLoading] = useState(false);
  const [outcomeRequired, setOutComeRequired] = useState(false);
  const dot_supervision = watch("dot_supervision");
  const treatment_outcome = watch("treatment_outcome");

  useEffect(() => {
    const { DOT_end_date, treatment_outcome, treatment_outcome_date,dot_supervision } =
      patient.patient;
    setValue("DOT_end_date", DOT_end_date);
    setValue("dot_supervision", dot_supervision);
    setValue("treatment_outcome", treatment_outcome);
    setValue("treatment_outcome_date", treatment_outcome_date);
    if(treatment_outcome_date) {
      setOutComeRequired(true);
    }
  }, [patient.patient]);

  const onSubmit = async (values) => {
    // if (values.dot_supervision || values.treatment_outcome_date) {
      setLoading(true);
      await updatePatient(patient.patient.id, {
        dot_supervision: values.dot_supervision,
        DOT_end_date: values.dot_supervision ? values.DOT_end_date : null,
        treatment_outcome: values.treatment_outcome_date
          ? values.treatment_outcome
          : null,
        treatment_outcome_date: values.treatment_outcome
          ? values.treatment_outcome_date
          : null,
      },
      'dot-conclusion',
      'DOT Conclusion');
      setLoading(false);
    // }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={4} className="mb-3">
          <SelectBox
            label="Dot Supervision (yes/no)"
            control={control}
            name="dot_supervision"
            options={yesOrNo}
          />
        </Col>
        <Col md={4} className="mb-3">
          <CustomDateInput
            id="DOT_end_date"
            label="Dot End Date"
            register={{
              ...register("DOT_end_date", {
                required: dot_supervision ? "Dot End date is required!" : false,
              }),
            }}
            errors={errors}
            min="1950-01-01"
            max="2100-12-31"
            disabled={!dot_supervision}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-3">
          <CustomDateInput
            id="treatment_outcome_date"
            label="Outcome Date"
            register={{
              ...register("treatment_outcome_date", {
                required: treatment_outcome
                  ? "Outcome date is required!"
                  : false,
              }),
            }}
            errors={errors}
            min="1950-01-01"
            max="2100-12-31"
            onChange={(e)=> {
              setOutComeRequired(e.target.value);
            }}
          />
        </Col>
        <Col md={4} className="mb-3">
          <SelectBox
            label="Outcome Result"
            control={control}
            name="treatment_outcome"
            options={outcome}
            rules={{ required: outcomeRequired }}
            disabled={!outcomeRequired}
          />
        </Col>
      </Row>
      {patientMiddleware(role, CREATE) && (
        <div className=" modal-footer  d-flex align-items-end col-12">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(DotConclusion);
