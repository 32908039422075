const CBTBC_Project_Officer = "CBTBC Project Officer";
const Program_Manager = "Program Manager";
const MEAL_Officer = "MEAL Officer";
const VOLUNTEER_SUPERVISOR = "Volunteer Supervisor";
const CLINIC_MO = "Clinic MO";
const CENTRAL_DATA_ASSISTANT = "Central Data Assistant";
const CLINIC_ASSISTANT = "Clinic Assistant";

export const CREATE = "create";
export const READ = "read";
export const UPDATE = "update";
export const DELETE = "delete";
export const EXPORT = "export";

export const VR = "VR";
export const FR = "FR";
export const NTP = "NTP";

export const isVolunteerSupervisor = (role) =>
  role === VOLUNTEER_SUPERVISOR || role === CLINIC_MO || role === CLINIC_ASSISTANT;
export const isMealOfficer = (role) => role === MEAL_Officer || role === CENTRAL_DATA_ASSISTANT;

// export const dashboardMiddleware = (role, type) => {
//   if (
//     (role === Program_Manager ||
//       role === CBTBC_Project_Officer ||
//       role === MEAL_Officer || 
//       role === 
//       ) &&
//     (type === READ || type === EXPORT)
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// };
export const accountMiddleware = (role, type) => {
  if (
    role === Program_Manager ||
    role === CBTBC_Project_Officer ||
    role === MEAL_Officer || 
    role === CENTRAL_DATA_ASSISTANT
  ) {
    if (type !== undefined) {
      if ((role !== MEAL_Officer && role !== CENTRAL_DATA_ASSISTANT) && type !== READ) return false;
      else return true;
    }
    return true;
  } else {
    return false;
  }
};

export const basicDataMiddleware = (role, type) => {
  if (
    (role === MEAL_Officer || role === CENTRAL_DATA_ASSISTANT)&&
    (type === CREATE || type === UPDATE || type === DELETE)
  ) {
    return true;
  } else {
    return false;
  }
};

export const volunteerMiddleware = (role, type) => {
  if (
    (role === MEAL_Officer ||
      role === CENTRAL_DATA_ASSISTANT ||
      role === CLINIC_MO ||
      role === CLINIC_ASSISTANT ||
      role === VOLUNTEER_SUPERVISOR) &&
    (type === CREATE || type === UPDATE || type === DELETE)
  ) {
    return true;
  } else {
    return false;
  }
};

export const patientMiddleware = (role, type) => {
  if (
    (role === MEAL_Officer ||
      role === CENTRAL_DATA_ASSISTANT ||
      role === CLINIC_MO ||
      role === CLINIC_ASSISTANT ||
      role === VOLUNTEER_SUPERVISOR) &&
    (type === CREATE || type === UPDATE || type === DELETE || type === EXPORT)
  ) {
    return true;
  } else if (
    (role === Program_Manager || role === CBTBC_Project_Officer) &&
    type === EXPORT
  ) {
    return true;
  } else {
    return false;
  }
};

export const activitiesMiddleware = (role, type) => {
  if (
    (role === MEAL_Officer ||
      role === CENTRAL_DATA_ASSISTANT ||
      role === CLINIC_MO ||
      role === CLINIC_ASSISTANT ||
      role === VOLUNTEER_SUPERVISOR) &&
    (type === CREATE || type === UPDATE || type === DELETE)
  ) {
    return true;
  } else {
    return false;
  }
};

export const handoutMiddleware = (role, type) => {
  if (
    role === Program_Manager ||
    role === CBTBC_Project_Officer ||
    role === MEAL_Officer ||
    role === VOLUNTEER_SUPERVISOR ||
    role === CLINIC_MO || 
    role === CLINIC_ASSISTANT ||
    role === CENTRAL_DATA_ASSISTANT
  ) {
    if (type !== undefined) {
      if (
        role !== MEAL_Officer &&
        (type === CREATE || type === UPDATE || type === DELETE)
      )
        return false;
      else return true;
    }
    return true;
  } else {
    return false;
  }
};

// export const reportMiddleware = (role, report) => {
//   if (
//     role === DEVELOPER ||
//     role === PROJECT_MANAGER ||
//     role === SENIOR_PROJECT_OFFICER ||
//     role === PROJECT_OFFICER ||
//     role === MIS_OFFICER
//   ) {
//     return true
//   } else {
//     if (role === VOLUNTEER_SUPERVISOR && (report === VR || report === FR)) {
//       return true
//     } else {
//       return false
//     }
//   }
// }
