import axios from "axios";
import { removeToken } from "utils/cache";
import { setToken } from "utils/cache";

import { NotificationManager } from "react-notifications";
import axiosRetry from "axios-retry";

//export const host = "http://localhost:8000/api/v1";
export const host = 'https://api.mata-vot.org/api/v1'
//export const host = 'https://mata-dev-api.unionais.org/api/v1'

export const setAccessToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setToken(token);
  } else {
    delete axios.defaults.headers.common["Authorization"];
    removeToken();
  }
};

const getAPIClient = () => {
  const api = axios.create({
    baseURL: host, // Replace with your API base URL
  });

  // Configure the retry options for the Axios instance
  axiosRetry(api, {
    retries: 0,
    retryDelay: (retryCount, error) => {
      const { status } = error.response;
      if (status === 429) {
        const secondsToWait = Number(error.response.data.errors.retryAfter);
        NotificationManager.error(
          `Please wait around ${secondsToWait} seconds`
        );
        return secondsToWait * 1000;
      }
      console.log(`retry attempt: ${retryCount}`);
      return 2000; // time interval between retries
    },
    retryCondition: (error) => {
      if(error.response.status === 401) {
        return false;
      }
      return true;
    },
  });

  return api;
}

export const call = async (method, path, data) => {
  const apiClient = getAPIClient()
  const response = await apiClient[method](`${host}/${path}`, data);
  return response.data;
};
