import { SET_HE, SHOW_HES, CREATE_HE, DELETE_HE } from "../type";

const initialState = {
  hes: [],
  he: {},
  total: 0,
}

const he = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HES:
      return {
        ...state,
        hes: action.payload.data,
        total: action.payload.total,
      };
    case SET_HE:
      return {
        ...state,
        he: action.payload,
      };
    case CREATE_HE:
      return {
        ...state,
        hes: [action.payload, ...state.hes],
        total: state.total + 1,
      };
    case DELETE_HE:
      return {
        ...state,
        hes: state.hes.filter((he) => he.id !== action.payload),
        total: state.total - 1,
      };

    default:
      return state;
  }
}

export default he
