import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Table,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import styles from '../../DOTPatientWithVolunteer/DOTWithNoApp/NoAppDotList.module.css'
import BackBtn from 'utils/backBtn'
import { getAllDOTs, getPatient } from 'store/actions'
import CustomPagination from 'components/Pagination/Pagination'
import clsx from 'clsx'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import { NotificationManager } from 'react-notifications'
import { clearPatientData } from 'store/actions'
import DOTWithAppDetails from '../../DOTPatientWithVolunteer/DOTWithApp/DOTWithAppDetails'
import NoVolunteerDotDetail from '../../DOTPatientWithNoVolunteer/DOTStatus/NoVolunteerDotDetail'
import { getDOT } from 'store/actions'
import { DOTWorking } from 'utils/DOTWorking'
import moment from 'moment'
import dayjs from 'dayjs'
import { currentDate } from 'utils/currentDate'

export const getWorkStatus = (work, status, date) => {
  const now = moment();
  if (work === 1 || work === 2) {
    if (status) {
      return 'အောင်မြင်ပါသည်'
    }
    let tempDotDate = moment(date.substring(0, 10));
    if (now.isAfter(tempDotDate, "day")) {
      return 'မအောင်မြင်ပါ'
    }
    return 'မသွားရသေးပါ'
  }
  return 'အောင်မြင်ပါသည်'
}

const VDotCalendar = ({
  status,
  patient,
  DOT,
  getAllDOTs,
  getPatient,
  getDOT,
}) => {
  const { id } = useParams()
  const router = useHistory()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [exportLoading, setExportLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getAllDOTs(id, query)
  }, [router.location.search])

  useEffect(() => {
    if (Object.keys(patient?.patient).length === 0) {
      getPatient(id)
    }
    return () => {
      clearPatientData()
    }
  }, [])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (startDate !== '') {
      query.start_date = startDate
    } else {
      delete query['start_date']
    }

    if (endDate !== '') {
      query.end_date = endDate
    } else {
      delete query['end_date']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  
  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `export-all-DOTs/${id}?${new URLSearchParams(query).toString()}`
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Volunteer_Name: data.change_volunteer?.volunteer?.name,
        Date: data.date,
        Work: data.work_status ? DOTWorking[data.work] : '',
        Work_Status: getWorkStatus(data.work, data.work_status, data?.date),
        ကြာချိန်: data.long,
        သာမာန်ဘေးထွက်ဆိုးကျိုး: data.minor_side_effects,
        အရေးကြီးဘေးထွက်ဆိုးကျိုး: data.major_side_effects,
        လုပ်ဆောင်ချက်: data.actions,
        Video: data.video ? data.video_url : null,
        Sign: data.sign ? data.sign_url : null,
        Type: data.change_volunteer?.type,
        Remark: data.remark,
      }))
      if (response.status === 'success') {
        excelExport(result, "VOT-Calendar");
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">({patient.patient?.name}) VOT Calendar</h3>
                <Row className="d-flex align-items-center">
                  <Col md={4}>
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      id="start_date"
                      className="form-control"
                      type="date"
                      min=""
                      max={currentDate()}
                      onChange={(event) => setStartDate(event.target.value)}
                      value={startDate}
                    />
                  </Col>
                  <Col md={4}>
                    <label htmlFor="end_date">End Date</label>
                    <input
                      id="end_date"
                      className="form-control"
                      type="date"
                      min={startDate}
                      max={currentDate()}
                      onChange={(event) => setEndDate(event.target.value)}
                      disabled={startDate === ''}
                      value={endDate}
                    />
                  </Col>
                  <Col md={4}>
                    <Button
                      size="sm"
                      color="success"
                      className="mt-4"
                      onClick={handleFilter}
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col className="text-right" xs="6">
                <div>
                  <Button
                    size="sm"
                    color="info"
                    className="mb-3"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? 'Loading' : 'Export'}
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    className="mb-3"
                    onClick={() => {
                      getAllDOTs(id)
                    }}
                  >
                    All
                  </Button>
                </div>
                <div className="d-flex flex-column mt-3">
                  <span>
                    {`အောင်မြင်သည့်အရေအတွက် - ${DOT?.success_count}`}{' '}
                  </span>
                  <span>{`မအောင်မြင်သည့်အရေအတွက် - ${DOT?.fail_count}`} </span>
                  <span>
                    {`မသွားရသေးသည့် ရက်စုစုပေါင်း - ${DOT?.pending_count}`}{' '}
                  </span>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="mt-3">
            <Table>
              <tbody className="d-flex flex-wrap justify-content-start px-5 border-0">
                {DOT.DOTs.map((DOT, index) => {
                  let style = null

                  if (DOT.work_status === null) {
                    style = null
                  } else {
                    if (DOT.work_status === 1) {
                      style = 'bg-primary text-white'
                    } else {
                      style = 'bg-gray'
                    }
                  }
                  return (
                    <td
                      className={clsx(
                        styles.box,
                        style,
                        'py-4',
                        DOT?.work === 4 && styles.no_cursor
                      )}
                      key={index}
                      onClick={async () => {
                        if (DOT?.work === 4) {
                          return
                        }
                        await getDOT(DOT.id)
                        setIsOpen(true)
                      }}
                    >
                      <div className="d-flex flex-column">
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {DOT.date}
                        </span>
                        <span>
                          {DOT.work === 3
                            ? '(without volunteer)'
                            : DOT.work === 4
                            ? '(with volunteer no app)'
                            : '(with volunteer app)'}
                        </span>
                      </div>
                    </td>
                  )
                })}
              </tbody>
            </Table>
          </CardBody>
          {DOT?.DOT?.work === 3 && (
            <NoVolunteerDotDetail isOpen={isOpen} toggle={setIsOpen} />
          )}
          {(DOT?.DOT?.work === 1 || DOT?.DOT?.work === 2) && (
            <DOTWithAppDetails isOpen={isOpen} toggle={setIsOpen} />
          )}
          {DOT.total > 28 && <CustomPagination pageCount={DOT.total / 28} />}
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
  auth: store.auth,
})

export default connect(mapStateToProps, {
  getAllDOTs,
  getPatient,
  getDOT,
})(VDotCalendar)
