export const roles = [
  { value: "Program Manager", label: "Program Manager" },
  { value: "CBTBC Project Officer", label: "CBTBC Project Officer" },
  { value: "MEAL Officer", label: "MEAL Officer" },
  { value: "Central Data Assistant", label: "Central Data Assistant" },
  { value: "Volunteer Supervisor", label: "Volunteer Supervisor" },
  { value: "Clinic MO", label: "Clinic MO" },
  { value: "Clinic Assistant", label: "Clinic Assistant" },
];
export const referTo = [
  {
    label: "MATA Clinic",
    value: "MATA Clinic",
  },
  {
    label: "THD",
    value: "THD",
  },
  {
    label: "Mobile Team",
    value: "Mobile Team",
  },
];
export const geneExperts = [
  {
    label: "N - MTB Not Detected",
    value: "N - MTB Not Detected",
  },
  {
    label: "I - Invalid/No Result",
    value: "I - Invalid/No Result",
  },
  {
    label: "T - MTB detected",
    value: "T - MTB detected",
  },
  {
    label: "RR - Rif resistance",
    value: "RR - Rif resistance",
  },
  {
    label: "TI - MTB(+)ve but Invalid RR",
    value: "TI - MTB(+)ve but Invalid RR",
  },
  {
    label: 'TT - MTB detected (trace)',
    value: 'TT - MTB detected (trace)'
  }
];
export const caseFoundActivity = [
  {
    label: "Community",
    value: "Community",
  },
  {
    label: "TB CI",
    value: "TB CI",
  },
  {
    label: "HE",
    value: "HE",
  },
];
export const trainingOptions = [
  {
    label: "SCLTBC Training",
    value: "SCLTBC Training",
  },
  {
    label: "VOT Training",
    value: "VOT Training",
  },
];
export const referType = [
  {
    label: "Patient",
    value: "Patient",
  },
  {
    label: "Sputum Sample",
    value: "Sputum Sample",
  },
];
export const positiveOrNegative = [
  {
    label: "Positive",
    value: "Positive",
  },

  {
    label: "Negative",
    value: "Negative",
  },
];

export const symptoms = [
  "Fever",
  "Any Cough",
  "Weight Loss",
  "Night Sweat",
  "Haemoptysis",
  "Chest Pain",
  "Fatigue",
  "Neck Gland",
  "Other",
];
export const chestXrayResult = [
  {
    label: "Normal",
    value: "Normal",
  },

  {
    label: "Active",
    value: "Active",
  },
  {
    label: "TB Suspect Presumptive TB",
    value: "TB Suspect Presumptive TB",
  },
  {
    label: "Healed",
    value: "Healed",
  },
  {
    label: "Others",
    value: "Others",
  },
];
export const fnacResult = [
  {
    label: "Other",
    value: "Other",
  },

  {
    label: "TB lymphadenitis",
    value: "TB lymphadenitis",
  },
];
export const SputumCultureResult = [
  {
    label: "Positive",
    value: "Positive",
  },

  {
    label: "Negative",
    value: "Negative",
  },
  {
    label: "Contaminated",
    value: "Contaminated",
  },
];
export const allTBConclusion = [
  {
    label: "No TB",
    value: "No TB",
  },
  {
    label: "TB",
    value: "TB",
  },
  {
    label: "MDR TB",
    value: "MDR TB",
  },
];
export const tbMDRTBConclusion = [
  {
    label: "TB",
    value: "TB",
  },
  {
    label: "MDR TB",
    value: "MDR TB",
  },
];

export const tbType = [
  {
    label: "Pulmonary BC",
    value: "Pulmonary BC",
  },
  {
    label: "Extra Pulmonary BC",
    value: "Extra Pulmonary BC",
  },
  {
    label: "Pulmonary CD",
    value: "Pulmonary CD",
  },
  {
    label: "Extra Pulmonary CD",
    value: "Extra Pulmonary CD",
  },
];
export const tbTypeBCs = [
  {
    label: "Pulmonary BC",
    value: "Pulmonary BC",
  },
  {
    label: "Extra Pulmonary BC",
    value: "Extra Pulmonary BC",
  },
];
export const tstResult = [
  {
    label: "Other",
    value: "Other",
  },

  {
    label: "TB lymphadenitis",
    value: "TB lymphadenitis",
  },
];
export const truenatResult = [
  {
    label: "Negative",
    value: "Negative",
  },
  {
    label: "TB with NO RIF-Resistance",
    value: "TB with NO RIF-Resistance",
  },
  {
    label: "TB with RIF-Resistance",
    value: "TB with RIF-Resistance",
  },
  {
    label: "TB with RIF-Indeterminate",
    value: "TB with RIF-Indeterminate",
  },
];
export const drugSensitivityResult = [
  {
    label: "H Resistance",
    value: "H Resistance",
  },

  {
    label: "R Resistance",
    value: "R Resistance",
  },

  {
    label: "Z Resistance",
    value: "Z Resistance",
  },
  {
    label: "E Resistance",
    value: "E Resistance",
  },
  {
    label: "S Resistance",
    value: "S Resistance",
  },
  {
    label: "Multi Drug Resistance",
    value: "Multi Drug Resistance",
  },

  {
    label: "Poly Resistance",
    value: "Poly Resistance",
  },
  {
    label: "Other",
    value: "Other",
  },
];
export const treatmentStatus = [
  {
    label: "Recieved TB Treatment",
    value: "Recieved TB Treatment",
  },
  {
    label: "Refer to other township",
    value: "Refer to other township",
  },
  {
    label: "Initial Loss",
    value: "Initial Loss",
  },
  {
    label: "Died before treatment",
    value: "Died before treatment",
  },
];

export const treatmentRegimen = [
  {
    label: "Initial Regimen",
    value: "Initial Regimen",
  },
  {
    label: "Retreatment Regimen",
    value: "Retreatment Regimen",
  },
  {
    label: "Childhood Regimen",
    value: "Childhood Regimen",
  },
  {
    label: "MDRTB Regimen",
    value: "MDRTB Regimen",
  },
];

export const typeOfTBPatient = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Relapse",
    value: "Relapse",
  },
  {
    label: "Treatment after failure",
    value: "Treatment after failure",
  },
  {
    label: "Treatment after loss to follow up",
    value: "Treatment after loss to follow up",
  },

  {
    label: "Other previously treated",
    value: "Other previously treated",
  },
  {
    label: "Previous treatment history unknown",
    value: "Previous treatment history unknown",
  },
];

export const smokingStatus = [
  {
    label: "Past",
    value: "Past",
  },
  {
    label: "Never",
    value: "Never",
  },
  {
    label: "Current",
    value: "Current",
  },
];
export const urbanRural = [
  {
    label: "Urban",
    value: "Urban",
  },
  {
    label: "Rural",
    value: "Rural",
  },
];

export const dmStatus = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
];

export const hivStatus = [
  {
    label: "Reactive",
    value: "Reactive",
  },
  {
    label: "Non-reactive",
    value: "Non-reactive",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
];

export const outcome = [
  { value: "Cured", label: "Cured" },
  { value: "Completed", label: "Completed" },
  { value: "Died", label: "Died" },
  { value: "Lost to Follow up", label: "Lost to Follow up" },
  { value: "Failed", label: "Failed" },
  {
    value: "Move to Second Line Treatment",
    label: "Move to Second Line Treatment",
  },
  { value: "Not Evaluated", label: "Not Evaluated" },
];
export const occupations = [
  { value: "AMW", label: "AMW" },
  { value: "CHW", label: "CHW" },
  { value: "Other", label: "Other" },
];
export const traningTypes = [
  { value: "New", label: "New" },
  { value: "Refresher", label: "Refresher" },
  { value: "On Job", label: "On Job" },
];
export const sexs = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const nationals = [
  { value: "N", label: "N" },
  { value: "Non-N", label: "Non-N" },
];

export const yesOrNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const doneOrNotDone = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];
export const discussionTopics = [
  "တီဘီရောဂါအကြောင်း",
  "ရောဂါလက္ခဏာနှင့် ကုသပုံနည်းလမ်းများ",
  "ရောဂါကူးစက်ပုံနည်းလမ်းများ",
  "ရောဂါစစ်ဆေးရန်နှင့် ကုသရန်နေရာများ",
  "ရောဂါကာကွယ်ရန်နည်းလမ်းများ",
  "တီဘီနှင့်အိပ်ချ်အိုင်ဗွီအကြောင်း",
  "တီဘီဆေးမှန်ကန်စွာသောက်သုံးခြင်းနည်းလမ်း",
  "အခြား",
];
export const no = [{ value: "No", label: "No" }];

export const treatmentRegimens = [
  { value: "STR", label: "STR" },
  { value: "LTR", label: "LTR" },
  { value: "OSSTR", label: "OSSTR" },
  { value: "OLTR", label: "OLTR" },
  {
    value: "Individualized Regime (MDR)",
    label: "Individualized Regime (MDR)",
  },
  {
    value: "Individualized Regime (Pre-XDR)",
    label: "Individualized Regime (Pre-XDR)",
  },
  { value: "XDR", label: "XDR" },
  { value: "Initial Regime (IR)", label: "Initial Regime (IR)" },
  { value: "Retreatment Regime (RR)", label: "Retreatment Regime (RR)" },
  { value: "Childhood Regime (CR)", label: "Childhood Regime (CR)" },
  { value: "Modified Regime", label: "Modified Regime" },
];

export const dones = [
  { value: "Done", label: "Done" },
  { value: "Not Done", label: "Not Done" },
];

export const firstXpertStatusResults = [
  { value: "T", label: "T" },
  { value: "RR", label: "RR" },
  { value: "TI", label: "TI" },
  { value: "N", label: "N" },
];

export const secondXpertStatusResults = [
  { value: "RR/()", label: "RR/()" },
  { value: "RR/(H)", label: "RR/(H)" },
  { value: "RR/(M)", label: "RR/(M)" },
  { value: "RR/(L)", label: "RR/(L)" },
  { value: "RR/(VL)", label: "RR/(VL)" },
];

export const DSTResults = [
  { value: "Negative", label: "Negative" },
  { value: "Positive", label: "Positive" },
];

export const susceptibleOrResistantTos = [
  { value: "H", label: "H" },
  { value: "R", label: "R" },
  { value: "Z", label: "Z" },
  { value: "E", label: "E" },
  { value: "S", label: "S" },
  { value: "Am", label: "Am" },
  { value: "Km", label: "Km" },
  { value: "Cm", label: "Cm" },
  { value: "Fq", label: "Fq" },
  { value: "Other", label: "Other" },
];

export const registrationOnGroups = [
  { value: "1=New", label: "1=New" },
  { value: "2=Non-Converter (IR/RR)", label: "2=Non-Converter (IR/RR)" },
  {
    value: "3=Treatment After Lost To Follow up (IR/RR)",
    label: "3=Treatment After Lost To Follow up (IR/RR)",
  },
  {
    value: "4=Treatment After Failure Of Treatment (IR/RR)",
    label: "4=Treatment After Failure Of Treatment (IR/RR)",
  },
  { value: "5=Relapse (IR/RR)", label: "5.=Relapse (IR/RR)" },
  {
    value: "6.1.1=SSTR (LTFU/Failure/Relapse)",
    label: "6.1.1=SSTR (LTFU/Failure/Relapse)",
  },
  {
    value: "6.1.2=LTR (LTFU/Failure/Relapse)",
    label: "6.1.2=LTR (LTFU/Failure/Relapse)",
  },
  {
    value: "6.2.1=OSSTR (LTFU/Failure/Relapse)",
    label: "6.2.1=OSSTR (LTFU/Failure/Relapse)",
  },
  {
    value: "6.2.2=OLTR (LTFU/Failure/Relapse)",
    label: "6.2.2=OLTR (LTFU/Failure/Relapse)",
  },
  {
    value: "6.3=Individualized/Other regimen (LTFU/Failure/Relapse)",
    label: "6.3=Individualized/Other regimen (LTFU/Failure/Relapse)",
  },
  { value: "7=Others", label: "7=Others" },
  { value: "8=Transfer In", label: "8=Transfer In" },
];

export const HIVResults = [
  { value: "Non-Reactive", label: "Non-Reactive" },
  { value: "Reactive", label: "Reactive" },
];

export const COMorbidities = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Unknown", label: "Unknown" },
];

export const COMorbiditieOthers = [
  { value: "Hepatitis B+", label: "Hepatitis B+" },
  { value: "Hepatitis C+", label: "Hepatitis C+" },
  { value: "B+&C+", label: "B+&C+" },
  { value: "Neg", label: "Neg" },
];

export const dotVisitDate = [
  { value: 1, label: "1 week" },
  { value: 2, label: "2 week" },
  { value: 4, label: "1 month" },
  { value: 8, label: "2 month" },
  { value: 12, label: "3 month" },
];
export const phases = [
  { value: "Initial Phase", label: "Initial Phase" },
  { value: "Continuous Phase", label: "Continuous Phase" },
];

export const smearResults = [
  { value: "0", label: "0" },
  { value: "Scanty", label: "Scanty" },
  { value: "+", label: "+" },
  { value: "++", label: "++" },
  { value: "+++", label: "+++" },
];

export const cultureAndDSTResults = [
  { value: "Positive", label: "Positive" },
  { value: "Negative", label: "Negative" },
  { value: "Contaminated", label: "Contaminated" },
];

export const CBTBCPatientTypes = [
  {
    value: "ပိုးတွေ့တီဘီ",
    label: "ပိုးတွေ့တီဘီ",
  },
  { value: "အိပ်ချ်အိုင်ဗီပိုးတွေ့တီဘီ", label: "အိပ်ချ်အိုင်ဗီပိုးတွေ့တီဘီ" },
  {
    value: "အသက် (၅) နှစ်အောက်ကလေးတီဘီလူနာ",
    label: "အသက် (၅) နှစ်အောက်ကလေးတီဘီလူနာ",
  },
  { value: "အခြား", label: "အခြား" },
];
export const missDoseResults = [
  {
    value: "ပြန်လည်ဆေးကုသမူခံယူမည်ဟု ပြောကြားခြင်း",
    label: "ပြန်လည်ဆေးကုသမူခံယူမည်ဟု ပြောကြားခြင်း",
  },
  {
    value: "သက်ဆိုင်ရာ ကျန်းမာရေးဌာနသို့ ပြန်လာခြင်း",
    label: "သက်ဆိုင်ရာ ကျန်းမာရေးဌာနသို့ ပြန်လာခြင်း",
  },
  {
    value: "သက်ဆိုင်ရာ ကျန်းမာရေးဌာနသို့ ပြန်လာရန် ငြင်းဆန်ခြင်း",
    label: "သက်ဆိုင်ရာ ကျန်းမာရေးဌာနသို့ ပြန်လာရန် ငြင်းဆန်ခြင်း",
  },
  {
    value: "အခြားဆေးကုသမူဌာနတွင်တီဘီကုသမူခံယူနေခြင်း",
    label: "အခြားဆေးကုသမူဌာနတွင်တီဘီကုသမူခံယူနေခြင်း",
  },
  {
    value: "အပြင်းအထန် နေမကောင်းဖြစ်နေခြင်း",
    label: "အပြင်းအထန် နေမကောင်းဖြစ်နေခြင်း",
  },
  { value: "သေဆုံးခြင်း", label: "သေဆုံးခြင်း" },
  {
    value: "လိပ်စာမှား/ဖုန်းနံပါတ်မှား/ရှာမတွေ့ခြင်း/လိပ်စာပြောင်း",
    label: "လိပ်စာမှား/ဖုန်းနံပါတ်မှား/ရှာမတွေ့ခြင်း/လိပ်စာပြောင်း",
  },
  { value: "အခြား", label: "အခြား" },
];

export const medicines = [
  { value: "Levofloxacin (Lfx)", label: "Levofloxacin (Lfx)" },
  { value: "Moxifloxacin (Mfx)", label: "Moxifloxacin (Mfx)" },
  { value: "Bedaquiline (Bdq)", label: "Bedaquiline (Bdq)" },
  { value: "Linezolid (Lzd)", label: "Linezolid (Lzd)" },
  { value: "Clofazimine (Cfz)", label: "Clofazimine (Cfz)" },
  { value: "Cycloserine (Cs)", label: "Cycloserine (Cs)" },
  { value: "Terizidone (Trd)", label: "Terizidone (Trd)" },
  { value: "Ethambutol (E)", label: "Ethambutol (E)" },
  { value: "Delamanid (Dlm)", label: "Delamanid (Dlm)" },
  { value: "Pyrazinamide (Z)", label: "Pyrazinamide (Z)" },
  { value: "Imipenem (Ipm)", label: "Imipenem (Ipm)" },
  { value: "Meropenem (Mpm)", label: "Meropenem (Mpm)" },
  { value: "Amikacin (Amk)", label: "Amikacin (Amk)" },
  { value: "Steptomycin (S)", label: "Steptomycin (S)" },
  { value: "Ethionamide (Eto)", label: "Ethionamide (Eto)" },
  { value: "Prothionamide (Pto)", label: "Prothionamide (Pto)" },
  {
    value: "p-aminosalicylic acid (PAS)",
    label: "p-aminosalicylic acid (PAS)",
  },
  { value: "Isoniazid (H)", label: "Isoniazid (H)" },
  { value: "Pretonamid (Pa)", label: "Pretonamid (Pa)" },
  { value: "Co-amoxiclav (Co-amox)", label: "Co-amoxiclav (Co-amox)" },
];

export const reportMonths = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
  { value: "1 Quarter", label: "1 Quarter" },
  { value: "2 Quarter", label: "2 Quarter" },
  { value: "3 Quarter", label: "3 Quarter" },
  { value: "4 Quarter", label: "4 Quarter" },
  { value: "Annual", label: "Annual" },
];
export const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
export const fUMonths = [
  { value: "Month 2", label: "Month 2" },
  { value: "Month 3", label: "Month 3" },
  { value: "Month 5", label: "Month 5" },
  { value: "Month 6", label: "Month 6" },
  { value: "Month 8", label: "Month 8" },
  { value: "End of month", label: "End of month" },
];
export const educations = [
  { value: "Primary", label: "Primary" },
  { value: "Secondary", label: "Secondary" },
  { value: "Higher", label: "Higher" },
];

export const townships = [
  { value: "Botataung Township", label: "Botataung Township" },
  { value: "Dagon Seikkan Township", label: "Dagon Seikkan Township" },
  { value: "Hlaing Township", label: "Hlaing Township" },
  { value: "Hlaingthaya Township", label: "Hlaingthaya Township" },
  { value: "Insein Township", label: "Insein Township" },
];

export const districts = [
  { value: "Eastern District", label: "Eastern District" },
  { value: "Northern District", label: "Northern District" },
];

export const patients = [
  { value: "Ko Ko", label: "Ko Ko" },
  {
    value: "Kyaw Kyaw",
    label: "Kyaw Kyaw",
  },
];

export const volunteers = [
  { value: "Mg Mg", label: "Mg Mg" },
  {
    value: "Ko Ko",
    label: "Ko Ko",
  },
];

export const years = [
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

export const types = [
  { value: "On Job", label: "On Job" },
  { value: "New", label: "New" },
  { value: "Refresher", label: "Refresher" },
];

export const DOTTypes = [
  { value: "App", label: "App" },
  { value: "No App", label: "No App" },
];
export const VDOTCategories = [
  { value: "Change to In Person DOT", label: "Change to In Person DOT" },
  { value: "Change to Family DOT", label: "Change to Family DOT" },
  { value: "Deny VOT", label: "Deny VOT" },
  { value: "Damage and Losing Phone", label: "Damage and Losing Phone" },
  { value: "Technical Issues", label: "Technical Issues" },
  { value: "Internet Connection Problem", label: "Internet Connection Problem" },
  { value: "Stop VOT due to treatment outcome", label: "Stop VOT due to treatment outcome" },
  { value: "Other Outcomes", label: "Other Outcomes" },
];

export const VOTDeclineReasons = [
  { value: "Family DOT", label: "Family DOT" },
  { value: "Privacy", label: "Privacy" },
  { value: "Others", label: "Others" },
];

