import React from 'react'
import DatePicker from './DatePicker'

function DateRangePicker({ onDateChange }) {
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()

  const onStartDateChange = (date) => {
    setStartDate(date)
    onDateChange({start_date: date, end_date: endDate})
  }

  const onEndDateChange = (date) => {
    setEndDate(date)
    onDateChange({start_date: startDate, end_date: date})
  }

  return (
    <div className="d-flex" style={{height:35}}>
      <DatePicker
        placeholder={'Start Date'}
        onChange={(date) => onStartDateChange(date)}
        value={startDate}
      />
      <DatePicker
        placeholder={'End Date'}
        onChange={(date) => onEndDateChange(date)}
        value={endDate}
      />
    </div>
  )
}

export default DateRangePicker