import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomPagination from "components/Pagination/Pagination";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getVolunteers,
  getTownships,
  deleteVolunteer,
  recoverVolunteer,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { paginationLimit } from "variables/limits";
import { isVolunteerSupervisor } from "utils/middleware";
import BackBtn from "utils/backBtn";
import CustomAlert from "components/Alert/CustomAlert";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import { call } from "services/api";

const VolunteerTrashList = ({
  status,
  auth,
  volunteer,
  township,
  getVolunteers,
  getTownships,
  deleteVolunteer,
  recoverVolunteer,
}) => {
  const { role } = auth.user;
  const router = useHistory();
  const [alert, setAlert] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.accept_status = false;
    query.status = 0;
    if (!("page" in query)) {
      query.page = 1;
    }
    getVolunteers(query);
  }, [getVolunteers, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setSelectedTownship(null);
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.status = 0;
      const response = await call(
        "get",
        `volunteers-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Name: data.name,
               Code: data.code,
        Password: data.plain_password,

        Plain_Password: data.plain_password,
        Age: data.age,
        Gender: data.gender,
        Village: data.village,
        Address: data.address,
        Ward: data.ward,
        Phone: data.phone,
        Occupation: data.occupation,
        Education_Status: data.education_status,
        Township: data.township.name,
        Past_History_of_TB: data.past_history_of_TB,
        Volunteer_joined_date: data.volunteer_joined_date,
        Remark: data.remark,
      }));

      if (response.status === "success") {
        excelExport(result, "Volunteers Trash List");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteVolunteer(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const handleRecover = async (id) => {
    await recoverVolunteer(id);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Volunteer Trash List</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="info"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>

            {!isVolunteerSupervisor(role) && (
              <Row>
                <Col sm={4}>
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
                <Col sm={4}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={handleFilter}
                    className="mt-3"
                  >
                    <i className="fa fa-search" />
                  </Button>
                </Col>
              </Row>
            )}
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Address</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={volunteer.volunteers?.map((volunteer, index) => (
              <tr key={volunteer.id}>
                <td className="fixed-cell left">{index + 1}</td>
                <td className="fixed-cell left">{volunteer.name}</td>
                <td>{volunteer.address}</td>
                <td>{volunteer.phone}</td>
                <td>{volunteer.age}</td>
                <td>{volunteer.gender}</td>
                <td>{volunteer.township.name}</td>
                <td>
                  <>
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => handleRecover(volunteer.id)}
                    >
                      Recover
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(volunteer.id)}
                    >
                      Delete
                    </Button>
                  </>
                </td>
              </tr>
            ))}
          />

          {volunteer.total > paginationLimit && (
            <CustomPagination pageCount={volunteer.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
});

export default connect(mapStateToProps, {
  getVolunteers,
  getTownships,
  deleteVolunteer,
  recoverVolunteer,
})(VolunteerTrashList);
