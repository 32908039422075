import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { connect } from "react-redux";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";
import { yesOrNo } from "variables/options";
import { VOTDeclineReasons } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { useHistory } from "react-router-dom";

const Accept = ({ patient, updatePatient, auth }) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedAccept, setSelectedAccept] = useState(null);
  const [selectedDeclineReason, setSelectedDeclineReason] = useState(null);

  useEffect(() => {
    const { VOT_accept, VOT_decline_reason, VOT_decline_other_reason } =
      patient.patient;

    if (VOT_accept) {
      setValue("VOT_accept", {
        value: VOT_accept,
        label: VOT_accept,
      });
      setSelectedAccept({
        value: VOT_accept,
        label: VOT_accept,
      });
    }

    setValue(
      "VOT_decline_reason",
      VOT_decline_reason === null
        ? null
        : {
            value: VOT_decline_reason,
            label: VOT_decline_reason,
          }
    );
    setSelectedDeclineReason(
      VOT_decline_reason === null
        ? null
        : {
            value: VOT_decline_reason,
            label: VOT_decline_reason,
          }
    );
    setValue("VOT_decline_other_reason", VOT_decline_other_reason);
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    if (data.VOT_accept?.value === "Yes") {
      router.push(`/admin/move-to-DOT/${id}`);
      return;
    }
    setLoading(true);
    // await updatePatient(
    //   id,
    //   {
    //     VOT_accept: data.VOT_accept?.value,
    //     VOT_decline_reason: data.VOT_accept
    //       ? data.VOT_decline_reason?.value
    //       : null,
    //     VOT_decline_other_reason: data.VOT_accept
    //       ? data.VOT_decline_other_reason
    //       : null,
    //   },
    //   "VOT-accept",
    //   "VOT Accept"
    // );
    await updatePatient(
      id,
      {
        VOT_accept: data.VOT_accept?.value,
        VOT_decline_reason:
          selectedAccept?.value === "No"
            ? selectedDeclineReason?.value
            : null,
            VOT_decline_other_reason:
            selectedAccept?.value === "No" &&
            selectedDeclineReason?.value === "Others"
            ? data.VOT_decline_other_reason
            : null,
      },
      "VOT-accept",
      "VOT Accept"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="VOT_accept"
              label="VOT Accept"
              control={control}
              rules={{
                required: "VOT Accept is required!",
              }}
              options={yesOrNo}
              value={selectedAccept}
              setData={setSelectedAccept}
              placeholder="VOT Accept"
              isRequired={true}
            />
          </Col>
          {selectedAccept?.value === "No" && (
            <Col md={4}>
              <CustomSelect
                id="VOT_decline_reason"
                label="VOT Decline Reason"
                control={control}
                rules={{
                  required: "VOT Decline Reason is required!",
                }}
                options={VOTDeclineReasons}
                value={selectedDeclineReason}
                setData={setSelectedDeclineReason}
                placeholder="VOT Decline Reason"
                isRequired={true}
              />
            </Col>
          )}
        </Row>
        {selectedAccept?.value === "No" &&
          selectedDeclineReason?.value === "Others" && (
            <Row>
              <Col md={4}>
                <CustomTextArea
                  id="VOT_decline_other_reason"
                  label="VOT decline other reason"
                  register={{
                    ...register("VOT_decline_other_reason"),
                  }}
                  placeholder="VOT decline other reason"
                  errors={errors}
                />
              </Col>
            </Row>
          )}
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(Accept);
