import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, CardBody, Card, CardTitle, Container } from "reactstrap";
import { isVolunteerSupervisor } from "utils/middleware";

const CBTBCPatientPage = ({ auth }) => {
  const { role } = auth.user;
  const router = useHistory();
  return (
    <Container className="mt-6" fluid>
      <Row className="justify-content-around">
        <Col md="6" xl="6">
          <Card
            className="bg-gradient-default border-0 p-5"
            onClick={() => router.push("/admin/raw-data")}
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h3" className=" mb-0 text-white">
                    Raw Data (Refer)
                    {isVolunteerSupervisor(role) && (
                      <>
                        <br></br>
                        <span> (ညွှန်းပို့ထားသောစာရင်း)</span>
                      </>
                    )}
                  </CardTitle>
                </div>

                <Col className="col-1">
                  <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i className="ni ni-bullet-list-67" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" xl="6">
          <Card
            className={`bg-gradient-default border-0 p-5`}
            onClick={() => router.push("/admin/patient-list")}
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h3" className="mb-0 text-white">
                    Patient List
                    {isVolunteerSupervisor(role) && (
                      <>
                        <br></br>
                        <span>(မြို့နယ်သို့ရောက်သော စာရင်း)</span>
                      </>
                    )}
                  </CardTitle>
                </div>

                <Col className="col-1">
                  <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                    <i className="ni ni-circle-08" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="6" xl="6">
          <Card
            className="bg-gradient-success border-0 p-5"
            onClick={() => router.push("/admin/all-patient-list")}
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h3" className=" mb-0 text-white">
                    All Patient List
                    <br></br>
                    <span style={{ fontSize: "18px" }}>
                      (Raw Data + Accepted Patients)
                    </span>
                    {isVolunteerSupervisor(role) && (
                      <>
                        <br></br>
                        <div>
                          {" "}
                          (လူနာစာရင်းစုစုပေါင်း)
                          <br></br>
                          <span style={{ fontSize: "18px" }}>
                            (ညွှန်းပို့ + မြို့နယ်သို့ရောက်)
                          </span>
                        </div>
                      </>
                    )}
                  </CardTitle>
                </div>

                <Col className="col-1">
                  <div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
                    <i className="ni ni-folder-17" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
});

export default connect(mapStateToProps, {})(CBTBCPatientPage);
