import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_TOWNSHIP,
  DELETE_TOWNSHIP,
  SET_TOWNSHIP,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_TOWNSHIPS,
  UPDATE_TOWNSHIP,
} from '../type'
import { NotificationManager } from 'react-notifications'
// const dummyData = [
//   {
//     id: 1,
//     name: 'AMT',
//     abbreviation: 'abbreviation',
//     region: {
//       name: "MON"
//     },
//     district: {
//       name: "MDY"
//     }
//   }
// ]
export const getTownships = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `townships?${new URLSearchParams(query).toString()}`,
      )
      dispatch({
        type: SHOW_TOWNSHIPS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getTownship = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `townships/${id}`)
      const result = response.data
      // const result = dummyData.find((d)=> d.id === id)
      dispatch({
        type: SET_TOWNSHIP,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createTownship = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'townships', data)
      const result = response.data
      // dummyData.push(data)
      // const result = data;
      
      dispatch({
        type: CREATE_TOWNSHIP,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Township has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateTownship = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call('post', `townships/${id}?_method=PUT`, data)
      const result = response.data
      // const result = data;

      dispatch({
        type: UPDATE_TOWNSHIP,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Township has been updated successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const deleteTownship = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
     await call('delete', `townships/${id}`)

      dispatch({ type: DELETE_TOWNSHIP, payload: id })
      NotificationManager.success('Township has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
