import React, { useEffect, useState, useCallback, useRef } from 'react'
import Select from 'react-select'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPatients, getPatient, getTownships } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import { patientMiddleware, UPDATE, DELETE } from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'
import { CREATE } from 'utils/middleware'
import { EXPORT } from 'utils/middleware'
import { VscRefresh } from 'react-icons/vsc'

const DOTPatientWithNoVolunteerList = ({
  status,
  auth,
  patient,
  township,
  getPatients,
  getPatient,
  getTownships,
}) => {
  const router = useHistory()
  const { role } = auth.user
  const [exportLoading, setExportLoading] = useState(false)

  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [filterPatients, setFilterPatients] = useState([])
  const presumptiveTBNumberRef = useRef(null)

  const getFilterPatients = useCallback(async () => {
    const query = queryString.parse(router.location.search)
    query.DOT_with_no_volunteer = true
    const response = await call(
      'get',
      `patients?${new URLSearchParams(query).toString()}`
    )
    const patients = response.data?.map((element) => ({
      value: element.id,
      label: element.name,
    }))
    setFilterPatients(patients)
  }, [])

  useEffect(() => {
    getFilterPatients()
  }, [])
  useEffect(() => {
    const query = queryString.parse(router.location.search)
    query.DOT_with_no_volunteer = true
    if (!('page' in query)) {
      query.page = 1
    }
    getPatients(query)
    getTownships()
  }, [getPatients, router.location.search, getTownships])

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    delete query['complete']
    delete query['not_complete']

    if (selectedPatient) {
      query.patient_id = selectedPatient.value
    } else {
      delete query['patient_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }
    if (presumptiveTBNumberRef.current.value) {
      query.presumptive_TB_number = presumptiveTBNumberRef.current.value
      presumptiveTBNumberRef.current.value = null
    } else {
      delete query['presumptive_TB_number']
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    // setSelectedPatient(null);
    // setSelectedTownship(null);
  }
  const handleComplete = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    query.complete = true

    delete query['not_complete']

    if (selectedPatient) {
      query.patient_id = selectedPatient.value
    } else {
      delete query['patient_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    setSelectedPatient(null)
    setSelectedTownship(null)
  }

  const handleNotComplete = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    query.not_complete = true

    delete query['complete']

    if (selectedPatient) {
      query.patient_id = selectedPatient.value
    } else {
      delete query['patient_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    setSelectedPatient(null)
    setSelectedTownship(null)
  }

  const handleAll = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1

    delete query['complete']
    delete query['not_complete']
    delete query['patient_id']
    delete query['township_id']

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    setSelectedPatient(null)
    setSelectedTownship(null)
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      query.DOT_with_no_volunteer = true
      const response = await call(
        'get',
        `patients-export?${new URLSearchParams(query).toString()}`
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
               Code: data.code,
        Password: data.plain_password,

        Township: data.township?.name,
        Name: data.name,
        Address: data.address,
        Phone: data.phone,
        Age: data.age,
        Sex: data.sex,
        Referral_Volunteer: data.referral_volunteer
          ? `${data.referral_volunteer?.name}(${data.referral_volunteer?.code})`
          : '',
        Referral_Date: data.referral_date ? data.referral_date : '',
        Refer_To: data.refer_to ? data.refer_to : '',
        Case_Founded_Activity: data.case_founded_activity
          ? data.case_founded_activity
          : '',
        TB_Code: data.TB_code ? data.TB_code : '',
        Referred_Type: data.referred_type ? data.referred_type : '',
        Symptoms:
          data.symptoms &&
          JSON.parse(data.symptoms)
            .map((d) => d.eng_name)
            .join(','),
        Presumptive_TB_Number:
          data.township?.abbreviation + '/' + data.presumptive_TB_number,
        Registration_Date: data.registration_date,
        Sputum_Done_Or_Not_Done:
          data.sputum_done_or_not_done === '1' ? 'done' : 'not done',
        Sputum_Examination_Date: data.sputum_examination_date,
        Sputum_Smear_Result: data.sputum_smear_result,
        Chest_XRay_Done_Or_Not_Done:
          data.chest_Xray_done_or_not_done === '1' ? 'done' : 'not done',
        Chest_XRay_Examination_Date: data.chest_Xray_examination_date,
        Chest_XRay_Result: data.chest_Xray_result,
        Gene_XPert_Done_Or_Not_Done:
          data.gene_Xpert_done_or_not_done === '1' ? 'done' : 'not done',
        Gene_XPert_Examination_Date: data.gene_Xpert_examination_date,
        Gene_XPert_Result: data.gene_Xpert_result,
        Truenat_Done_Or_Not_Done:
          data.truenat_done_or_not_done === '1' ? 'done' : 'not done',
        Truenat_Examination_Date: data.truenat_examination_date,
        Truenat_Result: data.truenat_result,
        FNAC_Done_Or_Not_Done:
          data.FNAC_done_or_not_done === '1' ? 'done' : 'not done',
        FNAC_Examination_Date: data.FNAC_examination_date,
        FNAC_Result: data.FNAC_result,
        Tuberculin_Skin_Done_Or_Not_Done:
          data.tuberculin_skin_done_or_not_done === '1' ? 'done' : 'not done',
        TST_Examination_Date: data.TST_examination_date,
        Size_Of_Induration_In_Millimeter: data.size_of_induration_in_millimeter,
        Conclusion_Of_TST: data.conclusion_of_TST,
        Sputum_Culture_Done_Or_Not_Done:
          data.sputum_culture_done_or_not_done === '1' ? 'done' : 'not done',
        Sputum_Culture_Examination_Date: data.sputum_culture_examination_date,
        Sputum_Culture_Result: data.sputum_culture_result,
        Drug_Susceptibility_Done_Or_Not_Done:
          data.drug_susceptibility_done_or_not_done === '1'
            ? 'done'
            : 'not done',
        Drug_Susceptibility_Date: data.drug_susceptibility_date,
        Drug_Susceptibility_Result: data.drug_susceptibility_result,
        Drug_Susceptibility_Remark: data.drug_susceptibility_remark,
        TB_Status: data.TB_status,
        Treatment_Status: data.treatment_status,
        TB_DRTB_Code: data.TB_DRTB_code,
        Treatment_Start_Date: data.treatment_start_date,
        Treatment_Registration_Date: data.treatment_registration_date,
        Treatment_Regimen: data.treatment_regimen,
        Defined_TB_Type: data.defined_TB_type,
        Type_Of_TB_Patient: data.type_of_TB_patient,
        Smoking_Status: data.smoking_status,
        DM_Status: data.DM_status,
        HIV_Result: data.HIV_result,
        CPT_status: data.CPT_status,
        CPT_date: data.CPT_date,
        ART_status: data.ART_status,
        ART_date: data.ART_date,
        Treatment_Outcome: data.treatment_outcome,
        Treatment_Outcome_Date: data.treatment_outcome_date,
        Remark: data.remark,
        Volunteer: data.volunteer ? data.volunteer?.name : '',
        DOT_Start_Date: data.DOT_start_date,
        DOT_supervision: data.dot_supervision,
        DOT_End_Date: data.DOT_end_date,
        VDOT_end_date: data.vdot_conclusion_date,
        VDOT_outcome: data.vdot_conclusion_category,
        VDOT_conclusion_remark: data.vdot_conclusion_remark,
      }))

      if (response.status === 'success') {
        excelExport(result, 'DOT_Patients_With_No_Volunteer')
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }
  const handleRefresh = () => {
    router.push(router.location.pathname)
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">VOT Patient With No Volunteer List</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={() => handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                {/* {patientMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => router.push("/admin/new-patient")}
                  >
                    New
                  </Button>
                )} */}
                {patientMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? 'Loading' : 'Export'}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Select
                  className="mt-3"
                  options={filterPatients}
                  value={selectedPatient}
                  onChange={(value) => setSelectedPatient(value)}
                  placeholder="Filter by patient..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              {
                <Col sm={4}>
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              }
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleComplete}
                  className="mt-3"
                >
                  Complete
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleNotComplete}
                  className="mt-3"
                >
                  Not Complete
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleAll}
                  className="mt-3"
                >
                  All
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <input
                  className="form-control"
                  ref={presumptiveTBNumberRef}
                  placeholder="Presumptive TB_number"
                />
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Password</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">Township</th>

                {patientMiddleware(auth.user.role, UPDATE) &&
                  patientMiddleware(auth.user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={patient.patients?.map((r, index) => (
              <tr key={r.id}>
                <td
                  className="fixed-cell left"
                  style={{
                    backgroundColor: r.DOT_end_date ? '#009879' : '#EB2429',
                    color: '#ffffff',
                  }}
                >
                  {index + 1}
                </td>
                <td>{r.name}</td>
                <td>{r.code}</td>
                <td>{r.plain_password}</td>
                <td>{r.phone}</td>
                <td>{r.age}</td>
                <td>{r.sex}</td>
                <td>{r.township.name}</td>

                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={async () => {
                      await getPatient(r.id)
                      router.push(
                        `/admin/dot-patient-with-no-volunteer-detail/${r.id}`
                      )
                    }}
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />

          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  patient: store.patient,
})

export default connect(mapStateToProps, {
  getPatients,
  getPatient,
  getTownships,
})(DOTPatientWithNoVolunteerList)
