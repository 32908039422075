import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import {
  CREATE_VOLUNTEER_HANDOUT,
  DELETE_VOLUNTEER_HANDOUT,
  SET_LOADING,
  SET_SUCCESS,
  SET_VOLUNTEER_HANDOUT,
  SHOW_VOLUNTEER_HANDOUTS,
  UPDATE_VOLUNTEER_HANDOUT,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getVolunteerHandouts = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", "volunteer-handouts");
      const result = response.data;

      dispatch({
        type: SHOW_VOLUNTEER_HANDOUTS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getVolunteerHandout = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("get", `volunteer-handouts/${id}`);
      const result = response.data;

      dispatch({
        type: SET_VOLUNTEER_HANDOUT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createVolunteerHandout = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "volunteer-handouts", data);
      const result = response.data;

      dispatch({
        type: CREATE_VOLUNTEER_HANDOUT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(
        "Volunteer Handout has been created successfully!"
      );
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const updateVolunteerHandout = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        "post",
        `volunteer-handouts/${id}?_method=PUT`,
        data
      );
      const result = response.data;

      dispatch({
        type: UPDATE_VOLUNTEER_HANDOUT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(
        "Volunteer Handout has been updated successfully!"
      );
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};
export const deleteHandout = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `volunteer-handouts/${id}`);

      dispatch({ type: DELETE_VOLUNTEER_HANDOUT, payload: id });
      NotificationManager.success("Handout has been deleted successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
