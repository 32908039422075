import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import dayjs from "dayjs";
import { createNoAppDOT, getNoAppDOTs } from "store/actions";
import { useParams } from "react-router";
import moment from "moment";

const NoAppDotCreate = ({
  isOpen,
  toggle,
  status,
  createNoAppDOT,
  lastDotDate,
  setLastDotDate,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");

  const onSubmit = async (values) => {
    setLoading(true);
    let datesArray = [];
    let start_date = moment(values.start_date);
    let end_date = moment(values.end_date);
    while (!start_date.isAfter(end_date)) {
      datesArray.push(dayjs(start_date).format("YYYY-MM-DD"));
      start_date = start_date.add(1, "days");
    }

    // const start_date = new Date(values.start_date);
    // while (start_date <= new Date(values.end_date)) {
    //   datesArray.push(dayjs(start_date).format("YYYY-MM-DD"));
    //   start_date.setDate(start_date.getDate() + 1);
    // }
    await createNoAppDOT({ patient_id: id, dates: datesArray });
    // let endDate = new Date(values.end_date);
    // endDate.setDate(endDate.getDate() + 1);
    // setLastDotDate(dayjs(endDate).format("YYYY-MM-DD"));

    const date = moment(values.end_date);
    const newDate = date.add(1, "days");
    setLastDotDate(dayjs(newDate).format("YYYY-MM-DD"));
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create DOT Visit Date (No App)"
      onClick={() => {
        toggle(false);
        reset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomDateInput
            id="start_date"
            label="Start Date"
            register={{
              ...register("start_date", {
                required: "Start Date is required!",
              }),
            }}
            placeholder="Select Start Date"
            errors={errors}
            isRequired={true}
            min={lastDotDate}
            max={currentDate()}
            onChange={(event) => setStartDate(event.target.value)}
          />
          <CustomDateInput
            id="end_date"
            label="End Date"
            register={{
              ...register("end_date", {
                required: "End Date is required!",
              }),
            }}
            placeholder="Select End Date"
            errors={errors}
            isRequired={true}
            min={startDate}
            max={currentDate()}
          />
          {/* <SelectBox
            label="Phase"
            control={control}
            name="phase"
            options={phases}
            rules={{ required: true }}
          /> */}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  DOT: store.DOT,
});
export default connect(mapStateToProps, { createNoAppDOT, getNoAppDOTs })(
  NoAppDotCreate
);
