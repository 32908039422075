import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import { updateMissDoseResults } from "store/actions";
import SelectBox from "components/Selectbox";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { missDoseResults } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { UPDATE } from "utils/middleware";
import { activitiesMiddleware } from "utils/middleware";

const FinalOutcome = ({ missDose, updateMissDoseResults, auth }) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { outcome_date, outcome_result, outcome_remark } = missDose.missDose;
    setValue("outcome_date", outcome_date);
    setValue("outcome_result", outcome_result);
    setValue("outcome_remark", outcome_remark);
  }, [missDose.missDose, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
   // await updateMissDoseResults(missDose.missDose?.id, data, "final-outcome");
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <CustomDateInput
            id="outcome_date"
            label="Outcome Date"
            register={{
              ...register("outcome_date"),
            }}
            errors={errors}
            isRequired={false}
            disabled={true}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <SelectBox
            label="Outcome Result"
            control={control}
            name="outcome_result"
            options={missDoseResults}
            disabled={true}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <CustomTextArea
            id="outcome_remark"
            label="Remark"
            register={{
              ...register("outcome_remark", {
                required: false,
              }),
            }}
            placeholder="Remark"
            errors={errors}
            disable={true}
          />
        </Col>
      </Row>
      {/* {activitiesMiddleware(auth.user?.role, UPDATE) && (
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      )} */}
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  missDose: store.missDose,
});

export default connect(mapStateToProps, { updateMissDoseResults })(
  FinalOutcome
);
