import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import {
  CREATE_TRAINING,
  DELETE_TRAINING,
  SET_TRAINING,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_TRAININGS,
  UPDATE_TRAINING,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getTrainings = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
        const result = await call(
          'get',
          `trainings?${new URLSearchParams(query).toString()}`,
        )

      dispatch({
        type: SHOW_TRAININGS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getVolunteerTrainings = (volunteer, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
        const result = await call(
          'get',
          `volunteer-trainings/${volunteer}?${new URLSearchParams(
            query,
          ).toString()}`,
        )
     
      dispatch({
        type: SHOW_TRAININGS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getTraining = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
        const response = await call('get', `trainings/${id}`)
        const result = response.data

      dispatch({
        type: SET_TRAINING,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createTraining = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
        const response = await call('post', 'trainings', data)
        const result = response.data

      dispatch({
        type: CREATE_TRAINING,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Training has been created successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const updateTraining = (id, data) => {
  return async (dispatch) => {
    try {
        const response = await call('post', `trainings/${id}?_method=PUT`, data)
        const result = response.data

      dispatch({
        type: UPDATE_TRAINING,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Training has been updated successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const deleteTraining = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call('delete', `trainings/${id}`)

      dispatch({ type: DELETE_TRAINING, payload: id });
      NotificationManager.success("Training has been deleted successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
