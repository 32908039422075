import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export const generatePDF = (className, filename) => {
  let input = window.document.getElementsByClassName(className)[0];

  html2canvas(input).then((canvas) => {
    const img = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4"); // Set format to A4
    const padding = 10; // Adjust the padding value as needed

    // Calculate the width and height of the chart including padding
    const width = pdf.internal.pageSize.getWidth() - 2 * padding;
    const height = (canvas.height * width) / canvas.width;

    // Calculate the vertical position of the chart to remove unnecessary blank space
    const chartY = (pdf.internal.pageSize.getHeight() - height) / 2;

    pdf.addImage(img, "PNG", padding, chartY, width, height);
    pdf.save(filename);
  });
};
