import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { dones, smearResults } from "variables/options";
import {
  updateFUSputumExaminition,
  deleteFUSputumExaminition,
} from "store/actions";
import CustomAlert from "components/Alert/CustomAlert";
import { patientMiddleware, CREATE } from "utils/middleware";
import { fUMonths } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import SelectBox from "components/Selectbox";

const FUSputumExaminitionDetail = ({
  isOpen,
  toggle,
  auth,
  status,
  fUSputumExaminition,
  updateFUSputumExaminition,
  deleteFUSputumExaminition,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm();
  const { id } = useParams();
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const smearIsDone = watch("smear");

  useEffect(() => {
    const {
      month,
      smear,
      smear_date,
      smear_result,
      remark,
      to_send_date,
      send_date,
    } = fUSputumExaminition.fUSputumExaminition;
    setValue("smear", smear);
    setValue("month", month);
    setValue("smear_date", smear_date);
    setValue("smear_result", smear_result);
    setValue("remark", remark);
    setValue("patient_id", id);
    setValue("to_send_date", to_send_date);
    setValue("send_date", send_date);
  }, [setValue, fUSputumExaminition.fUSputumExaminition, id]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateFUSputumExaminition(
      fUSputumExaminition.fUSputumExaminition.id,
      {
        patient_id: id,
        month: data.month,
        smear: data.smear,
        smear_date: smearIsDone === "Done" ? data.smear_date : null,
        smear_result: smearIsDone === "Done" ? data.smear_result : null,
        remark: data.remark,
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteFUSputumExaminition(fUSputumExaminition.fUSputumExaminition.id);
          setAlert(null);
          toggle(false);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  return (
    <>
      {alert}
      <CustomModal
        isOpen={isOpen}
        title="Detail F/U Sputum Examinition"
        onClick={() => toggle(false)}
        style={{ maxWidth: "60%" }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <Row>
              <Col md={4}>
                <CustomDateInput
                  id="to_send_date"
                  label="To Send Date"
                  register={{
                    ...register("to_send_date", {
                      required: false,
                    }),
                  }}
                  placeholder="Select To Send Date"
                  errors={errors}
                  disabled={true}
                  isRequired={false}
                />
              </Col>
              <Col md={4}>
                <CustomDateInput
                  id="send_date"
                  label="Send Date"
                  register={{
                    ...register("send_date", {
                      required: false,
                    }),
                  }}
                  placeholder="Select Send Date"
                  errors={errors}
                  disabled={true}
                  isRequired={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <SelectBox
                  label="Select Month"
                  control={control}
                  name="month"
                  options={fUMonths}
                  rules={{ required: true }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <SelectBox
                  label="Select smear"
                  control={control}
                  name="smear"
                  options={dones}
                  rules={{ required: true }}
                />
              </Col>
              <Col md={4}>
                <CustomDateInput
                  id="smear_date"
                  label="Date"
                  register={{
                    ...register("smear_date", {
                      required:
                        getValues("smear") === "Done"
                          ? "Smear Date is required!"
                          : false,
                    }),
                  }}
                  placeholder="Select Smear Date"
                  errors={errors}
                  disabled={smearIsDone !== "Done"}
                  isRequired={smearIsDone === "Done"}
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="Select Smear Result"
                  control={control}
                  name="smear_result"
                  options={smearResults}
                  rules={{
                    required:
                      smearIsDone === "Done"
                        ? "Smear Result is required!"
                        : false,
                  }}
                  disabled={smearIsDone !== "Done"}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomTextArea
                  id="remark"
                  label="Remark"
                  register={{
                    ...register("remark"),
                  }}
                  placeholder="Remark"
                  errors={errors}
                />
              </Col>
            </Row>
          </div>
          {patientMiddleware(role, CREATE) && (
            <div className="modal-footer">
              <Button
                color="primary"
                size="sm"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading" : "Update"}
              </Button>
              <Button color="danger" size="sm" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          )}
        </Form>
      </CustomModal>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  fUSputumExaminition: store.fUSputumExaminition,
});

export default connect(mapStateToProps, {
  updateFUSputumExaminition,
  deleteFUSputumExaminition,
})(FUSputumExaminitionDetail);
