// import React, { useEffect, useState } from "react";
// import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
// import CustomTable from "components/Table/Table";
// import PatientHandoutCreate from "./PatientHandoutCreate";
// import { connect } from "react-redux";
// import { getPatientHandouts, getPatientHandout } from "store/actions";
// import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
// import PatientHandoutUpdate from "./PatientHandoutUpdate";
// import { handoutMiddleware, CREATE, UPDATE } from "utils/middleware";
// import BackBtn from "utils/backBtn";

// const PatientHandoutList = ({
//   status,
//   auth,
//   patientHandout,
//   getPatientHandouts,
//   getPatientHandout,
// }) => {
//   const { role } = auth.user;

//   const [isCreateOpen, setIsCreateOpen] = useState(false);
//   const [isUpdateOpen, setIsUpdateOpen] = useState(false);

//   useEffect(() => {
//     getPatientHandouts();
//   }, [getPatientHandouts]);

//   if (status.loading) {
//     return <FullScreenLoading />;
//   }

//   return (
//     <>
//       <Container className="mt-3" fluid>
//         <BackBtn />
//         <Card>
//           <CardHeader className="border-0">
//             <Row>
//               <Col xs="6">
//                 <h3 className="mb-0">Patient Handout</h3>
//               </Col>

//               <Col className="text-right" xs="6">
//                 {handoutMiddleware(role, CREATE) &&
//                   patientHandout.patientHandouts.length === 0 && (
//                     <Button
//                       size="sm"
//                       color="success"
//                       onClick={() => setIsCreateOpen(true)}
//                     >
//                       New
//                     </Button>
//                   )}

//               </Col>
//             </Row>
//           </CardHeader>

//           <CustomTable
//             header={
//               <tr>
//                 <th scope="col">File Link</th>
//                 {handoutMiddleware(role, UPDATE) && <th scope="col">Action</th>}
//               </tr>
//             }
//             body={patientHandout.patientHandouts.map((patientHandout) => (
//               <tr key={patientHandout.id}>
//                 <td>
//                   <a href={patientHandout.file} target="_black">
//                     Click Link To View Handout File
//                   </a>
//                 </td>
//                 {handoutMiddleware(role, UPDATE) && (
//                   <td>
//                     <Button
//                       size="sm"
//                       color="primary"
//                       onClick={async () => {
//                         await getPatientHandout(patientHandout.id);
//                         setIsUpdateOpen(true);
//                       }}
//                     >
//                       Update
//                     </Button>
//                   </td>
//                 )}
//               </tr>
//             ))}
//           />
//         </Card>
//       </Container>

//       <PatientHandoutUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
//       <PatientHandoutCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
//     </>
//   );
// };

// const mapStateToProps = (store) => ({
//   status: store.status,
//   auth: store.auth,
//   patientHandout: store.patientHandout,
// });

// export default connect(mapStateToProps, {
//   getPatientHandouts,
//   getPatientHandout,
// })(PatientHandoutList);

import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import CustomTable from "components/Table/Table";
import PatientHandoutCreate from "./PatientHandoutCreate";
import { connect } from "react-redux";
import {
  getPatientHandouts,
  getPatientHandout,
  deletePatientHandout,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import PatientHandoutUpdate from "./PatientHandoutUpdate";
import { handoutMiddleware, CREATE, UPDATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import { DELETE } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";

const PatientHandoutList = ({
  status,
  auth,
  patientHandout,
  deletePatientHandout,
  getPatientHandouts,
  getPatientHandout,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [editName, setEditName] = useState(null);

  useEffect(() => {
    getPatientHandouts();
  }, [getPatientHandouts]);

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deletePatientHandout(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };
  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Patient Handout</h3>
              </Col>
              <Col className="text-right" xs="6">
                {handoutMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Name</th>
                <th scope="col">File Link</th>
                <th scope="col">
                  {handoutMiddleware(role, UPDATE) && "Action"}
                </th>
              </tr>
            }
            body={patientHandout.patientHandouts.map(
              (PatientHandout, index) => (
                <tr key={PatientHandout.id}>
                  <td>{index + 1}</td>
                  <td>{PatientHandout.name}</td>
                  <td>
                    <a href={PatientHandout.file} target="_black">
                      Click Link To View Handout File
                    </a>
                  </td>

                  <td>
                    {/* {handoutMiddleware(role, UPDATE) && (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getPatientHandout(PatientHandout.id);
                          setIsUpdateOpen(true);
                          setEditName(PatientHandout.name)
                        }}
                      >
                        Update
                      </Button>
                    )} */}
                    {handoutMiddleware(role, DELETE) && (
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(PatientHandout.id)}
                      >
                        delete
                      </Button>
                    )}
                  </td>
                </tr>
              )
            )}
          />
        </Card>
      </Container>

      <PatientHandoutUpdate
        isOpen={isUpdateOpen}
        toggle={setIsUpdateOpen}
        editName={editName}
      />
      <PatientHandoutCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patientHandout: store.patientHandout,
});

export default connect(mapStateToProps, {
  getPatientHandouts,
  getPatientHandout,
  deletePatientHandout,
})(PatientHandoutList);
