import React from "react";
import CustomModal from "components/Modal/CustomModal";
import { Col, Container, Row, Table } from "reactstrap";
import { connect } from "react-redux";
import { DOTWorking } from "utils/DOTWorking";

const DOTWithAppDetails = ({ isOpen, toggle, DOT }) => {
  const {
    date,
    change_volunteer,
    DOT_records,
    work,
    work_status,
    long,
    minor_side_effects,
    major_side_effects,
    actions,
    video,
    video_url,
    sign,
    sign_url,
    remark,
    late_remark,
  } = DOT.DOT;

  return (
    <CustomModal
      isOpen={isOpen}
      title="VOT with volunteer Visit Date Detail (App)"
      onClick={() => toggle(false)}
      style={{ maxWidth: "60%" }}
    >
      <Container className="mt-3" fluid>
        <Row className="mb-3">
          <Col sm={6}>ရက်စွဲ</Col>
          <Col sm={6}>{date}</Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Table
              className="app-table align-items-center table-flush"
              responsive
            >
              <thead>
                <tr>
                  <th>ဆေးအမည်</th>
                  {/* <th>မီလီဂရမ်</th>
                  <th>ဆေးလုံးအရေအတွက်</th> */}
                </tr>
              </thead>
              <tbody>
                {DOT_records?.map((record) => (
                  <tr key={record.id}>
                    <td>{record.medicine_name}</td>
                    {/* <td>{record.milligram}</td>
                    <td>{record.quantity}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>လုပ်ဆောင်ပုံ</Col>
          <Col sm={6}>{work_status ? DOTWorking[work] : ""}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>လုပ်ဆောင်မှုအခြေအနေ</Col>
          <Col sm={6}>
            {new Date(date).getTime() <= new Date().getTime()
              ? work_status !== 1
                ? "မအောင်မြင်ပါ"
                : "အောင်မြင်ပါသည်"
              : "မသွားရသေးပါ"}
          </Col>
        </Row>
        {long && (
          <Row className="mb-3">
            <Col sm={6}>ကြာချိန်</Col>
            <Col sm={6}>{long}</Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col>သာမာန်ဘေးထွက်ဆိုးကျိုး</Col>
        </Row>
        <Row className="mb-3">
          <Col>{minor_side_effects}</Col>
        </Row>
        <Row className="mb-3">
          <Col>အရေးကြီးဘေးထွက်ဆိုးကျိုး</Col>
        </Row>
        <Row className="mb-3">
          <Col>{major_side_effects}</Col>
        </Row>
        <Row className="mb-3">
          <Col>လုပ်ဆောင်ချက်</Col>
        </Row>
        <Row className="mb-3">
          <Col>{actions}</Col>
        </Row>
        {video && (
          <Row className="mb-3">
            <Col sm={6}>Video</Col>
            <Col sm={6}>
              <a href={video_url} target="_black">
                Click Link To View
              </a>
            </Col>
          </Row>
        )}
        {sign && (
          <Row className="mb-3">
            <Col sm={6}>Sign</Col>
            <Col sm={6}>
              <img src={sign_url} alt="Sign" width={150} height={130} />
            </Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col sm={6}>မှတ်ချက်</Col>
          <Col sm={6}>{remark}</Col>
        </Row>
        {late_remark && (
          <Row className="mb-3">
            <Col sm={6}>နောက်ကျရသည့်အကြောင်းအရင်း</Col>
            <Col sm={6}>{late_remark}</Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col sm={6}>Volunteer Name</Col>
          <Col sm={6}>{change_volunteer?.volunteer?.name}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>Type</Col>
          <Col sm={6}>{change_volunteer?.type}</Col>
        </Row>
      </Container>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  DOT: store.DOT,
});

export default connect(mapStateToProps)(DOTWithAppDetails);
