import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import {
  SET_LOADING,
  SET_SUCCESS,
  VOLUNTEER_VISUALIZATION,
  PATIENT_VISUALIZATION,
  DOT_PATIENT_VISUALIZATION,
  ACTIVITY_VISUALIZATION,
  VOLUNTEER_REPORT_VISUALIZATION,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getVolunteerVisualization = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `volunteer-visualization?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: VOLUNTEER_VISUALIZATION,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
export const getPatientVisualization = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `patient-visualization?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: PATIENT_VISUALIZATION,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
export const getDotPatientVisualization = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `dot-patient-visualization?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: DOT_PATIENT_VISUALIZATION,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
export const getActivityVisualization = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `activity-visualization?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: ACTIVITY_VISUALIZATION,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getVolunteerReportVisualization = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `volunteer-report-viz?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: VOLUNTEER_REPORT_VISUALIZATION,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};