import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import { years } from "variables/options";
import { reportMonths } from "variables/options";
import { connect } from "react-redux";
import { getVolunteers, getTownships } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { isVolunteerSupervisor } from "utils/middleware";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { useHistory } from "react-router";
import { getVolunteerSupervisiorReport } from "store/actions";
import queryString from "query-string";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import styles from "./VolunteerReport.module.css";

const VolunteerSupervisiorReport = ({
  status,
  auth,
  township,
  getVolunteerSupervisiorReport,
  getTownships,
  report,
}) => {
  const { role } = auth.user;

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const router = useHistory();

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  // useEffect(() => {
  //   getVolunteers();
  // }, [getVolunteers, role]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  // const volunteers = volunteer.volunteers?.map((element) => ({
  //   value: element.id,
  //   label: element.name,
  // }));

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    getVolunteerSupervisiorReport(query);
  }, [getVolunteerSupervisiorReport, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    if (month) {
      console.log(month);
      query.month = month.value;
    } else {
      delete query["month"];
    }
    if (year) {
      query.year = year.value;
    } else {
      delete query["year"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setMonth("");
    // setYear("");
    // setSelectedVolunteer(null);
    // setSelectedTownship(null);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Volunteer Supervisor Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="btn btn-sm bg-primary text-white border-0"
                table="table-to-xls"
                filename="Volunteer Supervisior report"
                sheet="Volunteer Supervisior report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            {/* <Col md={3}>
              <Select
                className="mt-3"
                options={volunteers}
                value={selectedVolunteer}
                onChange={(value) => setSelectedVolunteer(value)}
                placeholder="Filter by volunteer..."
                isSearchable={true}
                isClearable={true}
              />
            </Col> */}
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col md={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col sm={2}>
              <Button
                size="sm"
                color="success"
                onClick={handleFilter}
                className="mt-3"
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <Table
            responsive
            className="align-items-center  table-flush mb-5"
            id="table-to-xls"
          >
            <thead className="bg-info text-white">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Indicator</th>
                <th></th>
                <th scope="col">Male</th>
                <th scope="col">Female</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody className="list">
              <tr>
                <td>1</td>
                <td>
                  <div className="d-flex flex-column">
                    No of Trained Volunteer
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ပြီးခဲ့သောလအတွင်း သင်တန်းရရှိခဲ့သော
                        စေတနာ့ဝန်ထမ်းအရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of volunteers who get trainings (can be filtered by township)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.training?.male}</td>
                <td>{report.volunteerSupReport?.training?.female}</td>
                <td>
                  {report.volunteerSupReport?.training?.male +
                    report.volunteerSupReport?.training?.female}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Reported Volunteer 
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (စေတနာ့ဝန်ထမ်း
                        စောင့်ရှောက်မှုပေးသူအရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of all volunteers (can be filtered by township)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.volunteer?.male}</td>
                <td>{report.volunteerSupReport?.volunteer?.female}</td>
                <td>
                  {report.volunteerSupReport?.volunteer?.male +
                    report.volunteerSupReport?.volunteer?.female}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Active Volunteer (active in informing about patients, activities)
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( လစဉ်အစီရင်ခံစာပေးပို့အစီရင်ခံသော စေတနာ့ဝန်ထမ်း
                        စောင့်ရှောက်မှုပေးသူအရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of active volunteer
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.ReportedVolunteer?.male}</td>
                <td>{report.volunteerSupReport?.ReportedVolunteer?.female}</td>
                <td>
                  {report.volunteerSupReport?.ReportedVolunteer?.male +
                    report.volunteerSupReport?.ReportedVolunteer?.female}
                </td>
              </tr>

              <tr>
                <td>4</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of HE sessions To TB household members
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (အတူနေမိသားစုအားကျန်းမာရေးပညာပေးသည့်အကြိမ်အရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of HE and TB-CI sessions
                    </span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerSupReport?.TBCTAndHE?.total}</td>
              </tr>

              <tr>
                <td>5</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Health education attendees in this township
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ပြီးခဲ့သောလအတွင်း
                        ပြည်သူလူထုအတွင်းကျန်းမာရေးလုပ်ငန်းများဆောင်ရွက်ရာတွင်
                        တက်ရောက်ခဲ့သူစုစုပေါင်း)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of HE attendees
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.HE?.male}</td>
                <td>{report.volunteerSupReport?.HE?.female}</td>
                <td>
                  {parseInt(report.volunteerSupReport?.HE?.male) +
                    parseInt(report.volunteerSupReport?.HE?.female)}
                </td>
              </tr>

              <tr>
                <td>6</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Presumptive TB cases referred by volunteers for
                    examination (exclude referred by Contact Investigation)
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ပြည်သူလူထုအတွင်းမှ ညွှန်းပို့သည့်
                        တီဘီသံသယလူနာအရေအတွက်(အတူနေ/မိသားစုမပါဝင်ပါ))
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of referred patients from community and HE (referral volunteer should be included , referral date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.referPatient?.male}</td>
                <td>{report.volunteerSupReport?.referPatient?.female}</td>
                <td>
                  {report.volunteerSupReport?.referPatient?.male +
                    report.volunteerSupReport?.referPatient?.female}
                </td>
              </tr>

              <tr>
                <td>7</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Total TB Patients referred by Volunteers (exclude TB
                    cases referred by contact Investigation)
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( ညွှန်းပို့သူများအနက်မှ
                        တီဘီဆေးစတင်ကုသသူအရေအတွက်(အတူနေ/မိသားစုမပါဝင်ပါ))
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of registered patients from community and HE (referral volunteer should be included , treatment registration date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.registeredPatient?.male}</td>
                <td>{report.volunteerSupReport?.registeredPatient?.female}</td>
                <td>
                  {report.volunteerSupReport?.registeredPatient?.male +
                    report.volunteerSupReport?.registeredPatient?.female}
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Presumptive TB cases referred by contact Investigation
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (တီဘီဖြစ်နိုင်သည့်လက္ခဏာရှိ၍
                        တီဘီရောဂါရှိ/မရှိစစ်ဆေးရန်ညွှန်းပို့ပေးသည့်
                        လူနာ၏မိသားစုဝင်အရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of referred patients from TB CI (referral volunteer should be included , referral date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.referTBCTPatient?.male}</td>
                <td>{report.volunteerSupReport?.referTBCTPatient?.female}</td>
                <td>
                  {report.volunteerSupReport?.referTBCTPatient?.male +
                    report.volunteerSupReport?.referTBCTPatient?.female}
                </td>
              </tr>

              <tr>
                <td>9</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Presumptive TB cases diagnosed as TB referred by
                    contact Investigation
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (မိသားစုဝင်ထဲမှ တီဘီဆေးစတင်ကုသမှုခံယူရသည့်
                        တီဘီလူနာအရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of registered TBCI patients (referral volunteer should be included , treatment registration date is used)
                    </span>
                  </div>
                </td>
                {/* <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerSupReport?.TBCTPatient?.total}</td> */}
                 <td>{report.volunteerSupReport?.TBCTPatient?.male}</td>
                <td>{report.volunteerSupReport?.TBCTPatient?.female}</td>
                <td>
                  {report.volunteerSupReport?.TBCTPatient?.male +
                    report.volunteerSupReport?.TBCTPatient?.female}
                </td>
              </tr>

              <tr>
                <td>10</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of TB patients on DOTS provided by Volunteers
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        ( စေတနာ့ဝန်ထမ်းကျန်းမာရေးစောင့်ရှောက်မှုပေးသူများက
                        တီဘီဆေးစတင်တိုက်ကျွေးသူ တီဘီလူနာအရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of DOT patients (DOT start date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.DotPatient?.male}</td>
                <td>{report.volunteerSupReport?.DotPatient?.female}</td>
                <td>
                  {report.volunteerSupReport?.DotPatient?.male +
                    report.volunteerSupReport?.DotPatient?.female}
                </td>
              </tr>

              <tr>
                <td>11</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of TB patients who completed DOTS
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (ဆေးကုသမှုပြီးစီးသည့် တီဘီလူနာအရေအတွက် )
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of DOT finished patients  (DOT end date is used)
                    </span>
                  </div>
                </td>
                <td>{report.volunteerSupReport?.DotFinishedPatient?.male}</td>
                <td>{report.volunteerSupReport?.DotFinishedPatient?.female}</td>
                <td>
                  {report.volunteerSupReport?.DotFinishedPatient?.male +
                    report.volunteerSupReport?.DotFinishedPatient?.female}
                </td>
              </tr>

              <tr>
                <td>12</td>
                <td>
                  <div className="d-flex flex-column">
                    No Of Supervision visited by Supervisor
                    {isVolunteerSupervisor(role) && (
                      <span className="mt-1">
                        (ကြီးကြပ်သူမှ ကွင်းဆင်းကြီးကြပ်သည့်အကြိမ်အရေအတွက်)
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.tooltip}>
                    <BsFillPatchQuestionFill size={20} />
                    <span className={styles.tooltiptext}>
                      Count of the number of people from SuperVision visit
                    </span>
                  </div>
                </td>
                <td className="bg-dark"></td>
                <td className="bg-dark"></td>
                <td>{report.volunteerSupReport?.superVisionvisit?.total}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  report: store.report,
});

export default connect(mapStateToProps, {
  getVolunteers,
  getTownships,
  getVolunteerSupervisiorReport,
})(VolunteerSupervisiorReport);
