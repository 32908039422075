export const SET_LOADING = "SET_LOADING";
export const SET_SUCCESS = "SET_SUCCESS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SHOW_ACCOUNTS = "SHOW_ACCOUNTS";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

export const SHOW_REGIONS = "SHOW_REGIONS";
export const SET_REGION = "SET_REGION";
export const CREATE_REGION = "CREATE_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";

export const SHOW_DISTRICTS = "SHOW_DISTRICTS";
export const SET_DISTRICT = "SET_DISTRICT";
export const CREATE_DISTRICT = "CREATE_DISTRICT";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";

export const SHOW_TOWNSHIPS = "SHOW_TOWNSHIPS";
export const SET_TOWNSHIP = "SET_TOWNSHIP";
export const CREATE_TOWNSHIP = "CREATE_TOWNSHIP";
export const UPDATE_TOWNSHIP = "UPDATE_TOWNSHIP";
export const DELETE_TOWNSHIP = "DELETE_TOWNSHIP";

export const SHOW_VOLUNTEERS = "SHOW_VOLUNTEERS";
export const SET_VOLUNTEER = "SET_VOLUNTEER";
export const CREATE_VOLUNTEER = "CREATE_VOLUNTEER";
export const UPDATE_VOLUNTEER = "UPDATE_VOLUNTEER";
export const DELETE_VOLUNTEER = "DELETE_VOLUNTEER";

export const SHOW_TRAININGS = "SHOW_TRAININGS";
export const SET_TRAINING = "SET_TRAINING";
export const CREATE_TRAINING = "CREATE_TRAINING";
export const UPDATE_TRAINING = "UPDATE_TRAINING";
export const DELETE_TRAINING = "DELETE_TRAINING";

export const SHOW_PATIENTS = "SHOW_PATIENTS";
export const SET_PATIENT = "SET_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";

export const SHOW_PATIENT_MEDICINES = "SHOW_PATIENT_MEDICINES";
export const SET_PATIENT_MEDICINE = "SET_PATIENT_MEDICINE";
export const CREATE_PATIENT_MEDICINE = "CREATE_PATIENT_MEDICINE";
export const UPDATE_PATIENT_MEDICINE = "UPDATE_PATIENT_MEDICINE";
export const DELETE_PATIENT_MEDICINE = "DELETE_PATIENT_MEDICINE";

export const SHOW_FU_SPUTUM_EXAMINITIONS = "SHOW_FU_SPUTUM_EXAMINITIONS";
export const SET_FU_SPUTUM_EXAMINITION = "SET_FU_SPUTUM_EXAMINITION";
export const CREATE_FU_SPUTUM_EXAMINITION = "CREATE_FU_SPUTUM_EXAMINITION";
export const UPDATE_FU_SPUTUM_EXAMINITION = "UPDATE_FU_SPUTUM_EXAMINITION";
export const DELETE_FU_SPUTUM_EXAMINITION = "DELETE_FU_SPUTUM_EXAMINITION";

export const SHOW_HES = "SHOW_HES";
export const SET_HE = "SET_HE";
export const CREATE_HE = "CREATE_HE";
export const DELETE_HE = "DELETE_HE";

export const SHOW_INFECTION_CONTROL_CHECKS = "SHOW_INFECTION_CONTROL_CHECKS";
export const SET_INFECTION_CONTROL_CHECK = "SET_INFECTION_CONTROL_CHECK";
export const DELETE_INFECTION_CONTROL_CHECK = "DELETE_INFECTION_CONTROL_CHECK";

export const SHOW_CONTACT_TRACIES = "SHOW_CONTACT_TRACIES";
export const SET_CONTACT_TRACY = "SET_CONTACT_TRACY";

export const SHOW_VOLUNTEER_HANDOUTS = "SHOW_VOLUNTEER_HANDOUTS";
export const SET_VOLUNTEER_HANDOUT = "SET_VOLUNTEER_HANDOUT";
export const CREATE_VOLUNTEER_HANDOUT = "CREATE_VOLUNTEER_HANDOUT";
export const UPDATE_VOLUNTEER_HANDOUT = "UPDATE_VOLUNTEER_HANDOUT";
export const DELETE_VOLUNTEER_HANDOUT = "DELETE_VOLUNTEER_HANDOUT";

export const SHOW_PATIENT_HANDOUTS = "SHOW_PATIENT_HANDOUTS";
export const SET_PATIENT_HANDOUT = "SET_PATIENT_HANDOUT";
export const CREATE_PATIENT_HANDOUT = "CREATE_PATIENT_HANDOUT";
export const UPDATE_PATIENT_HANDOUT = "UPDATE_PATIENT_HANDOUT";
export const DELETE_PATIENT_HANDOUT = "DELETE_PATIENT_HANDOUT";

export const SHOW_TAKE_MEDICINES = "SHOW_TAKE_MEDICINES";
export const SET_TAKE_MEDICINE = "SET_TAKE_MEDICINE";

export const SHOW_DOTS = "SHOW_DOTS";
export const SET_DOT = "SET_DOT";
export const CHANGE_STATUS_NOAPP = "CHANGE_STATUS_NOAPP";
export const CREATE_DOTS = "CREATE_DOTS";
export const CREATE_DOT = "CREATE_DOT";
export const DELETE_DOT = "DELETE_DOT";
export const UPDATE_DOT_DATE = "UPDATE_DOT_DATE";

export const SHOW_CHANGE_VOLUNTEERS = "SHOW_CHANGE_VOLUNTEERS";
export const SET_CHANGE_VOLUNTEER = "SET_CHANGE_VOLUNTEER";
export const CREATE_CHANGE_VOLUNTEER = "CREATE_CHANGE_VOLUNTEER";
export const DELETE_CHANGE_VOLUNTEER = "DELETE_CHANGE_VOLUNTEER";

export const CREATE_SYMPTOM = "CREATE_SYMPTOM";
export const DELETE_SYMPTOM = "DELETE_SYMPTOM";
export const SET_SYMPTOM = "SET_SYMPTOM";
export const SHOW_SYMPTOMS = "SHOW_SYMPTOMS";
export const UPDATE_SYMPTOM = "UPDATE_SYMPTOM";

export const CREATE_TBCI = "CREATE_TBCI";
export const DELETE_TBCI = "DELETE_TBCI";
export const SET_TBCI = "SET_TBCI";
export const SHOW_TBCIS = "SHOW_TBCIS";
export const UPDATE_TBCI = "UPDATE_TBCI";

export const CREATE_MISS_DOSE = "CREATE_MISS_DOSE";
export const DELETE_MISS_DOSE = "DELETE_MISS_DOSE";
export const SET_MISS_DOSE = "SET_MISS_DOSE";
export const SHOW_MISS_DOSES = "SHOW_MISS_DOSES";
export const UPDATE_MISS_DOSE = "UPDATE_MISS_DOSE";

export const CREATE_SUPERVISION_VISIT = "CREATE_SUPERVISION_VISIT";
export const DELETE_SUPERVISION_VISIT = "DELETE_SUPERVISION_VISIT";
export const SET_SUPERVISION_VISIT = "SET_SUPERVISION_VISIT";
export const SHOW_SUPERVISION_VISITS = "SHOW_SUPERVISION_VISITS";
export const UPDATE_SUPERVISION_VISIT = "UPDATE_SUPERVISION_VISIT";

export const CREATE_RAWDATA = "CREATE_RAWDATA";
export const DELETE_RAWDATA = "DELETE_RAWDATA";
export const SET_RAWDATA = "SET_RAWDATA";
export const SHOW_RAWDATAS = "SHOW_RAWDATAS";
export const UPDATE_RAWDATA = "UPDATE_RAWDATA";

export const CREATE_ACCEPTED_PATIENT = "CREATE_ACCEPTED_PATIENT";
export const DELETE_ACCEPTED_PATIENT = "DELETE_ACCEPTED_PATIENT";
export const SET_ACCEPTED_PATIENT = "SET_ACCEPTED_PATIENT";
export const SHOW_ACCEPTED_PATIENTS = "SHOW_ACCEPTED_PATIENTS";
export const UPDATE_ACCEPTED_PATIENT = "UPDATE_ACCEPTED_PATIENT";


export const CREATE_DOTPATIENT_WITHVOLUNTEER =
  "CREATE_DOTPATIENT_WITHVOLUNTEER";
export const DELETE_DOTPATIENT_WITHVOLUNTEER =
  "DELETE_DOTPATIENT_WITHVOLUNTEER";
export const SET_DOTPATIENT_WITHVOLUNTEER = "SET_DOTPATIENT_WITHVOLUNTEER";
export const SHOW_DOTPATIENT_WITHVOLUNTEERS = "SHOW_DOTPATIENT_WITHVOLUNTEERS";
export const UPDATE_DOTPATIENT_WITHVOLUNTEER =
  "UPDATE_DOTPATIENT_WITHVOLUNTEER";

export const CREATE_MEDICINE = "CREATE_MEDICINE";
export const DELETE_MEDICINE = "DELETE_MEDICINE";
export const SET_MEDICINE = "SET_MEDICINE";
export const SHOW_MEDICINES = "SHOW_MEDICINES";
export const UPDATE_MEDICINE = "UPDATE_MEDICINE";

export const CREATE_PATIENT_SYMPTOM = "CREATE_PATIENT_SYMPTOM";
export const DELETE_PATIENT_SYMPTOM = "DELETE_PATIENT_SYMPTOM";
export const SET_PATIENT_SYMPTOM = "SET_PATIENT_SYMPTOM";
export const SHOW_PATIENT_SYMPTOMS = "SHOW_PATIENT_SYMPTOMS";
export const UPDATE_PATIENT_SYMPTOM = "UPDATE_PATIENT_SYMPTOM";

export const VOLUNTEER_REPORT = 'VOLUNTEER_REPORT'
export const VOT_REPORT = 'VOT_REPORT'
export const VOLUNTEER_SUPERVISIOR_REPORT = 'VOLUNTEER_SUPERVISIOR_REPORT'
export const CBTBC_REPORT = 'CBTBC_REPORT'

export const DELETE_DOT_DATES = 'DELETE_DOT_DATES'

export const VOLUNTEER_VISUALIZATION = "VOLUNTEER_VISUALIZATION";
export const PATIENT_VISUALIZATION = "PATIENT_VISUALIZATION";
export const DOT_PATIENT_VISUALIZATION = "DOT_PATIENT_VISUALIZATION";
export const ACTIVITY_VISUALIZATION = "ACTIVITY_VISUALIZATION";
export const VOLUNTEER_REPORT_VISUALIZATION = "VOLUNTEER_REPORT_VISUALIZATION";