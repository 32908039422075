import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import {useEffect } from 'react'
import { connect } from 'react-redux'
import { createSymptom } from 'store/actions'

const SymptomCreate = ({ isOpen, toggle,status,createSymptom }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = async (data) => {
    await createSymptom({
      id: data.eng_name,
      eng_name: data.eng_name,
      myan_name: data.myan_name,
    })
  }

  useEffect(() => {
    if (status.success) {
      reset()
      toggle(false);
    }
  }, [status.success, reset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Symptom"
      onClick={() => {
        toggle(false)
        reset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="eng_name"
            label="Eng Name"
            register={{
              ...register('eng_name', {
                required: 'Eng Name is required!',
              }),
            }}
            placeholder="Enter Eng Name"
            errors={errors}
          />
          <CustomInput
            id="myan_name"
            label="Myan Name"
            register={{
              ...register('myan_name', {
                required: 'Myan Name is required!',
              }),
            }}
            placeholder="Enter Myan Name"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
})

export default connect(mapStateToProps, { createSymptom })(SymptomCreate)