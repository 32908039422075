import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { fnacResult } from "variables/options";
import { updatePatient } from "store/actions";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { doneOrNotDone } from "variables/options";

const FNAC = ({ patient, updatePatient, auth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const isDone = watch("FNAC_done_or_not_done");

  useEffect(() => {
    if (patient.patient) {
      const { FNAC_done_or_not_done, FNAC_examination_date, FNAC_result } =
        patient.patient;

      setValue("FNAC_done_or_not_done", FNAC_done_or_not_done);
      setValue("FNAC_examination_date", FNAC_examination_date);
      setValue("FNAC_result", FNAC_result);
    }
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);

    await updatePatient(patient.patient.id, {
      FNAC_done_or_not_done: data.FNAC_done_or_not_done,
      FNAC_examination_date: isDone === "1" ? data.FNAC_examination_date : null,
      FNAC_result: isDone === "1" ? data.FNAC_result : null,
    },
    'FNAC',
    'FNAC');

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="FNAC done"
              control={control}
              name="FNAC_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          {isDone === "1" && (
            <>
              <Col md={4}>
                <CustomDateInput
                  id="FNAC_examination_date"
                  label=" Date of FNAC"
                  register={{
                    ...register("FNAC_examination_date", {
                      required: "FNAC date is required!",
                    }),
                  }}
                  placeholder="FNAC date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="Result of FNAC"
                  control={control}
                  name="FNAC_result"
                  options={fnacResult}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(FNAC);
