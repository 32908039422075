// import React, { useEffect, useState } from 'react'
// import { useForm } from 'react-hook-form'
// import { Button, Form } from 'reactstrap'
// import CustomInput from 'components/Inputs/CustomInput'
// import CustomModal from 'components/Modal/CustomModal'
// import { connect } from 'react-redux'
// import { updatePatientHandout } from 'store/actions'

// const PatientHandoutUpdate = ({
//   isOpen,
//   toggle,
//   status,
//   patientHandout,
//   updatePatientHandout,
// }) => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm()

//   const [loading, setLoading] = useState(false)

//   const onSubmit = async (data) => {
//     setLoading(true)
//     const formData = new FormData()
//     formData.append('file', data.file[0])
//     await updatePatientHandout(patientHandout.patientHandout.id, formData)
//     setLoading(false)
//   }

//   useEffect(() => {
//     if (status.success) {
//       toggle(false)
//     }
//   }, [status.success, toggle])

//   return (
//     <CustomModal
//       isOpen={isOpen}
//       title="Update Patient Handout"
//       onClick={() => toggle(false)}
//     >
//       <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
//         <div className="modal-body">
//           <CustomInput
//             id="file"
//             label="file"
//             register={{
//               ...register('file', {
//                 required: 'File is required!',
//               }),
//             }}
//             placeholder="Enter file"
//             errors={errors}
//             type="file"
//             accept="application/pdf"
//           />
//         </div>
//         <div className="modal-footer">
//           <Button color="primary" size="sm" type="submit" disabled={loading}>
//             {loading ? 'Loading' : 'Update'}
//           </Button>
//         </div>
//       </Form>
//     </CustomModal>
//   )
// }

// const mapStateToProps = (store) => ({
//   status: store.status,
//   patientHandout: store.patientHandout,
// })

// export default connect(mapStateToProps, { updatePatientHandout })(
//   PatientHandoutUpdate,
// )


import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updatePatientHandout } from "store/actions";

const PatientHandoutUpdate = ({
  isOpen,
  toggle,
  status,
  patientHandout,
  updatePatientHandout,
  editName,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("name", data.name);
    await updatePatientHandout(
      patientHandout.patientHandout.id,
      formData
    );
    setLoading(false);
  };

  useEffect(() => {
    setValue("name", editName);
  }, [editName]);

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Patient Handout"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
          <CustomInput
            id="file"
            label="file"
            register={{
              ...register("file", {
                required: "File is required!",
              }),
            }}
            placeholder="Enter file"
            errors={errors}
            type="file"
            accept="application/pdf"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patientHandout: store.patientHandout,
});

export default connect(mapStateToProps, { updatePatientHandout })(
  PatientHandoutUpdate
);
