import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { updateMedicine } from 'store/actions'

const MedicineUpdate = ({
  isOpen,
  toggle,
  status,
  medicine,
  updateMedicine,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { name, acronym } = medicine.medicine;
    setValue("name", name);
    setValue("acronym", acronym);
  }, [setValue, medicine.medicine]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateMedicine(medicine.medicine.id, data)
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Medicine"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="No. of dose"
            register={{
              ...register("name", {
                required: "No. of dose is required!",
              }),
            }}
            placeholder="Enter No. of dose"
            errors={errors}
          />
          <CustomInput
            id="acronym"
            label="TB drug"
            register={{
              ...register("acronym", {
                required: "TB drug is required!",
              }),
            }}
            placeholder="Enter TB drug "
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  medicine: store.medicine,
})

export default connect(mapStateToProps, { updateMedicine })(MedicineUpdate)
