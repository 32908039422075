import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_MISS_DOSE,
  DELETE_MISS_DOSE,
  SET_LOADING,
  SET_MISS_DOSE,
  SET_SUCCESS,
  SHOW_MISS_DOSES,
  UPDATE_MISS_DOSE,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getMissDoses = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        "get",
        `miss_doses?${new URLSearchParams(query).toString()}`
      );
  
      dispatch({
        type: SHOW_MISS_DOSES,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getMissDose = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call("get", `miss_doses/${id}`);
      const result = response.data


      dispatch({
        type: SET_MISS_DOSE,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createMissDose = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call("post", "miss_doses", data);
      const result = response.data

      dispatch({
        type: CREATE_MISS_DOSE,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('MissDose has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateMissDose = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call("post", `miss_doses/${id}?_method=PUT`, data);
      const result = response.data
     
      dispatch({
        type: UPDATE_MISS_DOSE,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Miss Dose has been updated successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}
export const updateMissDoseResults = (id, data, result_name) => {
  return async (dispatch) => {
    try {
      const response = await call("post", `miss-doses-${result_name}/${id}?_method=PUT`, data);
      const result = response.data
     
      dispatch({
        type: UPDATE_MISS_DOSE,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Miss Dose Outcome has been updated successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}
export const deleteMissDose = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call("delete", `miss_doses/${id}`);

      dispatch({ type: DELETE_MISS_DOSE, payload: id })
      NotificationManager.success('MissDose has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
