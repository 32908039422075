import React, { useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import "../patient/index.scss";
import classnames from "classnames";
import BackBtn from "utils/backBtn";
import { useParams } from "react-router";
import { connect } from "react-redux";
import PresumptiveInfo from "../patient/detailComponents/PresumptiveInfo";
import TBConclusion from "../patient/detailComponents/TBConclusion";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import DotConclusion from "../patient/detailComponents/DotConclusion";
import VDotConclusion from "../patient/detailComponents/VDotConclusion";
import { useHistory } from "react-router-dom";
import { getPatient } from "store/actions";

function DotPatientDetail({ auth,getPatient }) {
  const [activeTab, setActiveTab] = React.useState("1");
  const { id } = useParams();
  const { role } = auth.user;
  const router = useHistory();
  useEffect(() => {
    // id &&
    getPatient(id);
  }, []);

  return (
    <div className="custom-tab-container mt-3">
      <BackBtn />
      <Card className="my-3">
        <CardHeader className="p-4">
          <Nav className="d-flex flex-wrap">
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white",
                  {
                    "bg-danger": activeTab === "1",
                  }
                )}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Presumptive Information
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white",
                  {
                    "bg-danger": activeTab === "2",
                  }
                )}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                TB Conclusion
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white"
                )}
                onClick={() => {
                  router.push(patientMiddleware(role, UPDATE)
                    ? `/admin/change-volunteer-create/${id}`
                    : `/admin/change-volunteer-list/${id}`)
                }}
              >
                VOT with/without volunteer
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white",
                  {
                    "bg-danger": activeTab === "3",
                  }
                )}
                onClick={() => {
                  setActiveTab("3");
                }}
              >
                DOT Conclusion
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white",
                  {
                    "bg-danger": activeTab === "4",
                  }
                )}
                onClick={() => {
                  setActiveTab("4");
                }}
              >
                VOT Conclusion
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white"
                )}
                onClick={() => {
                  router.push(`/admin/dot-visit-date-app/${id}`);
                }}
              >
                VOT Visit Date (App)
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white"
                )}
                onClick={() => {
                  router.push(`/admin/dot-visit-date-noapp/${id}`);
                }}
              >
                VOT Visit Date (No App)
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white"
                )}
                onClick={() => {
                  router.push(`/admin/vot-calendar/${id}`);
                }}
              >
                VOT Calendar
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white"
                )}
                onClick={() => {
                  router.push(`/admin/patient-medicine-list/${id}`);
                }}
              >
                Medicines
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  "tab-background-color border-0 text-white"
                )}
                onClick={() => {
                  router.push(`/admin/FU-sputum-examinition-list/${id}`);
                }}
              >
                F/U Sputum Examinitions
              </Button>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="px-5">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PresumptiveInfo setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="2">
              <TBConclusion setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="3">
              <DotConclusion setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="4">
              <VDotConclusion setActiveTab={setActiveTab} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}
const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, {getPatient})(DotPatientDetail);
