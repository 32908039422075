import { serverErrorMessage } from "utils/messages";
import { setAccessToken, call } from "../../services/api";
import { SET_CURRENT_USER, SET_LOADING, SET_SUCCESS } from "../type";
import { NotificationManager } from "react-notifications";

export const logout = () => {
  return async (dispatch) => {
    await call("get", "logout");
    setAccessToken(null);
    dispatch({
      type: SET_CURRENT_USER,
      payload: {},
    });
  };
};

export const autoLogin = () => {
  return async (dispatch) => {
    try {
      const response = await call('get', 'user')
      const { name, email, role, township } = response.data

      dispatch({
        type: SET_CURRENT_USER,
        payload: { name, email, role, township },
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
  };
};

export const login = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("post", "io-login", data);
      const { name, email, role, township, access_token } = response.data;

      dispatch({
        type: SET_CURRENT_USER,
        payload: { name, email, role, township },
      });
      setAccessToken(access_token);
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const changePassword = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    });
    try {
      const response = await call("post", "io-change-password", data);

      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(response.data.message);
      NotificationManager.success("success");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
