import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { dones, smearResults } from "variables/options";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { createFUSputumExaminition } from "store/actions";
import { fUMonths } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import SelectBox from "components/Selectbox";

const FUSputumExaminitionCreate = ({
  isOpen,
  toggle,
  status,
  createFUSputumExaminition,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const smearIsDone = watch("smear");
  const onSubmit = async (data) => {
    setLoading(true);
    await createFUSputumExaminition({
      patient_id: id,
      month: data.month,
      smear: data.smear,
      smear_date: smearIsDone === 'Done' ? data.smear_date : null,
      smear_result: smearIsDone === 'Done' ? data.smear_result : null,
      remark: data.remark,
    });
    setLoading(false);
  };

  const formReset = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create F/U Sputum Examinition"
      onClick={() => {
        toggle(false);
        formReset();
      }}
      style={{ maxWidth: "60%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <SelectBox
                label="Select Month"
                control={control}
                name="month"
                options={fUMonths}
                rules={{ required: true }}
              />
            </Col>
            <Col md={6}>
              <SelectBox
                label="Select smear"
                control={control}
                name="smear"
                options={dones}
                rules={{ required: true }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomDateInput
                id="smear_date"
                label="Date"
                register={{
                  ...register("smear_date", {
                    required:
                      smearIsDone === "Done"
                        ? "Smear Date is required!"
                        : false,
                  }),
                }}
                placeholder="Select Smear Date"
                errors={errors}
                disabled={smearIsDone !== "Done"}
                isRequired={smearIsDone === "Done"}
              />
            </Col>
            <Col md={6}>
              <SelectBox
                label="Select Smear Result"
                control={control}
                name="smear_result"
                options={smearResults}
                rules={{
                  required:
                    smearIsDone === "Done"
                      ? "Smear Result is required!"
                      : false,
                }}
                disabled={smearIsDone !== "Done"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomTextArea
                id="remark"
                label="Remark"
                register={{
                  ...register("remark"),
                }}
                placeholder="Remark"
                errors={errors}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
});

export default connect(mapStateToProps, {
  createFUSputumExaminition,
})(FUSputumExaminitionCreate);
