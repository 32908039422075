
import moment from "moment";
import {
  CHANGE_STATUS_NOAPP,
  CREATE_DOTS,
  DELETE_DOT,
  SET_DOT,
  SHOW_DOTS,
  UPDATE_DOT_DATE,
  DELETE_DOT_DATES,
  CREATE_DOT
} from "../type";

const initialState = {
  DOTs: [],
  DOT: {},
  total: 0,
  success_count: 0,
  pending_count: 0,
  fail_count: 0,
};

const DOT = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DOTS:
      return {
        ...state,
        DOTs: action.payload.data,
        total: action.payload.total,
        success_count: action.payload.success_count || 0,
        pending_count: action.payload.pending_count || 0,
        fail_count: action.payload.fail_count || 0,
      };
    case CREATE_DOTS:
      return {
        ...state,
        DOTs: [...state.DOTs, ...action.payload],
        total: state.total + action.payload.length,
      };
      case CREATE_DOT:
      return {
        ...state,
        DOTs: [...state.DOTs, action.payload],
        total: state.total + action.payload.length,
      };
    case SET_DOT:
      return {
        ...state,
        DOT: action.payload,
      };
    case CHANGE_STATUS_NOAPP:
      let index = state.DOTs.findIndex((DOT) => DOT.id === action.payload.id);
      state.DOTs[index] = action.payload;
      if (state.DOT.id === action.payload.id) {
        state.DOT = action.payload;
      }
      return {
        ...state,
      };
    case UPDATE_DOT_DATE:
      let i = state.DOTs.findIndex((DOT) => DOT.id === action.payload.id);
      state.DOTs[i] = action.payload;
      if (state.DOT.id === action.payload.id) {
        state.DOT = action.payload;
      }
      return {
        ...state,
      };
    case DELETE_DOT:
      return {
        ...state,
        DOTs: state.DOTs.filter((DOT) => DOT.id !== action.payload),
        total: state.total - 1,
      };
      case DELETE_DOT_DATES:
        const currentDate = moment();
      return {
        ...state,
        DOTs: state.DOTs.filter((DOT) => !currentDate.isBefore(DOT.date, 'day')),
      };
    default:
      return state;
  }
};

export default DOT;
