import {  Line } from "react-chartjs-2";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { lineChartConfig } from "utils/chartConfig";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generatePDF } from "utils/generatePDF";

export default function LineChart({data, total, title, uniqueChartClassName}) {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col xs="6">
              <h3>{title}</h3>
            </Col>

            <Col className="text-right" xs="6">
              <span className="mx-3">Total - {total}</span>
              <Button
                size="sm"
                color="success"
                onClick={() =>
                  generatePDF(
                    `${uniqueChartClassName}`,
                    `${uniqueChartClassName}.pdf`
                  )
                }
              >
                Export
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className={`${uniqueChartClassName}`}>
            <Line
              data={data}
              options={lineChartConfig}
              plugins={[ChartDataLabels]}
            />
          </div>
        </CardBody>
      </Card>
    );
}