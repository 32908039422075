import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import { positiveOrNegative } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import {  updatePatient } from "store/actions";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { doneOrNotDone } from "variables/options";

const LamTest = ({  updatePatient, patient, auth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const isDone = watch("lamtest_done_or_not_done");
  useEffect(() => {
    if (patient.patient) {
      const {
        lamtest_done_or_not_done,
        lamtest_examination_date,
        lamtest_result,
      } = patient.patient;
      setValue("lamtest_done_or_not_done", lamtest_done_or_not_done);
      setValue("lamtest_examination_date", lamtest_examination_date);
      setValue("lamtest_result", lamtest_result);
    }
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);

    await updatePatient(patient.patient.id, {
      lamtest_done_or_not_done: data.lamtest_done_or_not_done,
      lamtest_examination_date:
        isDone === "1" ? data.lamtest_examination_date : null,
      lamtest_result: isDone === "1" ? data.lamtest_result : null,
    },
    'lam-test',
    'Lam Test');

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="LAM Test done"
              control={control}
              name="lamtest_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          {isDone === "1" && (
            <>
              <Col md={4}>
                <CustomDateInput
                  id="lamtest_date"
                  label=" Date of Lam Test"
                  register={{
                    ...register("lamtest_examination_date", {
                      required: "Lam Test date is required!",
                    }),
                  }}
                  placeholder="Lam Test date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="Result of Lam Test"
                  control={control}
                  name="lamtest_result"
                  options={positiveOrNegative}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  done_or_not_done: store.done_or_not_done,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(LamTest);
