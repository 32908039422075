import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import { connect } from "react-redux";
import { sexs } from "variables/options";
import SelectBox from "components/Selectbox";
import CustomTextArea from "components/Inputs/CustomTextArea";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { updateMissDose } from "store/actions";
import { activitiesMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";

const PatientInfo = ({ status, volunteer, missDose, updateMissDose, auth }) => {
  const {
    register,
    formState: { errors },
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const {
      registration_date,
      volunteer,
      tb_patient_code,
      name,
      address,
      age,
      sex,
      phone,
    } = missDose.missDose;
    setValue("registration_date", registration_date);
    setValue("volunteer_id", volunteer?.id);
    setValue("tb_patient_code", tb_patient_code);
    setValue("name", name);
    setValue("address", address);
    setValue("age", age);
    setValue("sex", sex);
    setValue("phone", phone);
  }, [missDose.missDose, setValue]);

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateMissDose(missDose.missDose?.id, data);
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body d-flex flex-wrap">
        <Col md={6}>
          <CustomDateInput
            id="registration_date"
            label="Registration date"
            register={{
              ...register("registration_date"),
            }}
            errors={errors}
       
          />
        </Col>
        <Col md={6}>
          <SelectBox
            label="Volunteer"
            control={control}
            name="volunteer_id"
            options={volunteers}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            errors={errors}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            id="tb_patient_code"
            label="TB Code"
            register={{
              ...register("tb_patient_code", {
                required: "TB Code is required!",
              }),
            }}
            placeholder="TB Code"
            errors={errors}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            id="age"
            label="Age"
            register={{
              ...register("age", {
                required: "Age is required!",
              }),
            }}
            errors={errors}
            type="number"
            max="99"
          />
        </Col>
        <Col md={6}>
          <SelectBox
            label="Gender"
            control={control}
            name="sex"
            options={sexs}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            id="phone"
            label="Phone Number"
            type="number"
            register={{
              ...register("phone", {
                required: "Phone Number is required!",
              }),
            }}
            placeholder="Phone Number"
            errors={errors}
            min="999999"
            max="99999999999"
          />
        </Col>
        <Col md={6}>
          <CustomTextArea
            id="address"
            label="Address"
            register={{
              ...register("address", {
                required: "address is required!",
              }),
            }}
            errors={errors}
            isRequired={true}
          />
        </Col>
      </div>
      {activitiesMiddleware(auth.user?.role, UPDATE) && (
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  volunteer: store.volunteer,
  missDose: store.missDose,
});

export default connect(mapStateToProps, { updateMissDose })(PatientInfo);
