import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import TrainingCreate from './TrainingCreate'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getTrainings,
  deleteTraining,
  getTraining,
  getVolunteers,
  getTownships,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import CustomAlert from 'components/Alert/CustomAlert'
import TrainingUpdate from './TrainingUpdate'
import {
  isVolunteerSupervisor,
  activitiesMiddleware,
  CREATE,
} from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'
import {VscRefresh} from "react-icons/vsc"
const TrainingList = ({
  status,
  auth,
  training,
  township,
  volunteer,
  getTrainings,
  deleteTraining,
  getTraining,
  getVolunteers,
  getTownships,
}) => {
  const { role } = auth.user
  const router = useHistory()
  const [alert, setAlert] = useState(null)

  const [exportLoading, setExportLoading] = useState(false)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  const [monthYear, setMonthYear] = useState('')
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: `${element.name} (${element.code})`,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (monthYear !== '') {
      query.month_year = monthYear
    } else {
      delete query['month_year']
    }

    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value
    } else {
      delete query['volunteer_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    // setMonthYear('')
    // setSelectedVolunteer(null)
    // setSelectedTownship(null)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getTrainings(query)
  }, [getTrainings, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    getVolunteers()
  }, [getVolunteers, role])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteTraining(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `trainings-export?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Volunteer: `${data.volunteer?.name}(${data.volunteer?.code})`,
        Township: data.volunteer?.township?.name,
        Date: data?.date,
        Training: data?.name,
        Type: data?.type,
      }))

      if (response.status === 'success') {
        excelExport(result, 'Trainings')
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }
  const handleRefresh = () => {
    router.push(router.location.pathname);
  }
  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">သင်တန်းရရှိမှုစာရင်း</h3>
              </Col>

              <Col className="text-right" xs="6">
              <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={()=> handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                {activitiesMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
      
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
            <Row className='d-flex align-items-center mt-3'>
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>

              {role !== undefined && !isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}

              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Volunteer Name</th>
                <th scope="col">Date</th>
                <th scope="col">Training</th>
                <th scope="col">Type</th>
                {activitiesMiddleware(role, CREATE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={training.trainings?.map((training, index) => (
              <tr key={training.id}>
                <td>{index + 1}</td>
                <td>{training.volunteer.name}</td>
                <td>{training.date}</td>
                <td>{training.name}</td>
                <td>{training.type}</td>
                {activitiesMiddleware(role, CREATE) && (
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await getTraining(training.id);
                        setIsUpdateOpen(true);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(training.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          />

          {training.total > paginationLimit && (
            <CustomPagination pageCount={training.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <TrainingUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <TrainingCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  training: store.training,
})

export default connect(mapStateToProps, {
  getTrainings,
  getTraining,
  getVolunteers,
  getTownships,
  deleteTraining,
})(TrainingList)
