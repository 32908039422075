import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import { updateMissDoseResults } from "store/actions";
import SelectBox from "components/Selectbox";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { missDoseResults } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { activitiesMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";

const FirstTimeTracing = ({ missDose, auth, updateMissDoseResults }) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { first_time_tracing_date, first_time_tracing_result, remark } =
      missDose.missDose;
    setValue("first_time_tracing_date", first_time_tracing_date);
    setValue("first_time_tracing_result", first_time_tracing_result);
    setValue("remark", remark);
  }, [missDose.missDose, setValue]);
  
  const onSubmit = async (data) => {
    setLoading(true);
    await updateMissDoseResults(missDose.missDose?.id, data, 'first-time-tracing');
    setLoading(false);
  };
  const isDisable = useMemo(() => {
    return missDose.missDose?.second_time_tracing_result;
  }, [missDose.missDose?.second_time_tracing_result]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <CustomDateInput
            id="first_time_tracing_date"
            label="First Time Tracing Date"
            register={{
              ...register("first_time_tracing_date"),
            }}
            errors={errors}
            disabled={isDisable}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <SelectBox
            label="First Time Tracing Result"
            control={control}
            name="first_time_tracing_result"
            options={missDoseResults}
            disabled={isDisable}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={7}>
          <CustomTextArea
            id="remark"
            label="Remark"
            register={{
              ...register("remark", {
                required: false,
              }),
            }}
            placeholder="Remark"
            errors={errors}
            disable={isDisable}
          />
        </Col>
      </Row>
      {activitiesMiddleware(auth.user?.role, UPDATE) && !isDisable && (
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" type="submit" disabled={loading} size="sm">
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  missDose: store.missDose,
});

export default connect(mapStateToProps, {
  updateMissDoseResults,
})(FirstTimeTracing);
