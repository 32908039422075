import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import CustomTable from "components/Table/Table";
import VolunteerHandoutCreate from "./VolunteerHandoutCreate";
import { connect } from "react-redux";
import {
  getVolunteerHandouts,
  getVolunteerHandout,
  deleteHandout,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import VolunteerHandoutUpdate from "./VolunteerHandoutUpdate";
import { handoutMiddleware, CREATE, UPDATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import { DELETE } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";

const VolunteerHandoutList = ({
  status,
  auth,
  volunteerHandout,
  deleteHandout,
  getVolunteerHandouts,
  getVolunteerHandout,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [editName, setEditName] = useState(null);

  useEffect(() => {
    getVolunteerHandouts();
  }, [getVolunteerHandouts]);

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteHandout(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };
  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Volunteer Handout</h3>
              </Col>
              <Col className="text-right" xs="6">
                {handoutMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Name</th>
                <th scope="col">File Link</th>
                <th scope="col">
                  {handoutMiddleware(role, UPDATE) && "Action"}
                </th>
              </tr>
            }
            body={volunteerHandout.volunteerHandouts.map(
              (volunteerHandout, index) => (
                <tr key={volunteerHandout.id}>
                  <td>{index + 1}</td>
                  <td>{volunteerHandout.name}</td>
                  <td>
                    <a href={volunteerHandout.file} target="_black">
                      Click Link To View Handout File
                    </a>
                  </td>

                  <td>
                    {/* {handoutMiddleware(role, UPDATE) && (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getVolunteerHandout(volunteerHandout.id);
                          setIsUpdateOpen(true);
                          setEditName(volunteerHandout.name)
                        }}
                      >
                        Update
                      </Button>
                    )} */}
                    {handoutMiddleware(role, DELETE) && (
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(volunteerHandout.id)}
                      >
                        delete
                      </Button>
                    )}
                  </td>
                </tr>
              )
            )}
          />
        </Card>
      </Container>

      <VolunteerHandoutUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} editName={editName}/>
      <VolunteerHandoutCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  volunteerHandout: store.volunteerHandout,
});

export default connect(mapStateToProps, {
  getVolunteerHandouts,
  getVolunteerHandout,
  deleteHandout,
})(VolunteerHandoutList);
