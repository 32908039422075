import React, { useEffect, useState } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import MedicineCreate from "./MedicineCreate";
import MedicineUpdate from "./MedicineUpdate";
import { connect } from "react-redux";
import { getMedicines, getMedicine, deleteMedicine } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomAlert from "components/Alert/CustomAlert";
import { patientMiddleware, CREATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import { UPDATE } from "utils/middleware";
import { DELETE } from "utils/middleware";

const MedicineList = ({
  status,
  auth,
  medicine,
  getMedicines,
  getMedicine,
  deleteMedicine,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  useEffect(() => {
    getMedicines();
  }, [getMedicines]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteMedicine(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Medicines</h3>
              </Col>
              {patientMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">No.</th>
                <th scope="col">No. of dose</th>
                <th scope="col">TB drug</th>
                {patientMiddleware(auth.user.role, UPDATE) &&
                  patientMiddleware(auth.user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={medicine.medicines?.map((r, index) => (
              <tr key={r.id}>
                <td>{index + 1}</td>
                <td>{r.name}</td>
                <td>{r.acronym}</td>
                {patientMiddleware(auth.user.role, UPDATE) &&
                  patientMiddleware(auth.user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getMedicine(r.id);
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(r.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <MedicineUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <MedicineCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  medicine: store.medicine,
});

export default connect(mapStateToProps, {
  getMedicines,
  getMedicine,
  deleteMedicine,
})(MedicineList);
