import React, { useEffect, useState } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import {
  getPatients,
  getPatient,
  temDeletePatient,
  updatePatient,
  getVolunteers,
  getTownships,
  acceptPatient,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import BackBtn from "utils/backBtn";
import Select from "react-select";
import DateRangePicker from "components/Inputs/DateRangePicker";
import { useHistory } from "react-router";
import queryString from "query-string";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import { call } from "services/api";
import { paginationLimit } from "variables/limits";
import CustomPagination from "components/Pagination/Pagination";
import { isVolunteerSupervisor } from "utils/middleware";
import dayjs from "dayjs";
import {VscRefresh} from "react-icons/vsc"

const AllPatientList = ({
  status,
  auth,
  patient,
  getPatients,
  temDeletePatient,
  volunteer,
  getVolunteers,
  getTownships,
  acceptPatient,
  township,
}) => {
  const router = useHistory();
  const { role } = auth.user;
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [dates, setDates] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.status = 1;
    if (!("page" in query)) {
      query.page = 1;
    }
    getPatients(query);
  }, [getPatients, router.location.search]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers, role]);

  const volunteers = volunteer.volunteers?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value;
    } else {
      delete query["volunteer_id"];
    }
    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }
    if (dates) {
      query.start_date = dates.start_date;
      query.end_date = dates.end_date;
      setDates(null);
    } else {
      delete query["start_date"];
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setSelectedVolunteer(null);
  };
  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);
  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
               Code: data.code,
        Password: data.plain_password,

        Township: data.township?.name,
        Database: data.registration_date ? "Registered Patients" : "Raw Data",
        Name: data.name,
        Address: data.address,
        Phone: data.phone,
        Age: data.age,
        Sex: data.sex,
        Referral_Volunteer: data.referral_volunteer
          ? `${data.referral_volunteer?.name}(${data.referral_volunteer?.code})`
          : "",
        
        Referral_Date: data.referral_date ? data.referral_date : "",
        Refer_To: data.refer_to ? data.refer_to : "",
        Case_Founded_Activity: data.case_founded_activity
          ? data.case_founded_activity
          : "",
        TB_Code: data.TB_code ? data.TB_code : "",
        Referred_Type: data.referred_type ? data.referred_type : "",
        Symptoms:
          data.symptoms &&
          JSON.parse(data.symptoms)
            .map((d) => d.eng_name)
            .join(","),
        Presumptive_TB_Number:
          data.township?.abbreviation + "/" + data.presumptive_TB_number,
        Registration_Date: data.registration_date,
        Sputum_Done_Or_Not_Done:
          data.sputum_done_or_not_done === "1" ? "done" : "not done",
        Sputum_Examination_Date: data.sputum_examination_date,
        Sputum_Smear_Result: data.sputum_smear_result,
        Chest_XRay_Done_Or_Not_Done:
          data.chest_Xray_done_or_not_done === "1" ? "done" : "not done",
        Chest_XRay_Examination_Date: data.chest_Xray_examination_date,
        Chest_XRay_Result: data.chest_Xray_result,
        Gene_XPert_Done_Or_Not_Done:
          data.gene_Xpert_done_or_not_done === "1" ? "done" : "not done",
        Gene_XPert_Examination_Date: data.gene_Xpert_examination_date,
        Gene_XPert_Result: data.gene_Xpert_result,
        Truenat_Done_Or_Not_Done:
          data.truenat_done_or_not_done === "1" ? "done" : "not done",
        Truenat_Examination_Date: data.truenat_examination_date,
        Truenat_Result: data.truenat_result,
        FNAC_Done_Or_Not_Done:
          data.FNAC_done_or_not_done === "1" ? "done" : "not done",
        FNAC_Examination_Date: data.FNAC_examination_date,
        FNAC_Result: data.FNAC_result,
        Tuberculin_Skin_Done_Or_Not_Done:
          data.tuberculin_skin_done_or_not_done === "1" ? "done" : "not done",
        TST_Examination_Date: data.TST_examination_date,
        Size_Of_Induration_In_Millimeter: data.size_of_induration_in_millimeter,
        Conclusion_Of_TST: data.conclusion_of_TST,
        Sputum_Culture_Done_Or_Not_Done:
          data.sputum_culture_done_or_not_done === "1" ? "done" : "not done",
        Sputum_Culture_Examination_Date: data.sputum_culture_examination_date,
        Sputum_Culture_Result: data.sputum_culture_result,
        Drug_Susceptibility_Done_Or_Not_Done:
          data.drug_susceptibility_done_or_not_done === "1"
            ? "done"
            : "not done",
        Drug_Susceptibility_Date: data.drug_susceptibility_date,
        Drug_Susceptibility_Result: data.drug_susceptibility_result,
        Drug_Susceptibility_Remark: data.drug_susceptibility_remark,
        TB_Status: data.TB_status,
        Treatment_Status: data.treatment_status,
        TB_DRTB_Code: data.TB_DRTB_code,
        Treatment_Start_Date: data.treatment_start_date, 
Treatment_Registration_Date : data.treatment_registration_date,
        Treatment_Regimen: data.treatment_regimen,
        Defined_TB_Type: data.defined_TB_type,
        Type_Of_TB_Patient: data.type_of_TB_patient,
        Smoking_Status: data.smoking_status,
        DM_Status: data.DM_status,
        HIV_Result: data.HIV_result,
        CPT_status: data.CPT_status,
        CPT_date: data.CPT_date,
        ART_status: data.ART_status,
        ART_date: data.ART_date,
        Treatment_Outcome: data.treatment_outcome,
        Treatment_Outcome_Date: data.treatment_outcome_date,
        Remark: data.remark,
        Volunteer: data.volunteer ? data.volunteer?.name : '',
        DOT_Start_Date: data.DOT_start_date,
        DOT_End_Date: data.DOT_end_date,
        vdot_conclusion_date: data.vdot_conclusion_date,
        vdot_conclusion_category: data.vdot_conclusion_category,
        vdot_conclusion_remark: data.vdot_conclusion_remark,
        VOT_eligible: data.VOT_eligible,
        VOT_ineligible_reason: data.VOT_ineligible_reason,
        VOT_ineligible_other_reason: data.VOT_ineligible_other_reason,
        VOT_accept: data.VOT_accept,
        VOT_decline_reason: data.VOT_decline_reason,
        VOT_decline_other_reason: data.VOT_decline_other_reason,
      }));

      if (response.status === "success") {
        excelExport(result, "Patients");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleRefresh = () => {
    router.push(router.location.pathname);
  }

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="8">
                <h3 className="mb-0">
                  All Patient List (Raw Data + Accepted Patients)
                </h3>
              </Col>
              <Col className="text-right" xs={4}>
              <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={()=> handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
            <Row className="mt-5 d-flex">
              <Col md={3}>
                <Select
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              {!isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col md={5}>
                <DateRangePicker
                  onDateChange={(dates) => {
                    setDates(dates);
                  }}
                />
              </Col>
              <Col md={1}>
                <Button size="sm" color="success" onClick={handleFilter}>
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Township</th>
                <th scope="col">Symptoms</th>
              </tr>
            }
            body={patient.patients?.map((r, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{r.name}</td>
                <td>{r.age}</td>
                <td>{r.sex}</td>
                <td>{r.phone}</td>

                <td>{r.township.name}</td>
                <td>
                  {r.symptoms &&
                    JSON.parse(r.symptoms)?.map((s, index) => {
                      return (
                        <div key={index}>
                          <span>{s.eng_name}</span> <br />
                        </div>
                      );
                    })}
                </td>
              </tr>
            ))}
          />
          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  patient: store.patient,
  volunteer: store.volunteer,
  township: store.township,
});

export default connect(mapStateToProps, {
  getPatients,
  getPatient,
  acceptPatient,
  temDeletePatient,
  updatePatient,
  getVolunteers,
  getTownships,
})(AllPatientList);
