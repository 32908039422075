import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Label } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { createMissDose } from "store/actions";
import { sexs } from "variables/options";
import SelectBox from "components/Selectbox";
import DatePicker from "components/Inputs/DatePicker";
import { isVolunteerSupervisor } from "utils/middleware";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { call } from "services/api";
import CustomDateInput from "components/Inputs/CustomDateInput";

const MissDoseCreate = ({
  isOpen,
  toggle,
  status,
  createMissDose,
  auth,
  township,
  volunteer,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
  } = useForm();

  const { role } = auth.user;
  const [loading, setLoading] = useState(false);
  const [chooseTownship, setChooseTownship] = useState(false);
  const [volunteers, setVolunteers] = useState([]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    if (isVolunteerSupervisor(role)) {
      const volunteers = volunteer.volunteers?.map((element) => ({
        value: element.id,
        label: `${element.name} (${element.code})`,
      }));
      setVolunteers(volunteers);
      setChooseTownship(true);
    }
    return () => {
      setChooseTownship(false);
      setVolunteers([]);
    };
  }, [role, volunteer.volunteers]);

  const setVolunteer = async (township_id) => {
    const result = await call(
      "get",
      `volunteers?${new URLSearchParams({
        township_id: township_id,
      }).toString()}`
    );
    const volunteers = result.data?.map((element) => ({
      value: element.id,
      label: element.name,
    }));
    setChooseTownship(true);
    setVolunteers(volunteers);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    await createMissDose(data);
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="ဆေးသောက်ပျက်ကွက်လူနာ စာရင်းအသစ်"
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ minWidth: "70%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body d-flex flex-wrap">
          <Col md={4}>
            {/* <Label>
              Date <sup className="text-danger">*</sup>
            </Label>
            <DatePicker
              rules={{ required: true }}
              control={control}
              value={getValues("registration_date")}
              onChange={(v) => {
                setValue("registration_date", v);
              }}
            /> */}
            <CustomDateInput
              id="date"
              label="Date"
              register={{
                ...register("registration_date", {
                  required: "Date is required!",
                }),
              }}
              placeholder="Select Date"
              errors={errors}
            />
          </Col>
          {!isVolunteerSupervisor(role) && (
            <Col md={4}>
              <SelectBox
                label="Township"
                control={control}
                rules={{ required: true }}
                name="township_id"
                options={townships}
                onValueChange={(val) => {
                  if (!val) {
                    setChooseTownship(false);
                    setValue("volunteer_id", null);
                  } else {
                    setVolunteer(val);
                  }
                }}
              />
            </Col>
          )}

          <Col md={4}>
            <SelectBox
              label="Volunteer"
              control={control}
              rules={{ required: true }}
              name="volunteer_id"
              options={volunteers}
              disabled={!chooseTownship}
            />
          </Col>

          <Col md={4}>
            <CustomInput
              id="name"
              label="Name"
              register={{
                ...register("name", {
                  required: "Name is required!",
                }),
              }}
              errors={errors}
              placeholder="Name"
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="tb_patient_code"
              label="TB Code"
              register={{
                ...register("tb_patient_code", {
                  required: "TB Code is required!",
                }),
              }}
              placeholder="TB Code"
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="age"
              label="Age"
              register={{
                ...register("age", {
                  required: "Age is required!",
                }),
              }}
              errors={errors}
              type="number"
              max="99"
              placeholder="Age"
            />
          </Col>
          <Col md={4}>
            <SelectBox
              label="Gender"
              control={control}
              name="sex"
              options={sexs}
              rules={{ required: true }}
              placeholder="Gender"
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="phone"
              label="Phone Number"
              type="number"
              register={{
                ...register("phone", {
                  required: "Phone Number is required!",
                }),
              }}
              placeholder="Phone Number"
              errors={errors}
              min="999999"
              max="99999999999"
            />
          </Col>
          <Col md={4}>
            <CustomTextArea
              id="address"
              label="Address"
              register={{
                ...register("address", {
                  required: "address is required!",
                }),
              }}
              errors={errors}
              placeholder="Address"
              isRequired={true}
            />
          </Col>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  township: store.township,
});

export default connect(mapStateToProps, { createMissDose })(MissDoseCreate);
