import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import {
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  SET_ACCOUNT,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_ACCOUNTS,
  UPDATE_ACCOUNT,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getAccounts = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `users?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: SHOW_ACCOUNTS,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getAccount = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("get", `users/${id}`);
      const result = response.data;

      dispatch({
        type: SET_ACCOUNT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createAccount = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "io-register", data);
      const result = response.data;
      dispatch({
        type: CREATE_ACCOUNT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Account has been created successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const updateAccount = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call("post", `users/${id}?_method=PUT`, data);
      const result = response.data;

      dispatch({
        type: UPDATE_ACCOUNT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Account has been updated successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const passwordReset = (id,data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        "post",
        `users/password-reset/${id}?_method=PUT`,
        data
      );
      const result = response.data;

      dispatch({
        type: UPDATE_ACCOUNT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Password has been reset successfully!");
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};

export const deleteAccount = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `users/${id}`);

      dispatch({ type: DELETE_ACCOUNT, payload: id });
      NotificationManager.success("Account has been deleted successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
export const updateAccountEmail = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        "post",
        `email-users/${id}?_method=PUT`,
        data
      );
      const result = response.data;

      dispatch({
        type: UPDATE_ACCOUNT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Email has been updated successfully!");
    } catch (error) {
      const { status, data } = error.response;

      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(data.message);
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    }
  };
};
