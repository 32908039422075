import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import TownshipCreate from "./TownshipCreate";
import CustomPagination from "components/Pagination/Pagination";
import CustomTable from "components/Table/Table";
import { connect } from "react-redux";
import {
  getTownships,
  deleteTownship,
  getTownship,
  getRegions,
  getDistricts,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import TownshipUpdate from "./TownshipUpdate";
import { paginationLimit } from "variables/limits";
import CustomAlert from "components/Alert/CustomAlert";
import { basicDataMiddleware, CREATE } from "utils/middleware";
import BackBtn from "utils/backBtn";
import { UPDATE } from "utils/middleware";
import { DELETE } from "utils/middleware";

const TownshipList = ({
  status,
  auth,
  township,
  getTownships,
  deleteTownship,
  getTownship,
  getRegions,
  getDistricts,
}) => {
  const { role } = auth.user;
  const router = useHistory();
  const [alert, setAlert] = useState(null);

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);


  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getTownships(query);
  }, [getTownships, router.location.search]);

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteTownship(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Townships</h3>
              </Col>
              {basicDataMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
              
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Region</th>
                <th scope="col">District</th>
                <th scope="col">Township</th>
                <th scope="col">Abbreviation</th>
                {basicDataMiddleware(auth.user.role, UPDATE) &&
                  basicDataMiddleware(auth.user.role, DELETE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={township.townships?.map((township, index) => (
              <tr key={township.id}>
                <td>{index + 1}</td>
                <td>{township.region.name}</td>
                <td>{township.district.name}</td>
                <td>{township.name}</td>
                <td>{township.abbreviation}</td>
                {basicDataMiddleware(auth.user.role, UPDATE) &&
                  basicDataMiddleware(auth.user.role, DELETE) && (
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await getTownship(township.id);
                        setIsUpdateOpen(true);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(township.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          />

          {township.total > paginationLimit && (
            <CustomPagination pageCount={township.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <TownshipUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <TownshipCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  township: store.township,
});

export default connect(mapStateToProps, {
  getTownships,
  deleteTownship,
  getTownship,
  getRegions,
  getDistricts,
})(TownshipList);
