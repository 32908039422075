import {
  CREATE_TBCI,
  DELETE_TBCI,
  SET_TBCI,
  SHOW_TBCIS,
  UPDATE_TBCI,
} from '../type'

const initialState = {
  tb_cis: [],
  tb_ci: {},
  total: 0,
};

const tb_ci = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TBCIS:
      return {
        ...state,
        tb_cis: action.payload.data,
        total: action.payload.total,
      };
    case CREATE_TBCI:
      return {
        ...state,
        tb_cis: [action.payload, ...state.tb_cis],
        total: state.total + 1,
      };
    case DELETE_TBCI:
      return {
        ...state,
        tb_cis: state.tb_cis.filter((TBCI) => TBCI.id !== action.payload),
        total: state.total - 1,
      };
    case SET_TBCI:
      return {
        ...state,
        tb_ci: action.payload,
      }
    case UPDATE_TBCI:
      
      let index = state.tb_cis.findIndex(
        (TBCI) => TBCI.id === action.payload.id,
      )
      state.tb_cis[index] = action.payload
      if (state.tb_ci.id === action.payload.id) {
        state.tb_ci = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default tb_ci
