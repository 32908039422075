import { excelExport } from 'utils/excelExport'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import queryString from 'query-string'
import { getWorkStatus } from 'views/pages/dashboard/patient/detailComponents/VDotCalendar'
import { DOTWorking } from './DOTWorking'
import { host } from 'services/api'

export const allPatientVOTExport = async (search) => {
   
    try {
      const response = await call(
        'get',
        `export-all-VOTs?${new URLSearchParams(search).toString()}`
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Township: data.patient?.township?.name,
        Volunteer_Name: data.change_volunteer ? `${data.change_volunteer?.volunteer?.name}(${data.change_volunteer?.volunteer?.code})` : '',
        Date: data.date,
        Work: data.work_status ? DOTWorking[data.work] : '',
        Work_Status: getWorkStatus(data.work, data.work_status, data?.date),
        //ကြာချိန်: data.long,
        သာမာန်ဘေးထွက်ဆိုးကျိုး: data.minor_side_effects,
        အရေးကြီးဘေးထွက်ဆိုးကျိုး: data.major_side_effects,
        လုပ်ဆောင်ချက်: data.actions,
        // Video: data.video ? data.video_url : null,
        // Sign: data.sign ? data.sign_url : null,
        // Type: data.change_volunteer?.type,
        Video_Link: data.work_status && data.work === 3 ? `${host}/DOT-video/${data.uuid}` : null,
        VOT_Video_Link: data.work_status && data.work === 2 ? data.video_url : null,
        Patient_Sign: data.work_status && data.work === 1 ? data.sign_url : null,
        Remark: data.remark,
        Late_Remark: data.late_remark,
      }))
      if (response.status === 'success') {
        excelExport(result, "All_Patients_VOTs");
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
  }