import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Label, FormGroup, Row, Input } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { createHE } from "store/actions";
import SelectBox from "components/Selectbox";
import { isVolunteerSupervisor } from "utils/middleware";
import { discussionTopics } from "variables/options";
import { getTownshipVolunteers } from "store/actions";
import { call } from "services/api";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { dataUrlToFile } from "utils/fileConverter";
import { MdOutlineClear } from "react-icons/md";
const PeopleHECreate = ({
  isOpen,
  toggle,
  status,
  createHE,
  auth,
  township,
  volunteer,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const { role } = auth.user;
  const [loading, setLoading] = useState(false);
  const [topicDiscussedOther, setTopicDiscussedOther] = useState(false);
  const [topicArray, settopicArray] = useState([]);
  const [topicError, setTopicError] = useState(false);
  const [chooseTownship, setChooseTownship] = useState(false);
  const [volunteers, setVolunteers] = useState([]);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const inputFile = useRef(null);
  const [image, setImage] = useState(null);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    if (isVolunteerSupervisor(role)) {
      const volunteers = volunteer.volunteers?.map((element) => ({
        value: element.id,
        label: `${element.name} (${element.code})`,
      }));
      setVolunteers(volunteers);
      setChooseTownship(true);
    }
    return () => {
      setChooseTownship(false);
      setVolunteers([]);
    };
  }, [role, volunteer.volunteers]);

  const addTopic = (s) => {
    let tempData = [];
    if (!topicArray?.find((sr) => sr === s)) {
      topicArray.push(s);
      tempData = topicArray
      settopicArray(topicArray);
    } else {
      tempData = topicArray.filter((sr) => sr !== s);
      settopicArray(tempData);
    }

    if (tempData.find((t) => t === "အခြား")) {
      setTopicDiscussedOther(true);
    } else {
      setTopicDiscussedOther(false);
    }
  };

  const setVolunteer = async (township_id) => {
    const result = await call(
      "get",
      `volunteers?${new URLSearchParams({
        township_id: township_id,
      }).toString()}`
    );
    const volunteers = result.data?.map((element) => ({
      value: element.id,
      label: element.name,
    }));
    setChooseTownship(true);
    setVolunteers(volunteers);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    if (topicArray.length === 0) {
      setTopicError(true);
    } else {
      const formData = new FormData();
      formData.append("date", data.date);
      formData.append("township_id", data.township_id);
      formData.append("volunteer_id", data.volunteer_id);
      formData.append("place", data.place);
      formData.append("male", data.male);
      formData.append("female", data.female);
      if (topicDiscussedOther) {
        formData.append("topic_discussed_other", data.topic_discussed_other);
      }
      formData.append("topic_discussed", topicArray.join("|"));
      if (image) {
        const imageFile = await dataUrlToFile(image);
        formData.append("image", imageFile);
      }

      await createHE(formData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title={"ကျန်းမာရေးအသိပညာပေးခြင်း စာရင်းအသစ်"}
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ minWidth: "50%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body p-3">
          <Row>
            <Col md={6}>
              <CustomDateInput
                id="Date"
                label="Date"
                register={{
                  ...register("date"),
                }}
                errors={errors}
                isRequired={true}
              />
            </Col>
            {!isVolunteerSupervisor(role) && (
              <Col md={6}>
                <SelectBox
                  label="Township"
                  control={control}
                  rules={{ required: true }}
                  name="township_id"
                  options={townships}
                  onValueChange={(val) => {
                    if (!val) {
                      setChooseTownship(false);
                      setValue("volunteer_id", null);
                    } else {
                      setVolunteer(val);
                    }
                  }}
                />
              </Col>
            )}
            <Col md={6}>
              <SelectBox
                label="Volunteer"
                control={control}
                rules={{ required: true }}
                name="volunteer_id"
                options={volunteers}
                disabled={!chooseTownship}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="place"
                label="Place"
                placeholder="Place"
                register={{
                  ...register("place", {
                    required: "Place is required!",
                  }),
                }}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label>Attendent List</Label>
              <div className="d-flex">
                <Col>
                  <CustomInput
                    register={{
                      ...register("male", {
                        required: "တတ်ရောက်သူ ကျား အရေအတွက် is required!",
                      }),
                    }}
                    errors={errors}
                    type="number"
                    max="99"
                    min="0"
                    isRequired={false}
                    placeholder="ကျား"
                    onChanged={(e) => {
                      e.target.value !== ""
                        ? setMale(parseInt(e.target.value))
                        : setMale(0);
                    }}
                  />
                </Col>
                <Col>
                  <CustomInput
                    register={{
                      ...register("female", {
                        required: "တတ်ရောက်သူ မ အရေအတွက် is required!",
                      }),
                    }}
                    errors={errors}
                    type="number"
                    max="99"
                    min="0"
                    isRequired={false}
                    placeholder="မ"
                    onChanged={(e) => {
                      e.target.value !== ""
                        ? setFemale(parseInt(e.target.value))
                        : setFemale(0);
                    }}
                  />
                </Col>
                <Col className="d-flex flex-column">
                  <Label>Total</Label>
                  <span>{male + female}</span>
                </Col>
              </div>
            </Col>

            <Col md={6} className="mb-3">
              <Label>ဆွေးနွေးခဲ့သော အကြောင်းအရာ</Label>

              {discussionTopics?.map((topic, index) => {
                return (
                  <FormGroup check key={index} className="mb-2">
                    <Input
                      type="checkbox"
                      defaultChecked={topicArray?.find((ps) => ps === topic)}
                      onChange={() => {
                        addTopic(topic);
                      }}
                    />
                    <span className="ms-2">{topic}</span>
                  </FormGroup>
                );
              })}

              {topicError && (
                <span className="text-danger">
                  ဆွေးနွေးသောအကြောင်းအရာများ ဖြည့်ပေးပါ
                </span>
              )}
            </Col>
          </Row>
          {topicDiscussedOther && (
            <Col md={6}>
              <CustomInput
                id="place"
                label="အခြား"
                register={{
                  ...register(
                    "topic_discussed_other",
                    topicDiscussedOther && {
                      required: "အခြားအကြောင်းအရာ is required!",
                    }
                  ),
                }}
                errors={errors}
              />
            </Col>
          )}

          <Row>
            <Col md="4">
              <FormGroup>
                  {image && (
                    <MdOutlineClear
                      size="25"
                      onClick={() => {
                        setImage(null);
                      }}
                    />
                  )}
                <div className="mt-2">
                  {image ? (
                    <img
                      src={image}
                      style={{ width: 150, height: 150 }}
                      onClick={() => {
                        inputFile.current.click();
                      }}
                      alt=""
                    />
                  ) : (
                    <div
                      onClick={() => {
                        inputFile.current.click();
                      }}
                      style={{ width: 150, height: 150, background: "#c3b7b7" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h4>Upload+</h4>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setImage(URL.createObjectURL(event.target.files[0]));
                    }
                  }}
                  accept="image/*"
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  township: store.township,
  he: store.he,
});

export default connect(mapStateToProps, { createHE, getTownshipVolunteers })(
  PeopleHECreate
);
