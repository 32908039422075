import React, { useEffect, useState } from 'react'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import SuperVisionVisitCreate from "./SuperVisionVisit_Create"
import { connect } from 'react-redux'
import {
  getSuperVisionVisits,
  getSuperVisionVisit,
  deleteSuperVisionVisit,
  getVolunteers,
  getTownships,
} from "store/actions";
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import CustomAlert from 'components/Alert/CustomAlert'
import { activitiesMiddleware, CREATE } from 'utils/middleware'
import BackBtn from 'utils/backBtn'
import { UPDATE } from 'utils/middleware'
import { DELETE } from 'utils/middleware'
import { useHistory } from 'react-router'
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { call } from "services/api";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import {VscRefresh} from "react-icons/vsc"
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";

const SuperVisionList = ({
  status,
  auth,
  township,
  getTownships,
  getVolunteers,
  supervision_visit,
  getSuperVisionVisits,
  deleteSuperVisionVisit,
}) => {
  const { role } = auth.user;
  const [alert, setAlert] = useState(null);
  const router = useHistory();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

   useEffect(() => {
     const query = queryString.parse(router.location.search);
     if (!("page" in query)) {
       query.page = 1;
     }
     getSuperVisionVisits(query);
   }, [getSuperVisionVisits, router.location.search]);
  
  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);


  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));


  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (monthYear !== "") {
      query.month_year = monthYear;
    } else {
      delete query["month_year"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setMonthYear("");
    // setSelectedTownship(null);
  };
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `supervision-visits-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Township: data.township?.name,
        month: data.month,
        year: data.year,
        Number_Of_SuperVisionVist : data.quantity
      }));

      if (response.status === "success") {
        excelExport(result, "SuperVisionVisit");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };


  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteSuperVisionVisit(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }
  const handleRefresh = () => {
    router.push(router.location.pathname);
  }
  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">ကွင်းဆင်းကြီးကြပ်သည့်အရေအတွက်</h3>
              </Col>

              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="success"
                  className="text-white"
                  onClick={() => handleRefresh()}
                >
                  <VscRefresh />
                </Button>
                {activitiesMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>

              {role !== undefined && !isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}

              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">No</th>
                <th scope="col">Month</th>
                <th scope="col">Year</th>
                <th scope="col">Number of supervision visit in this month</th>
                {activitiesMiddleware(auth.user.role, UPDATE) &&
                  activitiesMiddleware(auth.user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={supervision_visit.supervision_visits?.map((t, index) => (
              <tr key={t.id}>
                <td>{index + 1}</td>
                <td>{t.month}</td>
                <td>{t.year}</td>
                <td>{t.quantity}</td>
                {activitiesMiddleware(auth.user.role, UPDATE) &&
                  activitiesMiddleware(auth.user.role, DELETE) && (
                    <td>
                      {/* <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getSuperVisionVisit(t.id);
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button> */}
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(t.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
          {supervision_visit.total > paginationLimit && (
            <CustomPagination pageCount={supervision_visit.total / paginationLimit} />
          )}
        </Card>
      </Container>

      {/* <SuperVisionVisitUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} /> */}
      <SuperVisionVisitCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  supervision_visit: store.supervision_visit,
  volunteer: store.volunteer,
  township: store.township,
});

export default connect(mapStateToProps, {
  getSuperVisionVisits,
  getSuperVisionVisit,
  getVolunteers,
  getTownships,
  deleteSuperVisionVisit,
})(SuperVisionList);
