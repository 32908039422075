import {
  DELETE_MISS_DOSE,
  SET_MISS_DOSE,
  SHOW_MISS_DOSES,
  UPDATE_MISS_DOSE,
  CREATE_MISS_DOSE
} from '../type'

const initialState = {
  missDoses: [],
  missDose: {},
  total: 0,
};

const missDose = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MISS_DOSES:
      return {
        ...state,
        missDoses: action.payload.data,
        total: action.payload.total,
      };
    case CREATE_MISS_DOSE:
      return {
        ...state,
        missDoses: [action.payload, ...state.missDoses],
        total: state.total + 1,
      };
    case DELETE_MISS_DOSE:
      return {
        ...state,
        missDoses: state.missDoses.filter(
          (missDose) => missDose.id !== action.payload
        ),
        total: state.total - 1,
      };
    case SET_MISS_DOSE:
      return {
        ...state,
        missDose: action.payload,
      }
    case UPDATE_MISS_DOSE:
      
      let index = state.missDoses.findIndex(
        (missDose) => missDose.id === action.payload.id,
      )
      state.missDoses[index] = action.payload
      if (state.missDose.id === action.payload.id) {
        state.missDose = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default missDose
