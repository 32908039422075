import React from "react";
import { Col, Container, Row } from "reactstrap";

const Dashboard = () => {
  return (
    <Container className="d-flex justify-content-center flex-column align-items-center">
      <Row className="d-flex justify-content-center my-3">
        <h1>MATA dashboard</h1>
      </Row>
      <Row>
        <Col>
          <img
            src={require("assets/img/brand/TBDayImage1.png").default}
            style={{ width: "100%" }}
          />
        </Col>
        <Col>
          <img
            src={require("assets/img/brand/TBDayImage2.png").default}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
