import {
  CREATE_SYMPTOM,
  DELETE_SYMPTOM,
  SET_SYMPTOM,
  SHOW_SYMPTOMS,
  UPDATE_SYMPTOM,
} from '../type'

const initialState = {
  symptoms: [],
  symptom: {},
  total: 0,
}

const symptom = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SYMPTOMS:
      return {
        ...state,
        symptoms: action.payload.data,
        // symptoms: action.payload,
        total: action.payload.total,
      };
    case CREATE_SYMPTOM:
      return {
        ...state,
        symptoms: [action.payload, ...state.symptoms],
        // symptoms: [ ...state.symptoms],
        total: state.total + 1,
      }
    case DELETE_SYMPTOM:
      return {
        ...state,
        symptoms: state.symptoms.filter(
          (SYMPTOM) => SYMPTOM.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_SYMPTOM:
      return {
        ...state,
        symptom: action.payload,
      }
    case UPDATE_SYMPTOM:
      let index = state.symptoms.findIndex(
        (symptom) => symptom.id === action.payload.id,
      )
      state.symptoms[index] = action.payload
      if (state.symptom.id === action.payload.id) {
        state.symptom = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default symptom
