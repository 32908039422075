import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { updateSymptom } from 'store/actions'

const SymptomUpdate = ({ isOpen, toggle, status, symptom, updateSymptom }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { eng_name, myan_name } = symptom.symptom
  
    setValue('eng_name', eng_name)
    setValue('myan_name', myan_name)
  }, [setValue, symptom.symptom])

  const onSubmit = async (data) => {
    setLoading(true)
   // await updateSymptom(symptom.symptom.id, data)
    await updateSymptom(symptom.symptom.id, { // need to change when api comes
      id: symptom.symptom.id,
      eng_name: data.eng_name,
      myan_name: data.myan_name,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Symptom"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
        <CustomInput
            id="eng_name"
            label="Eng Name"
            register={{
              ...register('eng_name', {
                required: 'Eng Name is required!',
              }),
            }}
            placeholder="Enter Eng Name"
            errors={errors}
          />
          <CustomInput
            id="myan_name"
            label="Myan Name"
            register={{
              ...register('myan_name', {
                required: 'Myan Name is required!',
              }),
            }}
            placeholder="Enter Myan Name"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  symptom: store.symptom,
})

export default connect(mapStateToProps, { updateSymptom })(SymptomUpdate)
