import {
  DELETE_INFECTION_CONTROL_CHECK,
  SET_INFECTION_CONTROL_CHECK,
  SHOW_INFECTION_CONTROL_CHECKS,
} from '../type'

const initialState = {
  infectionControlChecks: [],
  infectionControlCheck: {},
  total: 0,
}

const infectionControlCheck = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_INFECTION_CONTROL_CHECKS:
      return {
        ...state,
        infectionControlChecks: action.payload.data,
        total: action.payload.total,
      }
    case DELETE_INFECTION_CONTROL_CHECK:
      return {
        ...state,
        infectionControlChecks: state.infectionControlChecks.filter(
          (infectionControlCheck) =>
            infectionControlCheck.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_INFECTION_CONTROL_CHECK:
      return {
        ...state,
        infectionControlCheck: action.payload,
      }
    default:
      return state
  }
}

export default infectionControlCheck
