import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import BarChart from "components/charts/BarChart";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import { getDotPatientVisualization } from "store/actions";
import { primaryColor } from "components/charts/constant";
import { secondaryColor } from "components/charts/constant";

const DotPatientVisualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, visualization, township, auth } = useSelector(
    (state) => state
  );
  const { dotPatientVisualization } = visualization;
  const { with_volunteers, without_volunteers, No_DOTs } =
    dotPatientVisualization;

  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getDotPatientVisualization(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const with_volunteers_bar = {
    labels: with_volunteers?.data?.map((row) => row.township_name),
    datasets: [
      {
        label: "Male",
        data: with_volunteers?.data?.map((data) => data.male),
        backgroundColor: primaryColor,
      },
      {
        label: "Female",
        data: with_volunteers?.data?.map((data) => data.female),
        backgroundColor: secondaryColor,
      },
    ],
  };

  const without_volunteers_bar = {
    labels: without_volunteers?.data?.map((row) => row.township_name),
    datasets: [
      {
        label: "Male",
        data: without_volunteers?.data?.map((data) => data.male),
        backgroundColor: primaryColor,
      },
      {
        label: "Female",
        data: without_volunteers?.data?.map((data) => data.female),
        backgroundColor: secondaryColor,
      },
    ],
  };
   const No_DOTs_bar = {
     labels: No_DOTs?.data?.map((row) => row.township_name),
     datasets: [
       {
         label: "Male",
         data: No_DOTs?.data?.map((data) => data.male),
         backgroundColor: primaryColor,
       },
       {
         label: "Female",
         data: No_DOTs?.data?.map((data) => data.female),
         backgroundColor: secondaryColor,
       },
     ],
   };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">DOT Patient Data Visualization</CardTitle>
          <Row className="mt-3">
            {!isVolunteerSupervisor(role) && (
              <Col sm={4} className="mt-4">
                <Select
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}

            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>

            <Col sm={1} className="mt-4">
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <BarChart
                title={"DOT Patients with volunteers"}
                data={with_volunteers_bar}
                total={with_volunteers?.total}
                uniqueChartClassName={"dot_patients_with_volunteers"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"DOT Patients without volunteers"}
                data={without_volunteers_bar}
                total={without_volunteers?.total}
                uniqueChartClassName={"dot_patients_without_volunteers"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"No DOT Patients"}
                data={No_DOTs_bar}
                total={No_DOTs?.total}
                uniqueChartClassName={"no_dot_patients"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default DotPatientVisualization;
