import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomInput from "components/Inputs/CustomInput";
import { positiveOrNegative } from "variables/options";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { number } from "prop-types";
import { updatePatient } from "store/actions";
import { doneOrNotDone } from "variables/options";

const TuberculinSkin = ({ patient, auth, updatePatient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const isDone = watch("tuberculin_skin_done_or_not_done");

  useEffect(() => {
    if (patient.patient) {
      const {
        tuberculin_skin_done_or_not_done,
        TST_examination_date,
        size_of_induration_in_millimeter,
        conclusion_of_TST,
      } = patient.patient;

      setValue(
        "tuberculin_skin_done_or_not_done",
        tuberculin_skin_done_or_not_done
      );
      setValue("TST_examination_date", TST_examination_date);
      setValue(
        "size_of_induration_in_millimeter",
        size_of_induration_in_millimeter
      );
      setValue("conclusion_of_TST", conclusion_of_TST);
    }
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);

    await updatePatient(patient.patient.id, {
      tuberculin_skin_done_or_not_done: data.tuberculin_skin_done_or_not_done,
      TST_examination_date: isDone === "1" ? data.TST_examination_date : null,
      size_of_induration_in_millimeter:
        isDone === "1" ? data.size_of_induration_in_millimeter : null,
      conclusion_of_TST: isDone === "1" ? data.conclusion_of_TST : null,
    },
    'tuberculin-skin',
    'Tuberculin skin');
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="Tuberculin skin done"
              control={control}
              name="tuberculin_skin_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          {isDone === "1" && (
            <>
              <Col md={4}>
                <CustomDateInput
                  id="TST_examination_date"
                  label=" Date of Tuberculin skin"
                  register={{
                    ...register("TST_examination_date", {
                      required: "Tuberculin skin date is required!",
                    }),
                  }}
                  placeholder="Tuberculin skin date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="size_of_induration_in_millimeter"
                  label="Size of induration(in millimeter)"
                  register={{
                    ...register("size_of_induration_in_millimeter", {
                      required:
                        "Size of induration(in millimeter) is required!",
                    }),
                  }}
                  placeholder="Size of induration(in millimeter)"
                  errors={errors}
                  type={number}
                  onChanged={(e) => {
                    if (e.target.value < 10) {
                      setValue("conclusion_of_TST", "Negative");
                    } else {
                      setValue("conclusion_of_TST", "Positive");
                    }
                  }}
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="TST Result"
                  control={control}
                  name="conclusion_of_TST"
                  options={positiveOrNegative}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(
  TuberculinSkin
);
