import React, { useEffect, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import {
  Container,
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import { reportMonths } from "variables/options";
import { years } from "variables/options";
import BackBtn from "utils/backBtn";
import { connect } from "react-redux";
import { getTownships } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { isVolunteerSupervisor } from "utils/middleware";
import NTPCBTBTableHead from "./NTPCBTBTableHead";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { getCBTBCReport } from "store/actions";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

const NTPCBTBCTemplate = ({
  status,
  auth,
  township,
  getTownships,
  report,
  getCBTBCReport,
}) => {
  const { role } = auth.user;
  const router = useHistory();
  const [month, setMonth] = useState(null);
  const [monthText, setMonthText] = useState("");
  const [year, setYear] = useState(null);
  const [yearText, setYearText] = useState("");
  const [townshipText, setTownshipText] = useState("");
  const [selectedTownship, setSelectedTownship] = useState(null);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    getCBTBCReport(query);
  }, [getCBTBCReport, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    if (month) {
      query.month = month.value;
      setMonthText(month.label);
    } else {
      delete query["month"];
      setMonthText("");
    }
    if (year) {
      query.year = year.value;
      setYearText(year.value);
    } else {
      delete query["year"];
      setYearText("");
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
      setTownshipText(selectedTownship.label);
    } else {
      delete query["township_id"];
      setTownshipText("");
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    // setMonth("");
    // setYear("");
    // setSelectedTownship(null);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">NTP CBTBC Report</h3>
            </Col>
            <Col className="text-right" xs="6">
            <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-sm bg-primary text-white border-0"
                    table="table-to-xls"
                    filename="Volunteer Supervisior report"
                    sheet="Volunteer Supervisior report"
                    buttonText="Export"/>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(e) => setMonth(e)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(e) => setYear(e)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col md={3}>
              <Button
                className="mt-3"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table
          className={classNames("app-table align-items-center table-fixed")}
          responsive
          id="table-to-xls"
        >
          <NTPCBTBTableHead />
          <tbody className="list">
            <tr>
              <td className="fixed-cell left">1</td>
              <td className="fixed-cell left">MATA TB</td>
              <td>USAID</td>
              <td>MATA TB Project(AIS)</td>
              <td>Yangon</td>
              <td>{townshipText}</td>
              <td>{yearText}</td>
              <td>{monthText}</td>
              <td>{report.CBTBCReport?.training?.male}</td>
              <td>{report.CBTBCReport?.training?.female}</td>
              <td>
                {report.CBTBCReport?.training?.male +
                  report.CBTBCReport?.training?.female}
              </td>
              <td>{report.CBTBCReport?.volunteer?.male}</td>
                <td>{report.CBTBCReport?.volunteer?.female}</td>
                <td>
                {report.CBTBCReport?.volunteer?.male +
                    report.CBTBCReport?.volunteer?.female}
                </td>
              <td>{report.CBTBCReport?.referPatient?.male}</td>
              <td>{report.CBTBCReport?.referPatient?.female}</td>
              <td>
                {report.CBTBCReport?.referPatient?.male +
                  report.CBTBCReport?.referPatient?.female}
              </td>
              <td>{report.CBTBCReport?.TBPatientFromCommunityAndHE?.male}</td>
              <td>{report.CBTBCReport?.TBPatientFromCommunityAndHE?.female}</td>
              <td>
                {report.CBTBCReport?.TBPatientFromCommunityAndHE?.male +
                  report.CBTBCReport?.TBPatientFromCommunityAndHE?.female}
              </td>
              <td>{report.CBTBCReport?.referTBCTPatient?.male}</td>
              <td>{report.CBTBCReport?.referTBCTPatient?.female}</td>
              <td>
                {report.CBTBCReport?.referTBCTPatient?.male +
                  report.CBTBCReport?.referTBCTPatient?.female}
              </td>
              <td>{report.CBTBCReport?.TBPatientFromTBCT?.male}</td>
              <td>{report.CBTBCReport?.TBPatientFromTBCT?.female}</td>
              <td>
                {report.CBTBCReport?.TBPatientFromTBCT?.male +
                  report.CBTBCReport?.TBPatientFromTBCT?.female}
              </td>
              <td>
                {report.CBTBCReport?.referPatient?.male +
                  report.CBTBCReport?.referPatient?.female +
                  report.CBTBCReport?.referTBCTPatient?.male +
                  report.CBTBCReport?.referTBCTPatient?.female}
              </td>
              <td>
                {report.CBTBCReport?.TBPatientFromCommunityAndHE?.male +
                  report.CBTBCReport?.TBPatientFromCommunityAndHE?.female +
                  report.CBTBCReport?.TBPatientFromTBCT?.male +
                  report.CBTBCReport?.TBPatientFromTBCT?.female}
              </td>
              <td>{report.CBTBCReport?.DotPatient?.male}</td>
              <td>{report.CBTBCReport?.DotPatient?.female}</td>
              <td>
                {report.CBTBCReport?.DotPatient?.male +
                  report.CBTBCReport?.DotPatient?.female}
              </td>
              <td>{report.CBTBCReport?.DotFinishedPatient?.male}</td>
              <td>{report.CBTBCReport?.DotFinishedPatient?.female}</td>
              <td>
                {report.CBTBCReport?.DotFinishedPatient?.male +
                  report.CBTBCReport?.DotFinishedPatient?.female}
              </td>
              <td>{report.CBTBCReport?.HE?.total}</td>
              <td>{report.CBTBCReport?.HE?.male}</td>
              <td>{report.CBTBCReport?.HE?.female}</td>
              <td>
                {report.CBTBCReport?.HE?.male + report.CBTBCReport?.HE?.female}
              </td>
             
              <td>{report.CBTBCReport?.superVisionvisit?.total}</td>

              <td>{report.CBTBCReport?.mobileTeamPatient?.male}</td>
              <td>{report.CBTBCReport?.mobileTeamPatient?.female}</td>
              <td>
                {report.CBTBCReport?.mobileTeamPatient?.male +
                  report.CBTBCReport?.mobileTeamPatient?.female}
              </td>
              <td>{report.CBTBCReport?.mobileTeamTBPatient?.male}</td>
              <td>{report.CBTBCReport?.mobileTeamTBPatient?.female}</td>
              <td>
                {report.CBTBCReport?.mobileTeamTBPatient?.male +
                  report.CBTBCReport?.mobileTeamTBPatient?.female}
              </td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  report: store.report,
});

export default connect(mapStateToProps, {
  getTownships,
  getCBTBCReport,
})(NTPCBTBCTemplate);
