import React, {  useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form ,Col} from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { createSuperVisionVisit } from "store/actions";
import SelectBox from 'components/Selectbox'
import { isVolunteerSupervisor } from 'utils/middleware'
import { months } from 'variables/options'

const SuperVisionVisitCreate = ({
  isOpen,
  toggle,
  status,
  createSuperVisionVisit,
  township,
  auth
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
  const onSubmit = async (data) => {
    setLoading(true);
    await createSuperVisionVisit(data);
    setLoading(false);
    reset();
     toggle(false);
  };
  return (
    <CustomModal
      isOpen={isOpen}
      title="ကွင်းဆင်းကြီးကြပ်မှု စာရင်း"
      onClick={() => {
        toggle(false);
        reset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Col>
            <SelectBox
              label="Month"
              control={control}
              name="month"
              options={months}
              rules={{ required: true }}
            />
          </Col>
          <Col>
            <CustomInput
              id="year"
              label="Year"
              register={{
                ...register("year", {
                  required: "Year is required!",
                }),
              }}
              placeholder="Year"
              errors={errors}
            />
          </Col>
          <Col>
            <CustomInput
              id="total_visit"
              label="Number of supervision visit"
              register={{
                ...register("quantity", {
                  required: "Number of supervision Visit is required!",
                }),
              }}
              placeholder="Total Visit"
              errors={errors}
            />
          </Col>
          {!isVolunteerSupervisor(auth.user.role) && (
            <Col>
              <SelectBox
                label="Township"
                control={control}
                name="township_id"
                options={townships}
                rules={{ required: true }}
              />
            </Col>
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  township: store.township,
  auth: store.auth
});

export default connect(mapStateToProps, { createSuperVisionVisit })(SuperVisionVisitCreate)
