import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import { positiveOrNegative } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { updatePatient } from "store/actions";
import { doneOrNotDone } from "variables/options";

const SpctumMicrosopy = ({
  updatePatient,
  auth,
  patient,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  // const [isDone, setIsDone] = useState(false);

  const isDone = watch("sputum_done_or_not_done");

  useEffect(() => {
    const {
      sputum_done_or_not_done,
      sputum_examination_date,
      sputum_smear_result,
    } = patient.patient;
    setValue("sputum_done_or_not_done", sputum_done_or_not_done);
    setValue("sputum_examination_date", sputum_examination_date);
    setValue("sputum_smear_result", sputum_smear_result);
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {

    setLoading(true);

    await updatePatient(patient.patient.id, {
      sputum_done_or_not_done: data.sputum_done_or_not_done,
      sputum_examination_date:
        isDone === "1" ? data.sputum_examination_date : null,
      sputum_smear_result: isDone === "1" ? data.sputum_smear_result : null,
    }, 
    'sputum',
    'Sputum Microsopy');
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        {/* <Col className="d-flex mb-4">
          <span>Not Done</span>
          <label className="custom-toggle custom-toggle-success mx-3">
            <input
              type="checkbox"
              checked={isDone}
              onChange={handleToggle}
              name="sputum_done_or_not_done"
            />
            <span className="custom-toggle-slider" />
          </label>
          <span>Done</span>
        </Col> */}

        <Row>
          <Col md={4}>
            <SelectBox
              label="Sputum Microsopy done"
              control={control}
              name="sputum_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>
        </Row>

        <Row>
          {isDone === "1" && (
            <>
              <Col md={4}>
                <CustomDateInput
                  id="sputum_examination_date"
                  label="Sputum date"
                  register={{
                    ...register("sputum_examination_date", {
                      required: "Sputum date is required!",
                    }),
                  }}
                  placeholder="Register date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="Result Of Smear"
                  control={control}
                  name="sputum_smear_result"
                  options={positiveOrNegative}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>

        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(
  SpctumMicrosopy
);
