import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updateVolunteer, getTownships } from "store/actions";
import { Button, Form, Row, Col, FormGroup } from "reactstrap";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { sexs, yesOrNo, educations } from "variables/options";
import { useCallback } from "react";
import SelectBox from "components/Selectbox";
import DatePicker from "components/Inputs/DatePicker";
import { occupations } from "variables/options";
import { volunteerMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { useHistory } from "react-router";
import { isVolunteerSupervisor } from "utils/middleware";
import CustomDateInput from "components/Inputs/CustomDateInput";

const VolunteerUpdate = ({
  isOpen,
  auth,
  toggle,
  status,
  volunteer,
  township,
  updateVolunteer,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const { role } = auth.user;
  const router = useHistory();

  useEffect(() => {
    const data = volunteer.volunteer;
    setValue("name", data.name);
    setValue("code", data.code);
    setValue("password", data.password);
    setValue("age", data.age);
    setValue("gender", data.gender);
    setValue("village", data.village);
    setValue("address", data.address);
    setValue("ward", data.ward);
    setValue("phone", data.phone);
    setValue("education_status", data.education_status);
    setValue("past_history_of_TB", data.past_history_of_TB);
    setValue("occupation", data.occupation);
    setValue("volunteer_joined_date", data.volunteer_joined_date);
    setValue("township_id", data.township?.id);
    setValue("remark", data.remark);
  }, [setValue, volunteer.volunteer]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    await updateVolunteer(volunteer.volunteer.id, data);
    setLoading(false);
  };

  const formReset = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (status.success) {
      formReset();
      toggle(false);
    }
    return () => formReset();
  }, [status.success, formReset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Volunteer"
      onClick={() => {
        toggle(false);
        formReset();
      }}
      style={{ maxWidth: "60%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <CustomInput
                id="name"
                label="Name"
                register={{
                  ...register("name", {
                    required: "Name is required!",
                  }),
                }}
                placeholder="Enter Name"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="age"
                label="age"
                register={{
                  ...register("age", {
                    required: "age is required!",
                  }),
                }}
                placeholder="Enter age"
                errors={errors}
                max="120"
                type="number"
              />
            </Col>
            <Col md={6}>
              <SelectBox
                label="Select Gender"
                control={control}
                name="gender"
                options={sexs}
                rules={{ required: true }}
              />
            </Col>
            {!isVolunteerSupervisor(role) && (
              <Col md={6}>
                <SelectBox
                  label="Select Township"
                  control={control}
                  name="township_id"
                  options={townships}
                  rules={{ required: true }}
                />
              </Col>
            )}
            <Col md={6}>
              <CustomTextArea
                id="address"
                label="address"
                register={{
                  ...register("address"),
                }}
                placeholder="Address"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="village"
                label="village"
                register={{
                  ...register("village", {
                    required: "village is required!",
                  }),
                }}
                placeholder="Enter village"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="ward"
                label="ward"
                register={{
                  ...register("ward", {
                    required: "ward is required!",
                  }),
                }}
                placeholder="Enter ward"
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                id="phone"
                label="Phone"
                register={{
                  ...register("phone", {
                    required: "Phone is required!",
                  }),
                }}
                placeholder="Enter Phone"
                errors={errors}
                max="99999999999"
                type="number"
              />
            </Col>
            <Col md={6}>
              {/* <FormGroup>
                <label className="mb-2">
                  Volunteer Joined Date <sup className="text-danger">*</sup>
                </label>
                <DatePicker
                  rules={{ required: true }}
                  control={control}
                  value={getValues("volunteer_joined_date")}
                  onChange={(v) => {
                    setValue("volunteer_joined_date", v);
                  }}
                />
              </FormGroup> */}
              <CustomDateInput
                id="volunteer_joined_date"
                label="Volunteer Join Date"
                register={{
                  ...register("volunteer_joined_date", {
                    required: "Volunteer Joined date is required!",
                  }),
                }}
                errors={errors}
                min="1950-01-01"
                max="2100-12-31"
              />
            </Col>
            <Col md={6}>
              <SelectBox
                label="Select Occupation"
                control={control}
                name="occupation"
                options={occupations}
                rules={{ required: true }}
              />
            </Col>
            <Col md={6}>
              <SelectBox
                label="Select Education Status"
                control={control}
                name="education_status"
                options={educations}
                rules={{ required: true }}
              />
            </Col>

            <Col md={6}>
              <SelectBox
                label="Select Past History of TB"
                control={control}
                name="past_history_of_TB"
                options={yesOrNo}
                rules={{ required: true }}
              />
            </Col>

            <Col md={6}>
              <CustomTextArea
                id="remark"
                label="Remark"
                register={{
                  ...register("remark"),
                }}
                placeholder="Write remark..."
                errors={errors}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="success"
            size="sm"
            onClick={() =>
              router.push(
                `/admin/volunteer-training-list/${volunteer.volunteer.id}`
              )
            }
          >
            Training
          </Button>

          {volunteerMiddleware(role, CREATE) && (
            <>
              <Button
                color="primary"
                size="sm"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading" : "Update"}
              </Button>
            </>
          )}
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  auth: store.auth,
  township: store.township,
});

export default connect(mapStateToProps, { updateVolunteer, getTownships })(
  VolunteerUpdate
);
