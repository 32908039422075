import React from "react";
import {
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Container,
} from "reactstrap";
import classnames from "classnames";
import "../../patient/index.scss";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import PatientInfo from "./details/PatientInfo";
import FirstTimeTracing from "./details/FirstTimeTracing";
import SecondTimeTracing from "./details/SecondTimeTracing";
import ThirdTimeTracing from "./details/ThirdTimeTracing";
import FinalOutcome from "./details/FinalOutcome";

const MissDoseDetails = ({ isOpen, toggle, missDose }) => {
  const [activeTab, setActiveTab] = React.useState("1");

  return (
    <CustomModal
      isOpen={isOpen}
      title="ဆေးသောက်ပျက်ကွက်လူနာ အသေးစိတ်"
      onClick={() => {
        toggle(false);
      }}
      style={{ minWidth: "60%" }}
    >
      <div>
        <Nav className="d-flex flex-wrap p-3">
          <NavItem className="mx-3 mb-3">
            <Button
              className={classnames(
                "tab-background-color text-white border-0",
                {
                  "bg-danger": activeTab === "1",
                  "bg-success": missDose.missDose?.name,
                }
              )}
              onClick={() => {
                setActiveTab("1");
              }}
            >
              Patient Information
            </Button>
          </NavItem>
          <NavItem className="mx-3 mb-3">
            <Button
              className={classnames(
                "tab-background-color text-white border-0",
                {
                  "bg-danger": activeTab === "2",
                  "bg-success": missDose.missDose?.first_time_tracing_result,
                }
              )}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              First Time Tracing
            </Button>
          </NavItem>
          <NavItem className="mx-3 mb-3">
            <Button
              className={classnames(
                "tab-background-color text-white border-0",
                {
                  "bg-danger": activeTab === "3",
                  "bg-success": missDose.missDose?.second_time_tracing_result,
                  "bg-gray":
                    !missDose.missDose?.second_time_tracing_result &&
                    missDose.missDose?.outcome_result,
                }
              )}
              onClick={() => {
                setActiveTab("3");
              }}
            >
              Second Time Tracing
            </Button>
          </NavItem>
          <NavItem className="mx-3 mb-3">
            <Button
              className={classnames(
                "tab-background-color text-white border-0",
                {
                  "bg-danger": activeTab === "4",
                  "bg-success": missDose.missDose?.third_time_tracing_result,
                  "bg-gray":
                    !missDose.missDose?.third_time_tracing_result &&
                    missDose.missDose?.outcome_result,
                }
              )}
              onClick={() => {
                setActiveTab("4");
              }}
            >
              Third Time Tracing
            </Button>
          </NavItem>
          <NavItem className="mx-3 mb-3">
            <Button
              className={classnames(
                "tab-background-color text-white border-0",
                {
                  "bg-danger": activeTab === "5",
                  "bg-success": missDose.missDose?.outcome_result,
                }
              )}
              onClick={() => {
                setActiveTab("5");
              }}
            >
              Final Outcome
            </Button>
          </NavItem>
        </Nav>
      </div>
      <Container fluid style={{ minHeight: "400px" }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <PatientInfo setActiveTab={setActiveTab} />
          </TabPane>
          <TabPane tabId="2">
            <FirstTimeTracing setActiveTab={setActiveTab} />
          </TabPane>
          <TabPane tabId="3">
            <SecondTimeTracing setActiveTab={setActiveTab} />
          </TabPane>
          <TabPane tabId="4">
            <ThirdTimeTracing setActiveTab={setActiveTab} />
          </TabPane>
          <TabPane tabId="5">
            <FinalOutcome setActiveTab={setActiveTab} />
          </TabPane>
        </TabContent>
      </Container>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  missDose: store.missDose,
});

export default connect(mapStateToProps)(MissDoseDetails);
