export const volunteerReportColors = {
  indicator_1: '#FF5733', // Red
 indicator_2: '#FFBD33', // Orange
  indicator_3:'#FFEE33', // Yellow
  indicator_4:'#33FF57', // Green
  indicator_5:'#33FFBD', // Turquoise
  indicator_6:'#33D2FF', // Light Blue
  indicator_7:'#336BFF', // Blue
  indicator_8:'#8A33FF', // Purple
  indicator_9:'#E933FF', // Magenta
  indicator_10:'#FF33EE', // Pink
  indicator_11:'#FF336B', // Salmon
  indicator_12:'#D73333', // Crimson
  indicator_13:'#4C4C4C', // Gray
};

   
export const volunteerReportLabels = [
  {
    eng_label: ' No. of volunteer referred presumptive TB cases reach to clinic',
    myan_label: 'ပြည်သူလူထုအတွင်းမှ ညွှန်းပို့သည့် တီဘီသံသယလူနာအရေအတွက်'
  }
  ,
  {
    eng_label: 'No.of presumptive TB cases examined',
    myan_label: 'ညွှန်းပို့သူများအနက်မှ စစ်ဆေးသည့် တီဘီသံသယလူနာအရေအတွက်'
  },
  {
    eng_label: ' No. of Total TB patients referred by volunteer',
    myan_label: 'ညွှန်းပို့သူများအနက်မှ တီဘီဆေးစတင်ကုသသူအရေအတွက်'
  },
  {
    eng_label: 'No Of Index TB patients receiving contact investigation',
    myan_label: 'TB-CI ဆောင်ရွက်ခဲ့သည့် သတ်မှတ်လူနာအရေအတွက်'
  },
  {
    eng_label: 'No of presumptive TB contacts examined by Contact Investigation',
    myan_label: 'TB-CIမှ ညွှန်းပို့ပြီး စစ်ဆေးသည့် တီဘီသံသယလူနာအရေအတွက်'
  },
  {
    eng_label: 'No Of Presumptive TB cases diagnosed as TB referred by contact tracing',
    myan_label: 'TB-CIမှ ညွှန်းပို့ပြီးစစ်ဆေးခံသူများအနက်မှ တီဘီဆေးစတင်ကုသသူ အရေအတွက်'
  },
  {
    eng_label: ' No Of TB patients on DOTS provided by Volunteers',
    myan_label: 'မိမိမှ တီဘီဆေးသောက်ရန်ကြီးကြပ်ဆဲ လူနာအရေအတွက်'
  },
  {
    eng_label: 'No Of TB patients who completed DOTS',
    myan_label: 'ဆေးကုသမှုပြီးစီးသည့် တီဘီလူနာအရေအတွက်'
  },
  {
    eng_label: 'No of Frequency traced by MD/LFU activities',
    myan_label: 'ဆေးသောက်ပျက်ကွက်ပြန်လည် ရှာဖွေသည့် အကြိမ်အရေအတွက်'
  },
  {
    eng_label: ' No Of Patients traced by MD/LFU activities',
    myan_label: 'ပြန်လည်ရှာဖွေတွေ့ရှိသည့် ဆေးသောက်ပျက်ကွက်လူနာအရေအတွက်'
  },
  {
    eng_label: 'No. of health education sessions conducted by volunteers',
    myan_label: ' ပြည်သူလူထုအတွင်း ကျန်းမာရေးပညာပေးသည့်အကြိမ်အရေအတွက်'
  },
  {
    eng_label: ' No Of Health education attendees in this township',
    myan_label: 'ပြီးခဲ့သောလအတွင်း ပြည်သူလူထုအတွင်းကျန်းမာရေးလုပ်ငန်းများဆောင်ရွက်ရာတွင် တက်ရောက်ခဲ့သူစုစုပေါင်း'
  },
  {
    eng_label: ' No Of HE sessions To TB household members and non-household members Contacts',
    myan_label: ' အတူနေမိသားစုအားကျန်းမာရေးပညာပေးသည့်အကြိမ်အရေအတွက်'
  },
]


export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const caseFoundActivityLabels = ["Community", "TB CI", "HE"];

export const tbTypeLabels = [
  "Pulmonary BC",
  "Extra Pulmonary BC",
  "Pulmonary CD",
  "Extra Pulmonary CD",
];
export const treatmentRegimenLabels = [
  "Initial Regimen",
  "Retreatment Regimen",
  "Childhood Regimen",
  "MDRTB Regimen",
];
export const typeOfTBPatientLabels = [
  "New",
  "Relapse",
  "Treatment after failure",
  "Treatment after loss to follow up",
  "Other previously treated",
  "Previous treatment history unknown",
];
export const outcomeLabels = [
  "Cured",
  "Completed",
  "Died",
  "Lost to Follow up",
  "Failed",
  "Move to Second Line Treatment",
  "Not Evaluated",
];

export const tbciLabels = [
  "ပိုးတွေ့တီဘီ",
  "အိပ်ချ်အိုင်ဗီပိုးတွေ့တီဘီ",
  "အသက် (၅) နှစ်အောက်ကလေးတီဘီလူနာ",
  "အခြား",
];


export const primaryColor = 'red';
export const secondaryColor = 'blue'