import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updateDotDate } from "store/actions";
import CustomDateInput from "components/Inputs/CustomDateInput";

const DOtVisitDateUpdate = ({
  isOpen,
  toggle,
  status,
  updateDotDate,
  DOT,
  patient,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const { date } = DOT.DOT;
    setValue("date", date);
  }, [setValue, DOT.DOT]);

  const onSubmit = async (data) => {
    setLoading(true);
    data.patient_id = patient.patient?.id;
    console.log(data);
    await updateDotDate(DOT.DOT.id, data);
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Dot Visit Date"
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ maxWidth: "20%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body d-flex flex-wrap">
          <Col>
            <CustomDateInput
              id="date"
              label="Date"
              register={{
                ...register("date", {
                  required: "date is required!",
                }),
              }}
              errors={errors}
              min="1950-01-01"
              max="2100-12-31"
            />
          </Col>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  DOT: store.DOT,
  patient: store.patient
});

export default connect(mapStateToProps, { updateDotDate })(DOtVisitDateUpdate);
