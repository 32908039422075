import React, { useEffect } from 'react'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Button,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap'
import './index.scss'
import classnames from 'classnames'
import BackBtn from 'utils/backBtn'
import PresumptiveInfo from './detailComponents/PresumptiveInfo'
import SpctumMicrosopy from './detailComponents/SpctumMicrosopy'
import LamTest from './detailComponents/LamTest'
import GeneExpert from './detailComponents/GeneExpert'
import FNAC from './detailComponents/FNAC'
import TuberculinSkin from './detailComponents/TuberculinSkin'
import SputumCulture from './detailComponents/SputumCulture'
import DrugSensitivity from './detailComponents/DrugSensitivity'
import TBConclusion from './detailComponents/TBConclusion'
import Truenat from './detailComponents/Truenat'
import ChestXray from './detailComponents/ChestXray'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import DotConclusion from './detailComponents/DotConclusion'
import { useHistory } from 'react-router-dom'
import Eligible from './detailComponents/Eligible'
import Accept from './detailComponents/Accept'
import { getPatient } from 'store/actions'

function PatientFill({ patient, getPatient }) {
  const router = useHistory()
  const [activeTab, setActiveTab] = React.useState('1')

  const { id } = useParams()
  useEffect(() => {
    // id &&
    getPatient(id)
  }, [])

  const handleShowTBConclusion = () => {
    const {
      sputum_done_or_not_done,
      chest_Xray_done_or_not_done,
      gene_Xpert_done_or_not_done,
      truenat_done_or_not_done,
      FNAC_done_or_not_done,
      tuberculin_skin_done_or_not_done,
      sputum_culture_done_or_not_done,
      drug_susceptibility_done_or_not_done,
      lamtest_done_or_not_done,
    } = patient.patient

    if (
      sputum_done_or_not_done != null &&
      chest_Xray_done_or_not_done != null &&
      gene_Xpert_done_or_not_done != null &&
      truenat_done_or_not_done != null &&
      FNAC_done_or_not_done != null &&
      tuberculin_skin_done_or_not_done != null &&
      sputum_culture_done_or_not_done != null &&
      drug_susceptibility_done_or_not_done != null &&
      lamtest_done_or_not_done != null
    ) {
      return true
    }
    return false
  }

  return (
    <div className="custom-tab-container mt-3">
      <BackBtn />
      <Card className="my-3">
        <CardHeader className="p-4">
          <Nav className="d-flex flex-wrap">
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '1',
                    'bg-success': patient.patient?.tb_contact,
                  }
                )}
                onClick={() => {
                  setActiveTab('1')
                }}
              >
                Presumptive Information
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '2',
                    'bg-success': patient.patient?.sputum_done_or_not_done,
                  }
                )}
                onClick={() => {
                  setActiveTab('2')
                }}
              >
                Sputum Microscopy
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '3',
                    'bg-success':
                      patient.patient?.chest_Xray_done_or_not_done !== null &&
                      patient.patient?.chest_Xray_done_or_not_done !==
                        undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('3')
                }}
              >
                Chest X-Ray
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '4',
                    'bg-success':
                      patient.patient?.lamtest_done_or_not_done !== null &&
                      patient.patient?.lamtest_done_or_not_done !== undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('4')
                }}
              >
                LAM Test
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '11',
                    'bg-success':
                      patient.patient?.truenat_done_or_not_done !== null &&
                      patient.patient?.truenat_done_or_not_done !== undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('11')
                }}
              >
                Truenat
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '5',
                    'bg-success':
                      patient.patient?.gene_Xpert_done_or_not_done !== null &&
                      patient.patient?.gene_Xpert_done_or_not_done !==
                        undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('5')
                }}
              >
                GENE XPERT
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '6',
                    'bg-success':
                      patient.patient?.FNAC_done_or_not_done !== null &&
                      patient.patient?.FNAC_done_or_not_done !== undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('6')
                }}
              >
                FNAC
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '7',
                    'bg-success':
                      patient.patient?.tuberculin_skin_done_or_not_done !=
                        null &&
                      patient.patient?.tuberculin_skin_done_or_not_done !==
                        undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('7')
                }}
              >
                Tuberculin Skin
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '8',
                    'bg-success':
                      patient.patient?.sputum_culture_done_or_not_done !=
                        null &&
                      patient.patient?.sputum_culture_done_or_not_done !==
                        undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('8')
                }}
              >
                Sputum Culture
              </Button>
            </NavItem>
            <NavItem className="mx-2 mb-3">
              <Button
                className={classnames(
                  'tab-background-color text-white border-0',
                  {
                    'bg-danger': activeTab === '9',
                    'bg-success':
                      patient.patient?.drug_susceptibility_done_or_not_done !=
                        null &&
                      patient.patient?.drug_susceptibility_done_or_not_done !==
                        undefined,
                  }
                )}
                onClick={() => {
                  setActiveTab('9')
                }}
              >
                Drug Sensitivity
              </Button>
            </NavItem>
            {id && handleShowTBConclusion() && (
              <NavItem className="mx-2 mb-3">
                <Button
                  className={classnames(
                    'tab-background-color text-white border-0',
                    {
                      'bg-danger': activeTab === '10',
                      'bg-success': patient.patient?.TB_status,
                    }
                  )}
                  onClick={() => {
                    setActiveTab('10')
                  }}
                >
                  TB Conclusion
                </Button>
              </NavItem>
            )}
            {(patient.patient?.TB_status === 'TB' ||
              patient.patient?.TB_status === 'MDR TB') && (
              <>
                <NavItem className="mx-2 mb-3">
                  <Button
                    className={classnames(
                      'tab-background-color text-white border-0',
                      {
                        'bg-danger': activeTab === '13',
                        'bg-success':
                          patient.patient?.VOT_eligible != null &&
                          patient.patient?.VOT_eligible !== undefined,
                      }
                    )}
                    onClick={() => {
                      setActiveTab('13')
                    }}
                  >
                    VOT Eligible
                  </Button>
                </NavItem>

                {patient.patient?.VOT_eligible === 'Yes' && (
                  <NavItem className="mx-2 mb-3">
                    <Button
                      className={classnames(
                        'tab-background-color text-white border-0',
                        {
                          'bg-danger': activeTab === '14',
                          'bg-success':
                            patient.patient?.VOT_accept != null &&
                            patient.patient?.VOT_accept !== undefined,
                        }
                      )}
                      onClick={() => {
                        setActiveTab('14')
                      }}
                    >
                      VOT Accept
                    </Button>
                  </NavItem>
                )}
              </>
            )}

            {(patient.patient?.TB_status === 'TB' ||
              patient.patient?.TB_status === 'MDR TB') &&
              patient.patient.VOT_eligible === 'Yes' &&
              patient.patient.VOT_accept === 'Yes' && (
                <NavItem className="mx-2 mb-3">
                  <Button
                    className={classnames('tab-background-color border-0 text-white', {
                      'bg-danger': activeTab === '15',
                     'bg-success': patient.patient?.DOT_start_date != null,
                    })}
                    onClick={() => {
                      router.push(`/admin/move-to-DOT/${id}`)
                    }}
                  >
                    Move to Dot
                  </Button>
                </NavItem>
              )}
            {(patient.patient?.TB_status === 'TB' ||
              patient.patient?.TB_status === 'MDR TB') && (
              <NavItem className="mx-2 mb-3">
                <Button
                  className={classnames(
                    'tab-background-color border-0 text-white',
                    {
                      'bg-danger': activeTab === '12',
                      'bg-success':
                        patient.patient?.DOT_end_date ||
                        patient.patient?.treatment_outcome,
                    }
                  )}
                  onClick={() => {
                    setActiveTab('12')
                  }}
                >
                  DOT Conclusion
                </Button>
              </NavItem>
            )}
          </Nav>
        </CardHeader>
        <CardBody className="px-5">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PresumptiveInfo setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="2">
              <SpctumMicrosopy setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="3">
              <ChestXray setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="4">
              <LamTest setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="11">
              <Truenat setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="5">
              <GeneExpert setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="6">
              <FNAC setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="7">
              <TuberculinSkin setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="8">
              <SputumCulture setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="9">
              <DrugSensitivity setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="10">
              <TBConclusion />
            </TabPane>
            <TabPane tabId="12">
              <DotConclusion setActiveTab={setActiveTab} />
            </TabPane>
            <TabPane tabId="13">
              <Eligible />
            </TabPane>
            <TabPane tabId="14">
              <Accept />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  )
}
const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
})

export default connect(mapStateToProps, { getPatient })(PatientFill)
