import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import { updateRemark } from "store/actions";
import CustomTextArea from "components/Inputs/CustomTextArea";

const DOTWithAppRemark = ({ isOpen, toggle, status, updateRemark, DOT, patient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const { remark } = DOT.DOT;
    setValue("remark", remark);
  }, [setValue, DOT.DOT]);

  const onSubmit = async (data) => {
    setLoading(true);
    data.patient_id = patient.patient?.id;
    await updateRemark(DOT?.DOT?.id, data);
    setLoading(false);
    toggle(false)
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="DOT Remark"
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ maxWidth: "40%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body d-flex flex-wrap">
          <Col>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register("remark"),
              }}
              placeholder="Remark"
              errors={errors}
            />
          </Col>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  DOT: store.DOT,
  patient: store.patient,
});

export default connect(mapStateToProps, { updateRemark })(DOTWithAppRemark);
