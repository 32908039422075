import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Col,
  Card,
  CardBody,
  Table,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { dotVisitDate } from "variables/options";
import { phases } from "variables/options";
import dayjs from "dayjs";
import classNames from "classnames";
import BackBtn from "utils/backBtn";
import { NotificationManager } from "react-notifications";
import { createDOT } from "store/actions";
import { useHistory, useParams } from "react-router-dom";
import { call } from "services/api";
import moment from "moment";

const DOTVisitDateCreate = ({ status, createDOT }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch
  } = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const start_date = watch('start_date');
  const phase = watch('phase');
  const month = watch('month');

  const [lastDotDate, setLastDotDate] = useState(null);
  const router = useHistory();
  const [show, setShow] = useState(false);
  const [dates, setDates] = useState(null);
  const [dotDates, setDotDates] = useState(null);

  useEffect(() => {
    getLastDotDate();
  }, []);


  const getLastDotDate = async () => {
    const response = await call("get", `last-DOTs-date/${id}`);
    const date = moment(response.lastDotDate);
    const newDate = date.add(1, "days");
    setLastDotDate(dayjs(newDate).format("YYYY-MM-DD"));
  };

  const onSubmit = (data) => {
    getDates(data?.start_date);
    getDotDates();
  };
  const tempDates = [];

  const getDates = (tempDate) => {
    // let tempDate = start_date;
    Array.from(Array(month), (x, m) => {
      tempDates[m] = [];
      Array.from(Array(7), (x, i) => {
        let date = moment(tempDate);
        let newDate = date.add(i, "days");
        let toAddNewDate = dayjs(newDate).format("YYYY-MM-DD");
        tempDates[m].push(
          toAddNewDate
        );
      });
      let temp = moment(tempDates[m][tempDates[m].length - 1]);
      let newTemp = temp.add(1, "days");
      tempDate = dayjs(newTemp).format("YYYY-MM-DD");
    });
    setDates(tempDates);
    setShow(true);
  };

  const getDotDates = () => {
    let tempDotDates = [];
    Array.from(Array(month), (x, m) => {
      tempDotDates[m] = [];
      return Array.from(
        Array(
          //phase === "Initial Phase" ? 5 : m === 3 || m === 7 || m === 11 ? 1 : 2
          phase === "Initial Phase" ? 5 : 3
        ),
        (x, i) => {
          let day = tempDates[m][i + 1];
          return tempDotDates[m].push(day);
        }
      );
    });
    setDotDates(tempDotDates);
    setShow(true);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  const clearAll = () => {
    setShow(false);
  };
  const checkDotDates = () => {
    let status;
    Array.from(Array(month), (x, m) => {
      if (phase === "Initial Phase") {
        if (dotDates[m].length < 5) {
          NotificationManager.error("Please choose 5 dates a week");
          status = false;
        }
      } else {
        // if (m === 3 || m === 7 || m === 11) {
        //   if (dotDates[m].length < 1) {
        //     NotificationManager.error("Please choose 7 dates a month");
        //     status = false;
        //   }
        // } else {
          // if (dotDates[m].length !== 2) {
          //   NotificationManager.error("Please choose 7 dates a month");
          //   status = false;
          // }
          if (dotDates[m].length < 3) {
            NotificationManager.error("Please choose 3 dates a week");
            status = false;
          }
        }
      // }
    });

    return status === false ? false : true;
  };
  const getDatesArray = async () => {
    let dates = [];
    let week = 0;

    while (week < dotDates.length) {
      let day = 0;
      while (day < dotDates[week].length) {
        dates.push(dotDates[week][day]);
        day++;
      }
      week++;
    }
    return dates;
  };
  const saveDotDates = async () => {
    const dates = await getDatesArray();
    setLoading(true);
    await createDOT({ patient_id: id, dates: dates });
    setLoading(false);
    router.replace(`/admin/dot-visit-date-app/${id}`);
  };

  return (
    <Container fluid className="mt-3">
      <BackBtn />
      <Row>
        <div className="col-12 d-flex justify-content-center flex-wrap">
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="col-10 d-flex justify-content-evenly mb-5"
          >
            <Col md={3}>
              <CustomDateInput
                id="start_date"
                label="Start Date"
                register={{
                  ...register("start_date", {
                    required: "Start date is required!",
                  }),
                }}
                placeholder="Chest XRay date"
                errors={errors}
                min={lastDotDate ? lastDotDate : "1950-01-01"}
                max="2100-12-31"
                disabled={show}

              />
            </Col>
            <Col md={3}>
              <SelectBox
                label="Month"
                control={control}
                name="month"
                options={dotVisitDate}
                rules={{ required: true }}
                disabled={show}

              />
            </Col>
            <Col md={3}>
              <SelectBox
                label="Phase"
                control={control}
                name="phase"
                options={phases}
                rules={{ required: true }}
                disabled={show}

              />
            </Col>

            <Col className="align-items-center d-flex" md={3}>
              <Button
                color="success"
                type="submit"
                disabled={show}
                className="btn btn-sm"
              >
                {loading ? "Loading" : "Generate"}
              </Button>
              <Button color="danger" className="btn btn-sm" onClick={clearAll}>
                Clear
              </Button>
            </Col>
          </Form>
          {show && (
            <Card className="col-12 p-3">
              <div className="text-center">
                {phase === null ? (
                  ""
                ) : phase === "Initial Phase" ? (
                  <h5 className="text-danger">5 times per week</h5>
                ) : (
                  <h5 className="text-danger">3 times per week</h5>
                )}
              </div>
              <CardBody>
                <Table>
                  <tbody className="d-flex flex-wrap justify-content-start px-5 border-0">
                    {dates &&
                      Array.from(Array(month), (x, m) => {
                        return (
                          <tr key={m}>
                            <th className="py-4">{`Week ${m + 1}`}</th>
                            {dates[m].map((d, i) => {
                              return (
                                <td
                                  className={classNames("py-4", {
                                    "bg-primary text-white":
                                      dotDates &&
                                      dotDates[m]?.find((dd) => dd === d),
                                  })}
                                  key={i}
                                  onClick={() => {
                                    if (dotDates[m]?.find((dd) => dd === d)) {
                                      dotDates[m] = dotDates[m]?.filter((i) => {
                                        return i !== d;
                                      });
                                    } else {
                                      if (
                                        phase === "Initial Phase"
                                          ? dotDates[m].length <= 4
                                          : dotDates[m].length <= 2
                                          // m !== 3 && m !== 7 && m !== 11
                                          // ? dotDates[m].length <= 1
                                          // : dotDates[m].length <= 0
                                      ) {
                                        dotDates[m].push(d);
                                      } else {
                                        NotificationManager.error(
                                          "Please choose after removing a date"
                                        );
                                      }
                                    }
                                    setDotDates(dotDates);
                                    getDates(start_date);
                                  }}
                                >
                                  {d}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <div className="mt-5 d-flex justify-content-center">
                  <Button
                    color="success"
                    onClick={() => {
                      if (checkDotDates()) saveDotDates();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          )}
        </div>
      </Row>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
});

export default connect(mapStateToProps, { createDOT })(DOTVisitDateCreate);
