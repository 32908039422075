import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import { geneExperts } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import {  updatePatient } from "store/actions";
import { doneOrNotDone } from "variables/options";

const GeneExpert = ({ patient, auth,  updatePatient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const isDone = watch("gene_Xpert_done_or_not_done");
  useEffect(() => {
    if (patient.patient) {
      const {
        gene_Xpert_done_or_not_done,
        gene_Xpert_examination_date,
        gene_Xpert_result,
      } = patient.patient;

      setValue("gene_Xpert_done_or_not_done", gene_Xpert_done_or_not_done);
      setValue("gene_Xpert_examination_date", gene_Xpert_examination_date);
      setValue("gene_Xpert_result", gene_Xpert_result);
    }
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);

    await updatePatient(patient.patient.id, {
      gene_Xpert_done_or_not_done:
        data.gene_Xpert_done_or_not_done ,
      gene_Xpert_examination_date:
        isDone === "1" ? data.gene_Xpert_examination_date : null,
      gene_Xpert_result: isDone === "1" ? data.gene_Xpert_result : null,
    },
    'gene-Xpert',
    'Gene-Xpert');

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4}>
            <SelectBox
              label="Gene Xpert done"
              control={control}
              name="gene_Xpert_done_or_not_done"
              options={doneOrNotDone}
              rules={{ required: true }}
            />
          </Col>

          
        </Row>
        <Row>
        {isDone === "1" && (
            <>
              <Col md={4}>
                <CustomDateInput
                  id="gene_Xpert_examination_date"
                  label=" Date of Gene Expert"
                  register={{
                    ...register("gene_Xpert_examination_date", {
                      required: "Gene Expert date is required!",
                    }),
                  }}
                  placeholder="Gene Expert date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col>
              <Col md={4}>
                <SelectBox
                  label="Result of Gene Expert"
                  control={control}
                  name="gene_Xpert_result"
                  options={geneExperts}
                  rules={{ required: true }}
                />
              </Col>
            </>
          )}
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(GeneExpert);
