import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import CustomTextArea from "components/Inputs/CustomTextArea";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { createNoVolunteerDOT} from 'store/actions'

const NoVolunteerDotcreateNoVolunteerDOT = ({
  isOpen,
  toggle,
  status,
  patient,
  createNoVolunteerDOT
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    data.patient_id = patient.patient?.id;
    await createNoVolunteerDOT(data);
    setLoading(false);
    toggle(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="New DOT"
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ maxWidth: "40%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body d-flex flex-wrap flex-column px-5">
          <CustomDateInput
            id="date"
            label=" Date"
            register={{
              ...register("date", {
                required: "date is required!",
              }),
            }}
            placeholder="date"
            errors={errors}
            min="1950-01-01"
            max="2100-12-31"
          />
          <CustomTextArea
            id="remark"
            label="Remark"
            register={{
              ...register("remark"),
            }}
            placeholder="Remark"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  DOT: store.DOT,
  patient: store.patient,
});

export default connect(mapStateToProps, { createNoVolunteerDOT})(
  NoVolunteerDotcreateNoVolunteerDOT
);
