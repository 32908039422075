import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getPatientVisualization } from "store/actions";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import LineChart from "components/charts/LineChart";
import PieChart from "components/charts/PieChart";
import { tbTypeLabels } from "components/charts/constant";
import { treatmentRegimenLabels } from "components/charts/constant";
import { outcomeLabels } from "components/charts/constant";
import { typeOfTBPatientLabels } from "components/charts/constant";
import { months } from "components/charts/constant";
import { caseFoundActivityLabels } from "components/charts/constant";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import BarChart from "components/charts/BarChart";
import {stackedBarCharConfig} from 'utils/chartConfig'
import { primaryColor } from "components/charts/constant";
import { secondaryColor } from "components/charts/constant";

const PatientVisualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, visualization, township, auth } = useSelector(
    (state) => state
  );
  const { patientVisualization } = visualization;
  const {
    all_patients,
    TB_patients,
    by_case_founded_activity,
    by_tb_type,
    by_treatment_regimen,
    by_outcome,
    by_type_of_tb_patient,
  } = patientVisualization;

  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getPatientVisualization(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }
    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const all_patients_line = {
    labels: all_patients?.data?.map((data) => data.month),
    datasets: [
      {
        label: "All patients",
        data: all_patients?.data?.map((data) => data.total),
        fill: false,
        borderColor: "green",
        borderWidth: 1,
      },
      {
        label: "TB Patients",
        data: TB_patients?.data?.map((data) => data.total),
        fill: false,
        borderColor: "red",
        tension: 0.1,
      },
    ],
  };
  //  const TB_patients_line = {
  //    labels: TB_patients?.data?.map((data) => data.month),
  //    datasets: [
  //      {
  //        label: "TB Patients",
  //        data: TB_patients?.data?.map((data) => data.total),
  //        fill: false,
  //        borderColor: "rgb(75, 192, 192)",
  //        tension: 0.1,
  //      },
  //    ],
  //  };

  const by_case_founded_activity_bar = {
    labels: caseFoundActivityLabels,
    datasets: [
      {
        label: "TB",
        data: by_case_founded_activity?.data?.map((data) => data.tb),
        backgroundColor: primaryColor,
      },
      {
        label: "Referred",
        data: by_case_founded_activity?.data?.map((data) => data.no_tb),
        backgroundColor: secondaryColor,
      },
    ],
  };

  const by_tb_type_pie = {
    labels: tbTypeLabels,
    datasets: [
      {
        label: "By TB Type",
        data: [
          by_tb_type?.pulmonaryBC,
          by_tb_type?.extraPulmonaryBC,
          by_tb_type?.pulmonaryCD,
          by_tb_type?.extraPulmonaryCD,
        ],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(255, 86, 187)",
        ],
        hoverOffset: 4,
      },
    ],
  };
  const by_treatment_regimen_pie = {
    labels: treatmentRegimenLabels,
    datasets: [
      {
        label: "By TB Type",
        data: [
          by_treatment_regimen?.IR,
          by_treatment_regimen?.RR,
          by_treatment_regimen?.CR,
          by_treatment_regimen?.MDRTB,
        ],
        backgroundColor: [
          "rgb(255, 234, 99)",
          "rgb(54, 162, 235)",
          "rgb(255, 86, 86)",
          "rgb(86, 255, 125)",
        ],
        hoverOffset: 4,
      },
    ],
  };
  const by_outcome_pie = {
    labels: outcomeLabels,
    datasets: [
      {
        label: "By TB Type",
        data: [
          by_outcome?.cured,
          by_outcome?.completed,
          by_outcome?.died,
          by_outcome?.lost,
          by_outcome?.failed,
          by_outcome?.moved,
          by_outcome?.notEvaluated,
        ],
        backgroundColor: [
          "#FF6384", // Red
          "#36A2EB", // Blue
          "#FFCE56", // Yellow
          "#4BC0C0", // Turquoise
          "#9966FF", // Purple
          "#FF9F40", // Orange
          "#33FF99", // Green
        ],
        hoverOffset: 4,
      },
    ],
  };
  const by_type_of_tb_patient_pie = {
    labels: typeOfTBPatientLabels,
    datasets: [
      {
        label: "By TB Type",
        data: [
          by_type_of_tb_patient?.new,
          by_type_of_tb_patient?.relapse,
          by_type_of_tb_patient?.afterFailure,
          by_type_of_tb_patient?.afterLost,
          by_type_of_tb_patient?.treated,
          by_type_of_tb_patient?.unknown,
        ],
        backgroundColor: [
          "#FF6384", // Red
          "#36A2EB", // Blue
          "#FFCE56", // Yellow
          "#4BC0C0", // Turquoise
          "#9966FF", // Purple
          "#FF9F40", // Orange
        ],
        hoverOffset: 4,
      },
    ],
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Patients Data Visualization</CardTitle>
          <Row className="mt-3">
            {!isVolunteerSupervisor(role) && (
              <Col sm={4} className="mt-4">
                <Select
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}

            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>

            <Col sm={1} className="mt-4">
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <LineChart
                title={"All Patients"}
                data={all_patients_line}
                total={all_patients?.total}
                uniqueChartClassName={"all_patients"}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <LineChart
                title={"TB Patients"}
                data={TB_patients_line}
                total={TB_patients?.total}
                uniqueChartClassName={"TB_patients"}
              />
            </Col>
          </Row> */}
          <Row>
            <Col>
              <BarChart
                title={"By Patient case founded activity"}
                data={by_case_founded_activity_bar}
                total={by_case_founded_activity?.total}
                uniqueChartClassName={"by_case_founded_activity"}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <PieChart
                title={"By Patients' TB Types"}
                data={by_tb_type_pie}
                total={by_tb_type?.total}
                uniqueChartClassName={"by_tb_type"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieChart
                title={"By Patients' Treatment Regimen"}
                data={by_treatment_regimen_pie}
                total={by_treatment_regimen?.total}
                uniqueChartClassName={"by_treatment_regimen"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieChart
                title={"By Patients' Treatment Outcome"}
                data={by_outcome_pie}
                total={by_outcome?.total}
                uniqueChartClassName={"by_outcome"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieChart
                title={"By Patients' Types"}
                data={by_type_of_tb_patient_pie}
                total={by_type_of_tb_patient?.total}
                uniqueChartClassName={"by_type_of_tb_patient"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default PatientVisualization;
