const CACHE_NAME = 'MATA_Myanmar_2022'

export const setToken = (token) => localStorage.setItem(CACHE_NAME, token)

export const getToken = () => localStorage.getItem(CACHE_NAME)

export const removeToken = () => localStorage.removeItem(CACHE_NAME)

// export function storeCache(name, value) {
//   localStorage.setItem(`${CACHE_NAME}${name}`, value);
// }

// export function getCache(name) {
//   return localStorage.getItem(`${CACHE_NAME}${name}`);
// }

// export function removeCache(name) {
//   return localStorage.removeItem(`${CACHE_NAME}${name}`);
// }