import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Label, FormGroup } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import DatePicker from "components/Inputs/DatePicker";
import SelectBox from "components/Selectbox";
import { createTBCI } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import { CBTBCPatientTypes } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { sexs } from "variables/options";
import { call } from "services/api";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { dataUrlToFile } from "utils/fileConverter";
import { MdOutlineClear } from "react-icons/md";

const TBCICreate = ({
  isOpen,
  toggle,
  status,
  createTBCI,
  auth,
  township,
  volunteer,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    setValue,
  } = useForm();

  const { role } = auth.user;
  const [loading, setLoading] = useState(false);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [chooseTownship, setChooseTownship] = useState(false);
  const [volunteers, setVolunteers] = useState([]);
    const inputFile = useRef(null);
    const [image, setImage] = useState(null);

  useEffect(() => {
    if (isVolunteerSupervisor(role)) {
      const volunteers = volunteer.volunteers?.map((element) => ({
        value: element.id,
        label: `${element.name} (${element.code})`,
      }));
      setVolunteers(volunteers);
      setChooseTownship(true);
    }
    return () => {
      setChooseTownship(false);
      setVolunteers([]);
    };
  }, [role, volunteer.volunteers]);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const setVolunteer = async (township_id) => {
    const result = await call(
      "get",
      `volunteers?${new URLSearchParams({
        township_id: township_id,
      }).toString()}`
    );
    const volunteers = result.data?.map((element) => ({
      value: element.id,
      label: element.name,
    }));
    setChooseTownship(true);
    setVolunteers(volunteers);
  };
  const onSubmit = async (data) => {
    setLoading(true);
     const formData = new FormData();
     formData.append("date", data.date);
     formData.append("tb_patient_code", data.tb_patient_code);
     formData.append("volunteer_id", data.volunteer_id);
     formData.append("name", data.name);
     formData.append("male", data.male);
     formData.append("female", data.female);
     formData.append("address", data.address);
     formData.append("age", data.age);
     formData.append("tb_type", data.tb_type);
     formData.append("sex", data.sex);
     formData.append("remark", data.remark);
     if (image) {
       const imageFile = await dataUrlToFile(image);
       formData.append("image", imageFile);
     }

   await createTBCI(formData);
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
       toggle(false);
    }
  }, [status.success, reset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="လူနာနှင့်မိသားစုအတွင်း ကျန်းမာရေးအသိပညာပေးခြင်း စာရင်းအသစ်"
      onClick={() => {
        toggle(false);
        reset();
      }}
      style={{ minWidth: "70%" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body d-flex flex-wrap">
          <Col md={4}>
            <CustomDateInput
              id="Date"
              label="Date"
              register={{
                ...register("date"),
              }}
              errors={errors}
              isRequired={true}
            />
          </Col>
          {!isVolunteerSupervisor(role) && (
            <Col md={4}>
              <SelectBox
                label="Township"
                control={control}
                rules={{ required: true }}
                name="township_id"
                options={townships}
                onValueChange={(val) => {
                  if (!val) {
                    setChooseTownship(false);
                    setValue("volunteer_id", null);
                  } else {
                    setVolunteer(val);
                  }
                }}
              />
            </Col>
          )}
          <Col md={4}>
            <SelectBox
              label="Volunteer"
              control={control}
              rules={{ required: true }}
              name="volunteer_id"
              options={volunteers}
              disabled={!chooseTownship}
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="tb_patient_code"
              label="TB Code"
              register={{
                ...register("tb_patient_code", {
                  required: "TB  Code is required!",
                }),
              }}
              placeholder="TB Code"
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="name"
              label="Name"
              register={{
                ...register("name", {
                  required: "Name is required!",
                }),
              }}
              errors={errors}
              placeholder="Name"
            />
          </Col>
          <Col md={4}>
            <CustomInput
              id="age"
              label="Age"
              register={{
                ...register("age", {
                  required: "Age is required!",
                }),
              }}
              errors={errors}
              type="number"
              max="99"
              placeholder="Age"
            />
          </Col>
          <Col md={4}>
            <SelectBox
              label="Gender"
              control={control}
              name="sex"
              options={sexs}
              rules={{ required: true }}
            />
          </Col>
          <Col md={4}>
            <CustomTextArea
              id="address"
              label="Address"
              register={{
                ...register("address", {
                  required: "address is required!",
                }),
              }}
              errors={errors}
              placeholder="Address"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <Label>Attendent List</Label>
            <div className="d-flex">
              <Col>
                <CustomInput
                  register={{
                    ...register("male", {
                      required: "တတ်ရောက်သူ ကျား အရေအတွက် is required!",
                    }),
                  }}
                  errors={errors}
                  type="number"
                  max="99"
                  min="0"
                  isRequired={false}
                  placeholder="male"
                  onChanged={(e) => {
                    e.target.value !== ""
                      ? setMale(parseInt(e.target.value))
                      : setMale(0);
                  }}
                />
              </Col>
              <Col>
                <CustomInput
                  register={{
                    ...register("female", {
                      required: "တတ်ရောက်သူ မ အရေအတွက် is required!",
                    }),
                  }}
                  errors={errors}
                  type="number"
                  max="99"
                  min="0"
                  isRequired={false}
                  placeholder="female"
                  onChanged={(e) => {
                    e.target.value !== ""
                      ? setFemale(parseInt(e.target.value))
                      : setFemale(0);
                  }}
                />
              </Col>
              <Col className="d-flex flex-column">
                <Label>Total</Label>
                <span>{male + female}</span>
              </Col>
            </div>
          </Col>
          <Col md={4}>
            <SelectBox
              label="TB Types"
              control={control}
              name="tb_type"
              options={CBTBCPatientTypes}
              rules={{ required: true }}
            />
          </Col>
          <Col md={4}>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register("remark", {
                  required: false,
                }),
              }}
              placeholder="Remark"
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              {image && (
                <MdOutlineClear
                  size="25"
                  onClick={() => {
                    setImage(null);
                  }}
                />
              )}
              <div className="mt-2">
                {image ? (
                  <img
                    src={image}
                    style={{ width: 150, height: 150 }}
                    onClick={() => {
                      inputFile.current.click();
                    }}
                    alt=""
                  />
                ) : (
                  <div
                    onClick={() => {
                      inputFile.current.click();
                    }}
                    style={{ width: 150, height: 150, background: "#c3b7b7" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h4>Upload+</h4>
                  </div>
                )}
              </div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setImage(URL.createObjectURL(event.target.files[0]));
                  }
                }}
                accept="image/*"
              />
            </FormGroup>
          </Col>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  township: store.township,
  auth: store.auth,
});

export default connect(mapStateToProps, { createTBCI })(TBCICreate);
