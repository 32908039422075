import styles from './NTPCBTBCTemplate.module.css'

const NTPCBTBTableHead=()=> {
    return (
        <thead className="thead-light">
        <tr>
          <th
            className={['fixed-cell left', styles.purple].join(' ')}
            rowSpan={2}
          >
            No
          </th>
          <th
            className={['fixed-cell left', styles.purple].join(' ')}
            rowSpan={2}
          >
            Organization
          </th>
          <th className={styles.purple} rowSpan={2}>
            Funding source
          </th>
          <th className={styles.purple} rowSpan={2}>
            Type of project
          </th>
          <th className={styles.purple} rowSpan={2}>
            Region/State
          </th>
          <th className={styles.purple} rowSpan={2}>
            Township
          </th>
          <th className={styles.purple} rowSpan={2}>
            Year
          </th>
          <th className={styles.purple} rowSpan={2}>
            Quarter
          </th>
          <th
            className={[styles.purple, styles.width].join(' ')}
            colSpan={3}
          >
            No of CHVs trained (A)
          </th>
          <th
            className={[styles.purple, styles.width].join(' ')}
            colSpan={3}
          >
            No of reported CHVs (active CHVs) (B)
          </th>
          <th
            className={[styles.skyblue, styles.width].join(' ')}
            colSpan={3}
          >
            No of presumptive TB cases referred in the community ©
          </th>
          <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
            No of TB patients detected among presumptive TB referred in the
            community (D)
          </th>
          <th
            className={[styles.skyblue, styles.width].join(' ')}
            colSpan={3}
          >
            No of presumptive TB cases referred among family member by
            contact tracing (E)
          </th>
          <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
            No of TB patients detected by contact tracing (F)
          </th>
          <th className={[styles.skyblue, styles.width].join(' ')}>
            Total No. of presumptive TB case referral (C+E)
          </th>
          <th className={[styles.red, styles.width].join(' ')}>
            No. of total TB patients among Presemptive TB referred (D+F)
          </th>
          <th
            className={[styles.purple, styles.width].join(' ')}
            colSpan={3}
          >
            No of TB patients who initiated DOT provided by volunteer (G)
          </th>
          <th
            className={[styles.purple, styles.width].join(' ')}
            colSpan={3}
          >
            No of TB patients completed treatment in same quarter of the
            previous year (H)
          </th>
          <th className={[styles.purple, styles.width].join(' ')}>
            Total Number of HE sessions held (I)
          </th>
          <th
            className={[styles.purple, styles.width].join(' ')}
            colSpan={3}
          >
            No . of attendees in HE sessions (J)
          </th>
          
          <th className={[styles.purple, styles.width].join(' ')}>
            No of supervisory visits conducted by supervisors (M)
          </th>
          <th
            className={[styles.skyblue, styles.width].join(' ')}
            colSpan={3}
          >
            No of presumptive TB cases referred by volunteers to mobile team
            visit (N)
          </th>

          <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
            No of TB patients detected among presumptive TB referred by
            volunteers to mobile team visit (O)
          </th>
          <th
            className={[styles.skyblue, styles.width].join(' ')}
            colSpan={3}
          >
            No of presumptive TB cases referred by volunteers to PPM clinics
            (P)
          </th>
          <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
            No of TB patients detected among presumptive TB referred by
            volunteers to PPM Clinics (Q)
          </th>
        </tr>
        <tr>
          <th className={styles.purple}>Male</th>
          <th className={styles.purple}>Female</th>
          <th className={styles.purple}>Total</th>
          <th className={styles.purple}>Male</th>
          <th className={styles.purple}>Female</th>
          <th className={styles.purple}>Total</th>
          <th className={styles.skyblue}>Male</th>
          <th className={styles.skyblue}>Female</th>
          <th className={styles.skyblue}>Total</th>
          <th className={styles.red}>Male</th>
          <th className={styles.red}>Female</th>
          <th className={styles.red}>Total</th>
          <th className={styles.skyblue}>Male</th>
          <th className={styles.skyblue}>Female</th>
          <th className={styles.skyblue}>Total</th>
          <th className={styles.red}>Male</th>
          <th className={styles.red}>Female</th>
          <th className={styles.red}>Total</th>
          <th className={styles.skyblue}>Total</th>
          <th className={styles.red}>Total</th>
          <th className={styles.purple}>Male</th>
          <th className={styles.purple}>Female</th>
          <th className={styles.purple}>Total</th>
          <th className={styles.purple}>Male</th>
          <th className={styles.purple}>Female</th>
          <th className={styles.purple}>Total</th>
          <th className={styles.purple}>Total</th>
          <th className={styles.purple}>Male</th>
          <th className={styles.purple}>Female</th>
          <th className={styles.purple}>Total</th>
          
          <th className={styles.purple}>Total</th>
          <th className={styles.skyblue}>Male</th>
          <th className={styles.skyblue}>Female</th>
          <th className={styles.skyblue}>Total</th>
          <th className={styles.red}>Male</th>
          <th className={styles.red}>Female</th>
          <th className={styles.red}>Total</th>
          <th className={styles.skyblue}>Male</th>
          <th className={styles.skyblue}>Female</th>
          <th className={styles.skyblue}>Total</th>
          <th className={styles.red}>Male</th>
          <th className={styles.red}>Female</th>
          <th className={styles.red}>Total</th>
        </tr>
      </thead>
    )
}
export default NTPCBTBTableHead