import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import BarChart from "components/charts/BarChart";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import { getActivityVisualization } from "store/actions";
import { tbciLabels } from "components/charts/constant";
import PieChart from "components/charts/PieChart";
import { primaryColor } from "components/charts/constant";
import { secondaryColor } from "components/charts/constant";
import { comboChartConfig } from "utils/chartConfig";
import LineChart from "components/charts/LineChart";
import { missDoseResults } from "variables/options";

const ActivityVisualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, visualization, township, auth } = useSelector(
    (state) => state
  );
  const { activityVisualization } = visualization;
  const { hes, tbci, by_tbci_type, by_months_hes, by_months_tbci, by_months_supervision , miss_dose} = activityVisualization;

  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getActivityVisualization(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const hes_bar = {
    labels: hes?.data?.map((row) => row.township_name),
    datasets: [
      {
        label: "session",
        data: hes?.data?.map((data) => data.session),
        backgroundColor: primaryColor,
      },
      {
        label: "attendent",
        data: hes?.data?.map((data) => data.attendent),
        backgroundColor: secondaryColor,
      },
     
    ],
  };
  const by_months_hes_bar = {
    labels: by_months_hes?.data?.map((data) => data.month),
    datasets: [
      {
        label: "session",
        data: by_months_hes?.data?.map((data) => data.session),
        backgroundColor: primaryColor,
      },
      {
        label: "attendent",
        data: by_months_hes?.data?.map((data) => data.attendent),
        backgroundColor: secondaryColor,
      },
    ],
  };

  const tbci_bar = {
    labels: tbci?.data?.map((row) => row.township_name),
    datasets: [
      {
        label: "session",
        data: tbci?.data?.map((data) => data.session),
        backgroundColor: primaryColor,
      },
      {
        label: "attendent",
        data: tbci?.data?.map((data) => data.attendent),
        backgroundColor: secondaryColor,
      },
    ],
  };
  const by_months_tbci_bar = {
    labels: by_months_tbci?.data?.map((data) => data.month),
    datasets: [
      {
        label: "session",
        data: by_months_tbci?.data?.map((data) => data.session),
        backgroundColor: primaryColor,
      },
      {
        label: "attendent",
        data: by_months_tbci?.data?.map((data) => data.attendent),
        backgroundColor: secondaryColor,
      },
    ],
  };

   const by_tbci_type_pie = {
     labels: tbciLabels,
     datasets: [
       {
         label: "By TBCI TB Type",
         data: [
           by_tbci_type?.virus_tb,
           by_tbci_type?.hiv_tb,
           by_tbci_type?.less_5_tb,
           by_tbci_type?.other_tb,
         ],
         backgroundColor: [
           "rgb(255, 99, 132)",
           "rgb(54, 162, 235)",
           "rgb(255, 205, 86)",
           "rgb(255, 86, 187)",
         ],
         hoverOffset: 4,
       },
     ],
   };

   const by_months_supervision_bar = {
    labels: by_months_supervision?.data?.map((data) => data.month),
    datasets: [
      {
        label: "Supervision visits",
        data: by_months_supervision?.data?.map((data) => data.quantity),
        fill: false,
        borderColor: "red",
        borderWidth: 1,
      },
    ],
  };

  const miss_dose_pie = {
    labels: ['Ongoing', ...missDoseResults?.map((result) => result?.label)],
    datasets: [
      {
        label: "Miss Dose Activity",
        data: [
          miss_dose?.ongoing,
          miss_dose?.result_1, 
          miss_dose?.result_2, 
          miss_dose?.result_3, 
          miss_dose?.result_4, 
          miss_dose?.result_5, 
          miss_dose?.result_6, 
          miss_dose?.result_7, 
          miss_dose?.result_8, 
        ],
        backgroundColor: [
          "#1f77b4", // Blue
          "#ff7f0e", // Orange
          "#2ca02c", // Green
          "#d62728", // Red
          "#9467bd", // Purple
          "#8c564b", // Brown
          "#e377c2", // Pink
          "#7f7f7f", // Gray
          "#bcbd22", // Yellow
        ],
        hoverOffset: 4,
      },
    ],
  };


  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Activity Data Visualization</CardTitle>
          <Row className="mt-3">
            {!isVolunteerSupervisor(role) && (
              <Col sm={4} className="mt-4">
                <Select
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}

            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>

            <Col sm={1} className="mt-4">
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <BarChart
                title={"HE activity"}
                data={hes_bar}
                total={hes?.total}
                uniqueChartClassName={"he_activity"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"HE activity by months"}
                data={by_months_hes_bar}
                total={by_months_hes?.total}
                uniqueChartClassName={"by_month_he_activity"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"TBCI activity"}
                data={tbci_bar}
                total={tbci?.total}
                uniqueChartClassName={"TBCI_activity"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"TBCI activity by months"}
                data={by_months_tbci_bar}
                total={by_months_tbci?.total}
                uniqueChartClassName={"by_month_tbci_activity"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieChart
                title={"By TBCI TB type"}
                data={by_tbci_type_pie}
                total={tbci?.total}
                uniqueChartClassName={"by_tbci_type"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LineChart
                title={"Number Of Supervision Visit"}
                data={by_months_supervision_bar}
                total={by_months_supervision?.total}
                uniqueChartClassName={"by_month_tbci_activity"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieChart
                title={"Miss Dose / LOF Activity"}
                data={miss_dose_pie}
                total={miss_dose?.total}
                uniqueChartClassName={"miss_dose"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ActivityVisualization;
