import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updatePatientMedicine, getMedicines } from "store/actions";
import SelectBox from "components/Selectbox";

const PatientMedicineUpdate = ({
  isOpen,
  toggle,
  status,
  medicine,
  patientMedicine,
  updatePatientMedicine,
}) => {
  const { id } = useParams();
  const { control, handleSubmit, reset, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const medicines = medicine.medicines?.map((element) => ({
    value: element.name,
    label: element.name + "(" + element.acronym + ")",
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    const chosenMedicine = medicine.medicines?.find(
      (m) => m.name === data.name
    );
    await updatePatientMedicine(patientMedicine.patientMedicine?.id, {
      patient_id: id,
      name: chosenMedicine.name,
      acronym: chosenMedicine.acronym,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [reset, status.success]);

  useEffect(() => {
    setValue("name", patientMedicine.patientMedicine?.name);
  }, [patientMedicine.patientMedicine]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Patient's Medicine"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <SelectBox
            rules={{ required: true }}
            label="Select Medicine"
            control={control}
            options={medicines}
            name="name"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patientMedicine: store.patientMedicine,
  medicine: store.medicine,
});

export default connect(mapStateToProps, {
  updatePatientMedicine,
  getMedicines,
})(PatientMedicineUpdate);
