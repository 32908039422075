import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPatient, createChangeVolunteer, getVolunteers } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { yesOrNo, DOTTypes } from 'variables/options'
import CustomSelect from 'components/Inputs/CustomSelect'
import { currentDate } from 'utils/currentDate'
import BackBtn from 'utils/backBtn'
import { patientMiddleware } from 'utils/middleware'
import { CREATE } from 'utils/middleware'
import { clearPatientData } from 'store/actions'

const ChangeVolunteerCreate = ({
  status,
  patient,
  volunteer,
  getPatient,
  createChangeVolunteer,
  getVolunteers,
  clearPatientData,
  auth,
}) => {
  const { control, handleSubmit, setValue } = useForm()
  const history = useHistory()
  const { id } = useParams()
  const { role } = auth.user

  const [loading, setLoading] = useState(false)
  const [volOrNoVol, setVolOrNoVol] = useState(null)
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)
  const [DOTType, setDOTType] = useState(null)

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    const { volunteer, type } = patient.patient
    setValue('volOrNoVol', volunteer ? { value: 'Yes', label: 'Yes' } : null)
    setVolOrNoVol(volunteer ? { value: 'Yes', label: 'Yes' } : null)
    setValue(
      'volunteer',
      volunteer ? { value: volunteer?.id, label: volunteer?.name } : null
    )
    setSelectedVolunteer(
      volunteer ? { value: volunteer?.id, label: volunteer?.name } : null
    )
    setValue('DOTType', type ? { value: type, label: type } : null)
    setDOTType(type ? { value: type, label: type } : null)
  }, [setValue, patient.patient])

  // useEffect(() => {
  //   getPatient(id)
  // }, [])
  useEffect(() => {
    if (Object.keys(patient?.patient).length === 0) {
      console.log('hello')
      getPatient(id)
    }
    return () => {
      clearPatientData()
    }
  }, [])

  useEffect(() => {
    getVolunteers()
  }, [])

  const onSubmit = async () => {
    setLoading(true)
    await createChangeVolunteer(patient.patient.id, {
      change_date: currentDate(),
      volunteer_id:
        volOrNoVol?.value === 'Yes' ? selectedVolunteer?.value : null,
      type: volOrNoVol?.value === 'Yes' ? DOTType?.value : null,
    })
    setLoading(false)
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  console.log(patient.patient?.DOT_end_date || patient?.patient?.vdot_conclusion_date);
  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          {/* {patient.patient?.DOT_start_date && ( */}
          <Row className="mb-3">
            <Col md={3}>
              <Button
                color="info"
                size="sm"
                className="mt-3"
                onClick={() =>
                  history.push(
                    `/admin/change-volunteer-list/${patient.patient.id}`
                  )
                }
              >
                Change Volunteer List
              </Button>
            </Col>
          </Row>
          {/* )} */}
          <h3 className="mb-3">
            ({patient.patient?.name}) Create Change Volunteer
          </h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="volOrNoVol"
                  label="DOT with Volunteer (Yes/No)"
                  rules={{
                    required: 'DOT with Volunteer (Yes/No) is required!',
                  }}
                  control={control}
                  options={yesOrNo}
                  value={volOrNoVol}
                  setData={setVolOrNoVol}
                  placeholder="Yes or No"
                />
              </Col>
              {volOrNoVol?.value === 'Yes' && (
                <Col md={4}>
                  <CustomSelect
                    id="volunteer"
                    label="Select Volunteer"
                    control={control}
                    options={volunteers}
                    rules={{
                      required:
                        volOrNoVol?.value === 'Yes' && 'Volunteer is required!',
                    }}
                    value={selectedVolunteer}
                    setData={setSelectedVolunteer}
                    placeholder="Select Volunteer"
                    isRequired={volOrNoVol?.value === 'Yes'}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {volOrNoVol?.value === 'Yes' && (
                <Col md={4}>
                  <CustomSelect
                    id="DOTType"
                    label="App or No App"
                    rules={{
                      required:
                        volOrNoVol?.value === 'Yes' &&
                        'App or No App is required!',
                    }}
                    control={control}
                    options={DOTTypes}
                    value={DOTType}
                    setData={setDOTType}
                    placeholder="App or No App"
                    isRequired={volOrNoVol?.value === 'Yes'}
                  />
                </Col>
              )}
            </Row>
            {patientMiddleware(role, CREATE) && (
              <div className="modal-footer">
                {patient?.patient?.DOT_end_date ||
                patient?.patient?.vdot_conclusion_date ? null : (
                  <Button
                    color="primary"
                    size="sm"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? 'Loading' : 'Save'}
                  </Button>
                )}
              </div>
            )}
          </Form>
        </CardBody>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  volunteer: store.volunteer,
  auth: store.auth,
})

export default connect(mapStateToProps, {
  getPatient,
  createChangeVolunteer,
  getVolunteers,
  clearPatientData,
})(ChangeVolunteerCreate)
