import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
  Badge,
  Table,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import BackBtn from "utils/backBtn";
import { isVolunteerSupervisor } from "utils/middleware";
import { getTownships } from "store/actions";
import queryString from "query-string";
import { currentDate } from "utils/currentDate";
import { getVolunteerReportVisualization } from "store/actions";
import { useHistory } from "react-router";
import { volunteerReportLabels } from "components/charts/constant";
import LineChart from "components/charts/LineChart";
import { volunteerReportColors } from "components/charts/constant";
import styles from ".//VolunteerReportViz.module.css";
import BarChart from "components/charts/BarChart";
import { VscRefresh } from "react-icons/vsc";


function VolunteerReportViz() {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCount, setSelectedCount] = useState(null);
  const { status, visualization, township, auth } = useSelector(
    (state) => state
  );
  const { volunteerReportVisualization } = visualization;

  const {
    counts,
    volunteer_report_lineChart_data,
    by_townships_volunteer_report,
  } = volunteerReportVisualization;

  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getVolunteerReportVisualization(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  let volunteer_report_line = {};
  if (volunteer_report_lineChart_data) {
    const { x_axis_monthyears_labels } = volunteer_report_lineChart_data;
    const data = Object.keys(volunteer_report_lineChart_data)?.filter(
      (key) => key !== "x_axis_monthyears_labels"
    );

    let dataset;
    if (selectedCount) {
      dataset = [
        {
          label: selectedCount,
          fill: false,
          borderColor: volunteerReportColors[selectedCount],
          borderWidth: 1,
          data: volunteer_report_lineChart_data[selectedCount]?.map(
            (value) => value
          ),
        },
      ];
    } else {
      dataset = data.map((indicator, index) => {
        return {
          label: `Indicator ${index + 1}`,
          data: volunteer_report_lineChart_data[indicator]?.map(
            (value) => value
          ),
          fill: false,
          borderColor: volunteerReportColors[indicator],
          borderWidth: 1,
        };
      });
    }

    volunteer_report_line = {
      labels: x_axis_monthyears_labels?.map((month) => month),
      datasets: dataset,
    };
  }

  let volunteer_report_bar = [];
  if (by_townships_volunteer_report) {
    const data = Object.keys(by_townships_volunteer_report[0])
    ?.filter((key) => key !== "township_name")

    let dataset;
    if (selectedCount) {
      dataset = [
        {
          label: selectedCount,
          backgroundColor: volunteerReportColors[selectedCount],
          data: by_townships_volunteer_report?.map((data) => data[selectedCount]),
        },
      ];
    } else {
      dataset = data.map((indicator, index) => {
        return {
          label: `Indicator ${index + 1}`,
          data: by_townships_volunteer_report.map((data) => data[indicator]),
          backgroundColor: volunteerReportColors[indicator],
        };
      });
    }
    volunteer_report_bar = {
      labels: by_townships_volunteer_report?.map((data) => data.township_name),
      datasets: dataset,
    };
  }

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Volunteer Report Visualization</CardTitle>
          <Row className="mt-3">
            {!isVolunteerSupervisor(role) && (
              <Col sm={4} className="mt-4">
                <Select
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}

            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>

            <Col sm={1} className="mt-4">
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={8}>
              <Card>
                <CardBody>
                  <Table
                    responsive
                    className="align-items-center table-flush mb-5"
                    id="table-to-xls"
                  >
                    <tbody className="list">
                      {volunteerReportLabels?.map((label, index) => (
                        <tr>
                          <td>Indicator {index + 1}</td>
                          <td>
                            <div className="d-flex flex-column">
                              {label.eng_label}
                              {isVolunteerSupervisor(role) && (
                                <span className="mt-1">
                                  `(${label.myan_label})`
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className="d-flex justify-content-end">
              <Button
                size="sm"
                color="success"
                className="text-white"
                onClick={() => setSelectedCount(null)}
              >
                <VscRefresh />
              </Button>
            </Col>
          </Row>
          <Row>
            {counts &&
              Object.keys(counts)?.map((key, index) => (
                <Col md={2} sm={4} className="mb-3">
                  <Badge
                    className="p-2 px-5"
                    onClick={() => setSelectedCount(key)}
                    style={{
                      backgroundColor: volunteerReportColors[key],
                      opacity: selectedCount ? selectedCount === key ? 1: 0.2 : 1
                    }}
                  >
                    <div className={styles.tooltip}>
                      <span>{counts[`indicator_${index + 1}`]}</span>
                      <span className="mt-2">Indicator {index + 1}</span>
                      <span className={styles.tooltiptext}>
                        {volunteerReportLabels[index]?.eng_label}
                      </span>
                    </div>
                  </Badge>
                </Col>
              ))}
          </Row>

          <Row>
            <Col>
              <LineChart
                title={"Volunteer Report Line Chart"}
                data={volunteer_report_line}
                total={13}
                uniqueChartClassName={"volunteer_report"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                title={"Volunteer Report Bar Chart"}
                data={volunteer_report_bar}
                total={13}
                uniqueChartClassName={"volunteer_report_barchart"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}

export default VolunteerReportViz;
