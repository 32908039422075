import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_SYMPTOM,
  DELETE_SYMPTOM,
  SET_SYMPTOM,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_SYMPTOMS,
  UPDATE_SYMPTOM,
} from '../type'
import { NotificationManager } from 'react-notifications'

// const dummyData = [{
//   id: 1,
//   eng_name: "weight loss"
// },{
//   id: 2,
//   eng_name: "cough"
// },{
//   id: 3,
//   eng_name: "fever"
// }];


export const getSymptoms = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        "get",
        `symptoms`
      );
      // const result = dummyData;

      dispatch({
        type: SHOW_SYMPTOMS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getSymptom = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call("get", `symptoms/${id}`);
      const result = response.data
      // const result = dummyData.find((d)=> d.id === id)
      dispatch({
        type: SET_SYMPTOM,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createSymptom = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call("post", "symptoms", data);
      const result = response.data
      //console.log(data);
      // dummyData.push(data)
      // const result = data
      dispatch({
        type: CREATE_SYMPTOM,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('symptom has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateSymptom = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call("post", `symptoms/${id}?_method=PUT`, data);
      const result = response.data
      // const result = data
      dispatch({
        type: UPDATE_SYMPTOM,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success("symptom has been updated successfully!");
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const deleteSymptom = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `symptoms/${id}`)

      dispatch({ type: DELETE_SYMPTOM, payload: id })
      NotificationManager.success("symptom has been deleted successfully!");
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
