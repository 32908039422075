import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import SelectBox from "components/Selectbox";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { tbMDRTBConclusion } from "variables/options";
import { tbType } from "variables/options";
import { treatmentStatus } from "variables/options";
import { treatmentRegimen } from "variables/options";
import { typeOfTBPatient } from "variables/options";
import { smokingStatus } from "variables/options";
import { urbanRural } from "variables/options";
import { dmStatus } from "variables/options";
import { hivStatus } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomInput from "components/Inputs/CustomInput";
import { updatePatient } from "store/actions";
import { outcome } from "variables/options";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { allTBConclusion } from "variables/options";
import { currentDate } from "utils/currentDate";
import { yesOrNo } from "variables/options";
import { tbTypeBCs } from "variables/options";
const TBConclusion = ({ patient, auth, updatePatient }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [tbPatientConclusion, setTBPatientConclusion] = useState(false);
  const [bcTypes, setBcTypes] = useState(false);

  const ART_status = watch("ART_status");
  const CPT_status = watch("CPT_status");
  const TB_status = watch("TB_status");
  const treatment_status = watch("treatment_status");

  useEffect(() => {
    if (patient.patient) {
      const {
        TB_status,
        remark,
        treatment_status,
        TB_DRTB_code,
        treatment_start_date,
        treatment_registration_date,
        treatment_regimen,
        defined_TB_type,
        type_of_TB_patient,
        smoking_status,
        urban_or_rural,
        DM_status,
        HIV_result,
        treatment_outcome,
        treatment_outcome_date,
        CPT_status,
        CPT_date,
        ART_status,
        ART_date,
        sputum_smear_result,
        gene_Xpert_result,
        DOT_start_date,
      } = patient.patient;
      setValue("TB_status", TB_status);
      setValue("remark", remark);
      setValue("treatment_status", treatment_status);
      setValue("TB_DRTB_code", TB_DRTB_code);
      setValue("treatment_start_date", treatment_start_date);
      setValue("treatment_registration_date", treatment_registration_date);
      
      setValue("treatment_regimen", treatment_regimen);
      setValue("defined_TB_type", defined_TB_type);
      setValue("type_of_TB_patient", type_of_TB_patient);
      setValue("smoking_status", smoking_status);
      setValue("urban_or_rural", urban_or_rural);
      setValue("DM_status", DM_status);
      setValue("HIV_result", HIV_result);
      setValue("treatment_outcome", treatment_outcome);
      setValue("treatment_outcome_date", treatment_outcome_date);
      setValue("CPT_status", CPT_status);
      setValue("CPT_date", CPT_date);
      setValue("ART_status", ART_status);
      setValue("ART_date", ART_date);
      setValue("remark", remark);

      if (
        sputum_smear_result === "Positive" 
      ) {
        setTBPatientConclusion(true);
        setBcTypes(true);
      } else {
        setTBPatientConclusion(false);
        setBcTypes(false);
      }

      if (DOT_start_date) {
        setValue("DOT_start_date", DOT_start_date);
      }
    }
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    if (
      treatment_status === "Initial Loss" ||
      treatment_status === "Died before treatment"
    ) {
      await updatePatient(patient.patient.id, {
        TB_status: data.TB_status,
        treatment_status: data.treatment_status,
        remark: data.remark,
      },
      'TB-conclusion',
      'TB conclusion');
    } else {
      await updatePatient(patient.patient.id, {
        TB_status: data.TB_status,
        treatment_status:
          data.TB_status === "No TB" ? null : data.treatment_status,
        TB_DRTB_code: data.TB_status === "No TB" ? null : data.TB_DRTB_code,
        treatment_start_date:
          data.TB_status === "No TB" ? null : data.treatment_start_date,
          treatment_registration_date:
          data.TB_status === "No TB" ? null : data.treatment_registration_date,
          
        treatment_regimen:
          data.TB_status === "No TB" ? null : data.treatment_regimen,
        defined_TB_type:
          data.TB_status === "No TB" ? null : data.defined_TB_type,
        type_of_TB_patient:
          data.TB_status === "No TB" ? null : data.type_of_TB_patient,
        smoking_status: data.TB_status === "No TB" ? null : data.smoking_status,
        urban_or_rural: data.TB_status === "No TB" ? null : data.urban_or_rural,
        DM_status: data.TB_status === "No TB" ? null : data.DM_status,
        HIV_result: data.TB_status === "No TB" ? null : data.HIV_result,
        ART_status: data.TB_status === "No TB" ? null : data.ART_status,
        ART_date:
          data.TB_status === "No TB"
            ? null
            : ART_status === "Yes"
            ? data.ART_date
            : null,
        CPT_status: data.TB_status === "No TB" ? null : data.CPT_status,
        CPT_date:
          data.TB_status === "No TB"
            ? null
            : CPT_status === "Yes"
            ? data.CPT_date
            : null,
        // treatment_outcome: data.treatment_outcome,
        // treatment_treatment_outcome_date: data.treatment_treatment_outcome_date,
        remark: data.remark,
      },
      'TB-conclusion',
      'TB conclusion');
    }

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col md={4} className="mb-3">
            <SelectBox
              label="Result of TB Status"
              control={control}
              name="TB_status"
              options={
                tbPatientConclusion ? tbMDRTBConclusion : allTBConclusion
              }
              rules={{ required: true }}
              // onValueChange={(val) => {
              //   if (val === "No TB") {
              //     setShowTBOrMDR(false);
              //     setShowRemark(true);
              //   } else {
              //     setShowRemark(false);
              //     setShowTBOrMDR(true);
              //   }
              // }}
            />
          </Col>
          {(TB_status === "TB" || TB_status === "MDR TB") && (
            <Col md={4} className="mb-3">
              <SelectBox
                label="Treatment Status"
                control={control}
                name="treatment_status"
                options={treatmentStatus}
                rules={{ required: true }}
              />
            </Col>
          )}

          {(TB_status === "TB" || TB_status === "MDR TB") &&
            treatment_status !== "Initial Loss" &&
            treatment_status !== "Died before treatment" && (
              <>
                <Col md={4} className="mb-3">
                  <CustomInput
                    id="TB_DRTB_code"
                    label="TB/MDRTB Code"
                    register={{
                      ...register("TB_DRTB_code", {
                        required: "TB/MDRTB code is required!",
                      }),
                    }}
                    placeholder="TB/MDRTB Code"
                    errors={errors}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="TB Type"
                    control={control}
                    name="defined_TB_type"
                    options={bcTypes ? tbTypeBCs : tbType}
                    rules={{ required: true }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <CustomDateInput
                    id="treatment_registration_date"
                    label="Treatment registration date"
                    register={{
                      ...register("treatment_registration_date", {
                        required: "Treatment Registration date is required!",
                      }),
                    }}
                    placeholder="Treatment registration date"
                    errors={errors}
                    min="1950-01-01"
                    max="2100-12-31"
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <CustomDateInput
                    id="treatment_start_date"
                    label="Treatment Start date"
                    register={{
                      ...register("treatment_start_date", {
                        required: "Treatment Start date is required!",
                      }),
                    }}
                    placeholder="Treatment Start date"
                    errors={errors}
                    min="1950-01-01"
                    max="2100-12-31"
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="Treatment Regimen"
                    control={control}
                    name="treatment_regimen"
                    options={treatmentRegimen}
                    rules={{ required: true }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="Type of TB Patient"
                    control={control}
                    name="type_of_TB_patient"
                    options={typeOfTBPatient}
                    rules={{ required: true }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="Smoking Status"
                    control={control}
                    name="smoking_status"
                    options={smokingStatus}
                    rules={{ required: true }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="Urban Or Rural"
                    control={control}
                    name="urban_or_rural"
                    options={urbanRural}
                    rules={{ required: true }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="DM Status"
                    control={control}
                    name="DM_status"
                    options={dmStatus}
                    rules={{ required: true }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="HIV Status"
                    control={control}
                    name="HIV_result"
                    options={hivStatus}
                    rules={{ required: true }}
                    onValueChange={(val) => {
                      if (val !== "Reactive") {
                        setValue("ART_status", "No");
                        setValue("CPT_status", "No");
                        // setARTTrue(false);
                        // setCPTTrue(false);
                      } else {
                        setValue("ART_status", "Yes");
                        setValue("CPT_status", "Yes");
                        // setARTTrue(true);
                        // setCPTTrue(true);
                      }
                    }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="CPT Status"
                    control={control}
                    name="CPT_status"
                    options={yesOrNo}
                    rules={{ required: true }}
                    // onValueChange={(val) => {
                    //   if (val !== "Yes") {
                    //     setCPTTrue(false);
                    //   } else {
                    //     setCPTTrue(true);
                    //   }
                    // }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <CustomDateInput
                    id="CPT_date"
                    label="CPT Date"
                    register={{
                      ...register("CPT_date", {
                        required:
                          getValues("CPT_status") === "Yes"
                            ? "CPT Date is required!"
                            : false,
                      }),
                    }}
                    placeholder="CPT Date"
                    errors={errors}
                    disabled={CPT_status !== "Yes"}
                    isRequired={CPT_status === "Yes"}
                    // min={patient.patient.registration_date}
                    // max={currentDate()}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <SelectBox
                    label="ART Status"
                    control={control}
                    name="ART_status"
                    options={yesOrNo}
                    rules={{ required: true }}
                    // onValueChange={(val) => {
                    //   if (val !== "Yes") {
                    //     setARTTrue(false);
                    //   } else {
                    //     setARTTrue(true);
                    //   }
                    // }}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <CustomDateInput
                    id="ART_date"
                    label="ART Date"
                    register={{
                      ...register("ART_date", {
                        required:
                          ART_status === "Yes"
                            ? "ART Date is required!"
                            : false,
                      }),
                    }}
                    placeholder="ART Date"
                    errors={errors}
                    disabled={ART_status !== "Yes"}
                    isRequired={ART_status === "Yes"}
                    // min={patient.patient?.registration_date}
                    // max={currentDate()}
                  />
                </Col>

                {/* {showOutComeDate && ( */}
                {/* <Col md={4} className="mb-3">
                <CustomDateInput
                  id="treatment_outcome_date"
                  label="Treatment outcome date"
                  register={{
                    ...register("treatment_outcome_date", {
                      required: "Treatment Outcome date is required!",
                    }),
                  }}
                  placeholder="Treatment outcome date"
                  errors={errors}
                  min="1950-01-01"
                  max="2100-12-31"
                />
              </Col> */}
                {/* )} */}
              </>
            )}
          {(TB_status === "TB" || TB_status === "MDR TB") && (
            <Col md={4} className="mb-3">
              <SelectBox
                label="Treatment outcome"
                control={control}
                name="treatment_outcome"
                options={outcome}
                // onValueChange={(val) => {
                //   val !== ""
                //     ? setShowOutComeDate(true)
                //     : setShowOutComeDate(false);
                // }}
                disabled={true}
              />
            </Col>
          )}
          {(TB_status === "TB" || TB_status === "MDR TB") && patient?.patient?.DOT_start_date && (
            <Col md={4}>
              <CustomDateInput
                id="DOT_start_date"
                label="DOT Start Date"
                register={{
                  ...register("DOT_start_date", {
                    required: "Select DOT Start Date",
                  }),
                }}
                errors={errors}
                disabled={true}
              />
            </Col>
          )}

          <Col md={4}>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register("remark"),
              }}
              placeholder="Remark"
              errors={errors}
            />
          </Col>
        </Row>
        {patientMiddleware(auth.user.role, UPDATE) && (
          <div className="modal-footer d-flex justify-content-end">
            <Button color="primary" type="submit" disabled={loading} size="sm">
              {loading ? "Loading" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  auth: store.auth,
});

export default connect(mapStateToProps, { updatePatient })(
  TBConclusion
);
