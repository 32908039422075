import {
  CREATE_PATIENT_SYMPTOM,
  DELETE_PATIENT_SYMPTOM,
  SET_PATIENT_SYMPTOM,
  SHOW_PATIENT_SYMPTOMS,
  UPDATE_PATIENT_SYMPTOM,
} from '../type'

const initialState = {
  patientSymptoms: [],
  patientSymptom: {},
};

const patientSymptom = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PATIENT_SYMPTOMS:
      return {
        ...state,
        patientSymptoms: action.payload,
      };
    case CREATE_PATIENT_SYMPTOM:
      return {
        ...state,
        patientSymptoms: [action.payload, ...state.patientSymptoms],
      };
    case DELETE_PATIENT_SYMPTOM:
      return {
        ...state,
       patientSymptoms: state.patientSymptoms.filter(
          (patientSymptom) =>patientSymptom.id !== action.payload
        ),
      };
    case SET_PATIENT_SYMPTOM:
      return {
        ...state,
        patientSymptom: action.payload,
      };
    case UPDATE_PATIENT_SYMPTOM:
      let index = state.patientSymptoms.findIndex(
        (patientSymptom) => patientSymptom.id === action.payload.id
      );
      state.patientSymptoms[index] = action.payload;
      if (state.patientSymptom.id === action.payload.id) {
        state.patientSymptom = action.payload;
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default patientSymptom;
